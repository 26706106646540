import { Component, OnInit } from '@angular/core';
import {Api} from '../../services/api.service';

@Component({
  selector: 'interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.css']
})
export class InterfaceComponent implements OnInit {

  constructor(public api:Api) { }

  ngOnInit() {
  }

}
