<nav class="d-flex" style="justify-content: space-between;" *ngIf="pagesList.length>1">
  <span class="pull-left pagination-info" *ngIf="results>0">Showing from {{start}} to {{end}} of {{results}} {{content}}</span>
    <ul class="pagination pagination-sm pull-right">
      <li class="page-item" [ngClass]="{'disabled':page==0}">
        <a class="page-link" href="javascript:void(0);" (click)="goToPage(current-1);">&laquo;</a>
      </li>
      <li *ngFor="let currentPage of pagesList" class="page-item" [ngClass]="{'disabled':currentPage==0,'active':current==currentPage-1}"><a class="page-link" href="javascript:void(0);" (click)="goToPage(currentPage-1);">{{currentPage>0?currentPage:'...'}}</a></li>
      <li class="page-item" [ngClass]="{'disabled':page==pagesList[pagesList.length-1]-1}">
        <a class="page-link" href="javascript:void(0);" (click)="goToPage(current+1);">&raquo;</a>
      </li>
    </ul>
  </nav>