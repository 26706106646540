import { Component, Input, OnInit } from '@angular/core';
import {Api} from "../../services/api.service";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserModel} from "../../models/Authentication";
import {validationState} from "../../utilities/helpers";

@Component({
  selector: 'app-marketing-row',
  templateUrl: './marketing-row.component.html',
  styleUrls: ['./marketing-row.component.css']
})
export class MarketingRowComponent implements OnInit {
  @Input() mainTitle: string;
  @Input() highlightTitle: string;
  @Input() highlightClass: string;
  @Input() paragraphs: string[];
  @Input() paragraphClass: string;
  @Input() imgSrc: string;
  @Input() flippedRow: boolean = false;
  @Input() titleClass: string;
  @Input() button: string;
  @Input() imgClass: string;
  @Input() imgClass1: string;
  validationState = validationState;


  userEmail: string;
  emailForm: FormGroup;
  user: UserModel | boolean;
  source: string;
  medium: string;
  coupon: string;
  partnerId: string;

  constructor(private api: Api,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private modalService: NgbModal) {
  }

  get validEmail(): boolean {
    return this.emailForm.get('email').valid;
  }

  ngOnInit() {
    this.emailForm = this.formBuilder.group({
      email: this.formBuilder.control('', [
        Validators.required,
        Validators.email,
      ])
    });
    const params = this.route.snapshot.queryParams;
    this.userEmail = params.email;
    this.source = params.utm_source;
    this.medium = params.utm_medium;
    this.partnerId = this.route.snapshot.params.partner_id
    this.coupon = params.coupon
  }

  checkoutSession() {
    this.userEmail = this.userEmail || this.emailForm.get('email').value;
    if (!this.userEmail) {
      return;
    }
    this.api.perkProductCheckout(this.userEmail, this.source, this.medium, this.partnerId, this.coupon)
      .subscribe((res) => {
        window.location.href = res;
      });
  }

  open(content: any): void {
    this.userEmail
      ? this.checkoutSession()
      : this.modalService.open(content, {centered: true});
  }

}
