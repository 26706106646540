import { OnInit } from '@angular/core';
import { Api } from "../../services/api.service";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { validationState } from "../../utilities/helpers";
var MarketingRowComponent = /** @class */ (function () {
    function MarketingRowComponent(api, route, formBuilder, modalService) {
        this.api = api;
        this.route = route;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.flippedRow = false;
        this.validationState = validationState;
    }
    Object.defineProperty(MarketingRowComponent.prototype, "validEmail", {
        get: function () {
            return this.emailForm.get('email').valid;
        },
        enumerable: true,
        configurable: true
    });
    MarketingRowComponent.prototype.ngOnInit = function () {
        this.emailForm = this.formBuilder.group({
            email: this.formBuilder.control('', [
                Validators.required,
                Validators.email,
            ])
        });
        var params = this.route.snapshot.queryParams;
        this.userEmail = params.email;
        this.source = params.utm_source;
        this.medium = params.utm_medium;
        this.partnerId = this.route.snapshot.params.partner_id;
        this.coupon = params.coupon;
    };
    MarketingRowComponent.prototype.checkoutSession = function () {
        this.userEmail = this.userEmail || this.emailForm.get('email').value;
        if (!this.userEmail) {
            return;
        }
        this.api.perkProductCheckout(this.userEmail, this.source, this.medium, this.partnerId, this.coupon)
            .subscribe(function (res) {
            window.location.href = res;
        });
    };
    MarketingRowComponent.prototype.open = function (content) {
        this.userEmail
            ? this.checkoutSession()
            : this.modalService.open(content, { centered: true });
    };
    return MarketingRowComponent;
}());
export { MarketingRowComponent };
