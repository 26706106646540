import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input("content")
  content:string;

  @Input("size")
  size:number;

  @Input("results")
  results:number;

  @Input("page")
  set page(page:number)
  {
    this.current=page;
    this.start=(page*this.size)+1;
    this.end=Math.min(this.start+this.size-1,this.start+(this.results-(page*this.size)-1));
  }

  @Input("pages")
  set pages(pages:number)
  {
    this.pagesList=[];
    if(pages>0)
    {
      let start=Math.max(1,this.current-3);
      let end=(pages<=6)?pages:Math.max(6,Math.min(pages,this.current+3));
      for(let i=start;i<=end;i++)
      {
        this.pagesList.push(i);
      }
    }
    
  }
  

  

  

  @Output("navigation") navigationEvent:EventEmitter<number>= new EventEmitter<number>();

  current:number=0;
  start:number;
  end:number;
  pagesList:number[]=[];
  constructor() { }

  ngOnInit() {
  }

  goToPage(page:number)
  {
    this.navigationEvent.emit(page);
  }
}
