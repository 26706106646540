import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { Api } from '../../services/api.service';
var DepartureComponent = /** @class */ (function () {
    function DepartureComponent(router, route, api, ngxModalService, formBuilder) {
        this.router = router;
        this.route = route;
        this.api = api;
        this.ngxModalService = ngxModalService;
        this.formBuilder = formBuilder;
        this.loading = false;
        this.isSaved = false;
        this.searchForm = formBuilder.group({
            search: ['']
        });
    }
    DepartureComponent.prototype.ngOnInit = function () {
        this.whitelabelConfig = this.route.snapshot.data.whitelabel;
        this.fetchSuggestedAirports();
    };
    DepartureComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.searchForm.controls.search.valueChanges.pipe(filter(function (val) { return val; }), debounceTime(500), switchMap(function (val) { return _this.api.findAirports(val).pipe(tap(function (a) { return _this.airports = a; })); })).subscribe();
        this.input.nativeElement.focus();
    };
    DepartureComponent.prototype.fetchSuggestedAirports = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, error_1, location, latitude, longitude, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.getNearestAirports().toPromise()];
                    case 1:
                        response = _a.sent();
                        this.suggestedAirports = response.data.list;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('fetchSuggestedAirports: Error fetching nearest airports:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [4 /*yield*/, this.api.getIPLocation()];
                    case 4:
                        location = _a.sent();
                        latitude = location.coords.latitude;
                        longitude = location.coords.longitude;
                        if (!(latitude && longitude)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.api.getNearestAirports(latitude, longitude).toPromise()];
                    case 5:
                        response = _a.sent();
                        this.suggestedAirports = response.data.list;
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DepartureComponent.prototype.canDeactivate = function () {
        return !this.isSaved;
    };
    DepartureComponent.prototype.setAirport = function () {
        var _this = this;
        fbq("track", "SetAirport");
        this.loading = true;
        this.api.setDepartureAirport(this.selectedAirport).pipe(tap(function (res) {
            _this.loading = false;
            _this.isSaved = true;
            _this.router.navigate(["signup/dream-destination"], {
                queryParamsHandling: "preserve",
            });
        })).subscribe();
    };
    DepartureComponent.prototype.select = function (id) {
        this.selectedAirport = this.airports.find(function (a) { return a.ID === id; });
        this.airports = [];
    };
    DepartureComponent.prototype.selectSuggestedAirport = function (airport) {
        this.selectedAirport = {
            ID: airport.ID,
            code: airport.code,
            name: airport.name,
            city: airport.city
        };
        this.airports = [];
    };
    DepartureComponent.prototype.removeSelectedAirport = function () {
        this.selectedAirport = null;
        this.input.nativeElement.focus();
    };
    return DepartureComponent;
}());
export { DepartureComponent };
