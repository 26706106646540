import {AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Api, PERKS_ORIGIN} from "../../services/api.service";
import {validationState} from "../../utilities/helpers";
import {UserModel} from "../../models/Authentication";
import {EmailModalComponent} from "../email-modal/email-modal.component";
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-travel-product',
  templateUrl: './perks-product.component.html',
  styleUrls: ['./perks-product.component.css']
})
export class PerksProductComponent implements OnInit, AfterViewInit {
  isCollapsed: boolean = true;
  isCollapsed1: boolean = true;
  isCollapsed2: boolean = true;
  isCollapsed3: boolean = true;
  isCollapsed4: boolean = true;
  checkoutSuccess = false;
  userEmail: string;
  user: UserModel | boolean;
  highlightClass = 'highlight-black';
  paragraphClass = 'p-class';
  titleClass = 'title-class';
  button = 'join-btn'
  imgClass = 'img-class'
  imgClass1 = 'img-class-1'
  validationState = validationState;
  redirect: boolean;
  copied: boolean = false;
  isMobileMenuOpen: boolean;
  utmSource: string;
  utmMedium: string;
  partnerId: string;
  coupon: string;


  rowContent = {
    row1: {
      mainTitle: 'Lifetime Access',
      highlightTitle: 'You pay one time for access to a growing list of discounts on all things travel.',
      paragraphs: [
        '✓ Discounts on the coolest products: Bags, Clothing, Travel Tech, etc.',

        '✓ Discounts of the best travel services and platform',

        '✓ Request your most wanted brand',

        '✓ One low lifetime membership cost'
      ],
      imgSrc: 'https://dfcapp.s3.amazonaws.com/Hero+Image+(5).png'
    },
    row2: {
      mainTitle: 'Value that keeps growing',
      highlightTitle: 'New perk partners are added weekly',
      imgSrc: 'https://dfcapp.s3.amazonaws.com/4.png'
    },
    row3: {
      mainTitle: 'The right partners discounts',
      highlightTitle: 'We add partners for all the product and service categories in travel',
      imgSrc: 'https://dfcapp.s3.amazonaws.com/5.png'
    },
    row4: {
      mainTitle: 'Unique partner perks',
      highlightTitle: 'Our travel discounts are unique and not publicly available',
      imgSrc: 'https://dfcapp.s3.amazonaws.com/6.png'
    }
  };


  constructor(private api: Api,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private titleService: Title,
              private elementRef: ElementRef, private renderer: Renderer2) {
  }
  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    this.userEmail = params.email;
    this.utmMedium = params.utm_medium
    this.utmSource = params.utm_source
    this.redirect = params.redirect === 'true';
    this.checkoutSuccess = params.success === 'true';
    this.partnerId = this.route.snapshot.params.partner_id
    this.coupon = params.coupon

  }
  ngAfterViewInit() {
    setTimeout(() => this.changeFavicon('https://dfcapp.s3.amazonaws.com/perks-favicon.ico'), 2000);
    setTimeout(() =>  this.titleService.setTitle('Perks'), 2000);
    if (this.redirect) {
      setTimeout(() => this.openEmail(), 0);
    }
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  closeMenu(): void {
    if(this.isMobileMenuOpen) {
      this.isMobileMenuOpen = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (window.innerWidth > 778) {
      this.closeMenu();
    }
  }

  copyInputMessage(inputElement){
    window.navigator['clipboard'].writeText(inputElement);
    this.copied = true
  }

  getPerks(){
    const link = document.createElement('a');
    link.href = 'https://getperkscard.com/dashboard';
    link.target = '_blank';
    link.rel = 'noreferrer noopener';
    link.click();
  }
  private changeFavicon(newFaviconUrl: string) {
    const head = this.elementRef.nativeElement.ownerDocument.head;
    const existingFavicon = head.querySelector('#app-favicon');
    if (existingFavicon) {
      this.renderer.removeChild(head, existingFavicon);
      const link: HTMLLinkElement = this.renderer.createElement('link');
      link.id = 'favicon';
      link.rel = 'icon';
      link.type = 'image/x-icon';
      link.href = newFaviconUrl;
      this.renderer.appendChild(head, link);
    }
  }

  allPerks(){
    const link = document.createElement('a');
    link.href = 'https://getperkscard.com/view-all-perks';
    link.target = '_blank';
    link.rel = 'noreferrer noopener';
    link.click();
  }

  openEmail(){
   const emailModal = this.modalService.open(EmailModalComponent, {centered: true})
    const component = emailModal.componentInstance as EmailModalComponent
    component.userEmail = this.userEmail
    component.origin = PERKS_ORIGIN
    component.source = this.utmSource
    component.medium = this.utmMedium
    component.partnerId = this.partnerId
    component.coupon = this.coupon
  }

  checkoutSession() {
    if (!this.userEmail) {
      return;
    }
    this.api.perkProductCheckout(this.userEmail, this.utmSource, this.utmMedium, this.partnerId, this.coupon)
      .subscribe((res) => {
        window.location.href = res;
      });
  }

  open(): void {
    this.userEmail
      ? this.checkoutSession()
      : this.openEmail();
  }

}
