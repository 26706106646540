<div class="col-12 px-0">
  <h3 class="bold mb-2 mt-3 mt-md-0 pb-3 pl-3">Update Plan</h3>
</div>
<div class="bg-white mx-3 pb-2 mb-5 overflow-hidden card-shadow" style="border-radius: 10px;">
  <div>
    <div class="row mx-3 pt-4 pb-5">
      <div *ngIf="!onTrial; else trialPause;" class="col-12 col-md-5 ml-auto mb-3 mb-md-0 mr-md-3 px-0 card-shadow position-relative dark-grey border-radius-6">
        <h5 class="text-center mt-1 pt-3 px-3">Pause Account</h5>
        <p class="text-center mb-2">Get 6 Months Free</p>
        <hr class="mx-3">
        <div class="mt-3 px-4 pb-3">
          <p >Travel plans on hold? Pause your account at no cost for 6 months so you're ready to jump back into action when you're ready. Keep receiving alerts and member benefits.
          </p>
        </div>
        <div class="position-relative w-100 mx-center" style="bottom: 20px;">
          <button class="primary-btn-dfc rounded-btn w-50 my-4 p-1 p-lg-2 min-w-fit" (click)="pauseAccount(); trackClick('Pause Account')">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <b *ngIf="!loading">Pause Account</b></button>
        </div>
      </div>
      <ng-template #trialPause>
        <div class="col-12 col-md-5 ml-auto mb-3 mb-md-0 mr-md-3 px-0 card-shadow position-relative dark-grey border-radius-6">
          <h5 class="text-center mt-1 pt-3 px-3">Pause Account</h5>
          <p class="text-center mb-2">Extend Trial Period 14 Days</p>
          <hr class="mx-3">
          <div class="mt-3 px-4 pb-3">
            <p >Not sure if DFC is right for you?
              </p>
            <p>Extend your trial period for 14 days to give you more time to explore the benefits of your membership.
            </p>
          </div>
          <div class="position-relative w-100 mx-center" style="bottom: 35px;">
            <button class="primary-btn-dfc rounded-btn w-50 my-4 p-1 p-lg-2 min-w-fit" (click)="extendTrial(); trackClick('Extend Trial') ">
              <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
              <b *ngIf="!loading">Pause Account</b></button>
          </div>
        </div>
      </ng-template>
      <div class="col-12 col-md-5 mr-auto mt-4 mt-md-0 ml-md-3 px-0 card-shadow dark-grey border-radius-6">
        <h5 class="text-center mt-1 pt-3 px-3 font-weight-bold">Downgrade Account</h5>
        <p class="text-center mb-2">Change Account Tier</p>
        <hr class="mx-3">
        <div class="mt-3 px-4 pb-3">
          <p>Trouble with your membership? Reach out to support to resolve concerns quickly so you don't miss out on your next dream vacation.</p>
          <div class="w-100 mx-center" style="bottom: 40px;">
            <a id="my_custom_link" href="mailto:support@dollarflightclub.com"><button class="rounded-btn w-50 my-3 p-1 p-lg-2 dull-btn light-grey min-w-fit" ><b style="color: #999;">Contact Support</b></button></a>
          </div>
          <p class="text-center">Or <a class="a-decoration pointer" (click)="openModal(false, 'cancelModal')">Downgrade Account</a></p>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-smart-modal #trialExtendedModal identifier="trialExtendedModal" [customClass]="'md-dialog dashboard-modal'">
  <div *ngIf="extendTrialPeriod" class="col-12 ml-auto mr-5 px-0">
    <div class="col-12 ml-auto mr-5 px-0">
      <div class="col-3 mx-auto">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/modal-logo.png">
      </div>
      <div class="col-12 mt-4 text-center">
        <h3 class="px-3">Your Trial Is Extended</h3>
        <p class="px-3 px-sm-3 my-2 py-1 f14">We have extended your trial period for 14 extra days.
        </p>
      </div>
      <div class="mt-3 pt-3 pb-3 pointer mt-border modal-close text-center" (click)="modalClose()"><span class="font-weight-bold dfc-color">Awesome</span></div>
    </div>
  </div>
  <div *ngIf="!extendTrialPeriod" class="col-12 ml-auto mr-5 px-0">
    <div class="col-12 ml-auto mr-5 px-0">
      <div class="col-3 mx-auto">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/modal-logo.png">
      </div>
      <div class="col-12 mt-4 text-center">
        <h3 class="px-3">You Have Previously Paused Your Account</h3>
        <p class="px-2 px-sm-3 my-2 py-1 f14">Members are allowed 1 account pause while on trial. Please contact <a href="mailto:support@dollarflightclub.com">support@dollarflightclub.com</a> with any questions.</p>
      </div>
      <div class="mt-3 pt-3 pb-3 pointer mt-border modal-close text-center" (click)="modalClose()"><span class="font-weight-bold dfc-color">Got it</span></div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #pauseModal identifier="pauseModal" [customClass]="'md-dialog dashboard-modal'" id="pauseModal">
  <div *ngIf="!api.user?.hasPaused && pauseSuccess" class="col-12 ml-auto mr-5 px-0">
    <div class="col-12 ml-auto mr-5 px-0">
      <div class="col-3 mx-auto">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/modal-logo.png">
      </div>
      <div class="col-12 mt-4 text-center">
        <h3 class="px-3">Your Account Is Paused</h3>
        <p class="px-3 px-sm-3 my-2 py-1 f14">We have paused your account for 6 months and pushed back your renewal 6 months as well. You can
          restart it anytime.
        </p>
      </div>
      <div class="mt-3 pt-3 pb-3 pointer mt-border modal-close text-center" (click)="modalClose()"><span class="font-weight-bold dfc-color">Awesome</span></div>
    </div>
  </div>
  <div *ngIf="api.user?.hasPaused || !pauseSuccess" class="col-12 ml-auto mr-5 px-0">
    <div class="col-12 ml-auto mr-5 px-0">
      <div class="col-3 mx-auto">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/modal-logo.png">
      </div>
      <div class="col-12 mt-4 text-center">
        <h3 class="px-3">You Have Previously Paused Your Account</h3>
        <p class="px-2 px-sm-3 my-2 py-1 f14">Members are allowed 1 account pause. Please contact <a href="mailto:support@dollarflightclub.com">support@dollarflightclub.com</a> with any questions.</p>
      </div>
      <div class="mt-3 pt-3 pb-3 pointer mt-border modal-close text-center" (click)="modalClose()"><span class="font-weight-bold dfc-color">Got it</span></div>
    </div>
  </div>
</ngx-smart-modal>


<ngx-smart-modal #cancelModal identifier="cancelModal" [customClass]="'md-dialog dashboard-modal'" id="cancelModal">
  <div *ngIf="!downgraded && !stepTwo">
    <div class="col-12 ml-auto mr-5 px-0">
      <div class="col-12 mt-4 text-center">
        <h3 class="px-3">Before you downgrade</h3>
        <p>Just a quick reminder about your account</p>
        <hr>
        <p class="px-2 px-sm-3 my-2 py-1 f14 text-left" style="font-size: 14px;">Members save $500 on average per flight if their <a href="/profile/departure"> departure airports </a> and <a href="/profile/departure">dream destinations </a> are updated to get the best personalized deals. Update them <a href="/profile/departure">now!</a>
        </p>
        <p class="px-2 px-sm-3 my-2 f14 text-left" style="font-size: 14px;">Once downgraded, you will lose access to:</p>
          <ul class="text-left" style="font-size: 14px;">
        <li>Deeply discounted international and & domestic deals</li>
        <li>Instant Dream Destination SMS alerts</li>
        <li>Member Perks including discounted Lounge Access</li>
      </ul>
      </div>
      <div  class="mt-3 row mt-border mx-0 text-center">
        <div class="col-6 py-3 modal-close-left mr-border pointer" (click)="modalClose('cancelModal')">
          <span class="font-weight-bold dark" >Go Back</span>
        </div>
        <div class="col-6 py-3 pointer" (click)="continueOn()">
          <span class="font-weight-bold dfc-color">Continue</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="stepTwo">
    <div class="col-12 ml-auto mr-5 px-0" *ngIf="!onTrial; else trialDiscount">
      <div class="col-12 mt-4 text-center">
        <h3 class="px-3">Why downgrade now?</h3>
        <p (click)="keepPlanWithCoupon()" style="color: #3a86ff; cursor: pointer;">Enjoy a free year on us before you decide</p>
        <hr>
        <p class="px-2 px-sm-3 my-2 py-1 f14 text-left">Share quick feedback with us why you're considering downgrading and we'll give you free year as we use your feedback to improve the product and launch new features.
        </p>
        <p class="px-2 px-sm-3 my-2 f14 text-left">It's a win-win!</p>
      </div>
      <div class="mt-3 row mt-border mx-0 text-center">
        <div class="col-6 py-3 modal-close-left mr-border pointer" (click)="cancelAccount(); trackClick('Cancel Account')">
          <span class="font-weight-bold dark" >Downgrade</span>
        </div>
        <div class="col-6 py-3 pointer" (click)="keepPlanWithCoupon(); trackClick('Switch to Discount')">
          <span class="font-weight-bold dfc-color">Get 1 Year Free</span>
        </div>
      </div>
    </div>
    <ng-template class="col-12 ml-auto mr-5 px-0" #trialDiscount>
      <div class="col-12 mt-4 text-center">
        <h3 class="px-3">Why downgrade now?</h3>
        <p (click)="keepPlanWithCoupon()" style="color: #3a86ff; cursor: pointer;">Enjoy 60% off your first year on us</p>
        <hr>
        <p class="px-2 px-sm-3 my-2 py-1 f14 text-left">Share quick feedback with us why you're considering downgrading and we'll give you 60% off your first year as we use your feedback to improve the product and launch new features.
        </p>
        <p class="px-2 px-sm-3 my-2 f14 text-left">It's a win-win!</p>
      </div>
      <div class="mt-3 row mt-border mx-0 text-center">
        <div class="col-6 py-3 modal-close-left mr-border pointer" (click)="cancelAccount(); trackClick('Cancel Account')">
          <span class="font-weight-bold dark" >Downgrade</span>
        </div>
        <div class="col-6 py-3 pointer" (click)="keepPlanWithCoupon(); trackClick('Switch to Discount')">
          <span class="font-weight-bold dfc-color">Get 60% Off</span>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="downgraded">
    <div class="col-12 ml-auto mr-5 px-0">
      <div class="col-3 mx-auto">
        <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/modal-logo.png">
      </div>
      <div class="col-12 mt-4 text-center" *ngIf="!error">
        <h3 class="px-3">Your Account Is Canceled</h3>
        <p class="px-2 px-sm-3 my-2 py-1 f14">The DFC team appreciates that you gave us a chance even if it didn't work out.
          Your account has been cancelled but you can access your member benefits for the duration of your current plan.
          Your membership will not renew. <br>Would you mind filling out a short survey? We value your opinion and want to improve the experience for future customers.
        </p>
      </div>
      <div class="col-12 mt-4 text-center" *ngIf="error">
        <h3 class="px-3">Your Account Has Already Been Canceled</h3>
        <p class="px-2 px-sm-3 my-2 py-1 f14">Please contact support@dollarflightclub.com with any questions.
        </p>
      </div>
      <div class="mt-3 pt-3 pb-3 pointer mt-border modal-close text-center" (click)="modalClose('cancelModal');takeSurvey()"><span class="font-weight-bold dfc-color">Take Survey</span></div>
    </div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #discountModal identifier="discountModal" [customClass]="'md-dialog dashboard-modal'" id="discountModal">
  <div class="col-12 ml-auto mr-5 px-0">
    <div class="col-3 mx-auto">
      <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/modal-logo.png">
    </div>
    <div class="col-12 mt-4 text-center">
      <h3 class="px-3"><span *ngIf="!onTrial">One Year Free Applied</span><span *ngIf="onTrial">60% Off Applied</span></h3>
      <p class="px-2 px-sm-3 my-2 py-1 f14">We've successfully applied the discount to your account! Please share your product feedback via the link below, so we can work to improve our product for you.
      </p>
    </div>
    <div class="mt-3 pt-3 pb-3 pointer mt-border modal-close text-center" (click)="modalClose('discountModal');takeSurvey()"><span class="font-weight-bold dfc-color">Share Feedback</span></div>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #discountFailedModal identifier="discountFailedModal" [customClass]="'md-dialog dashboard-modal'" id="discountFailedModal">
  <div class="col-12 ml-auto mr-5 px-0">
    <div class="col-3 mx-auto">
      <img class="img-fluid" src="https://dfcmedia.s3.amazonaws.com/email-assets/modal-logo.png">
    </div>
    <div class="col-12 mt-4 text-center">
      <h3 class="px-3">Coupon has already been applied</h3>
      <p class="px-2 px-sm-3 my-2 py-1 f14">It looks like you've already redeemed this offer! If you have any questions about your account status, please reach out to support@dollarflightclub.com
      </p>
    </div>
    <div class="mt-3 pt-3 pb-3 pointer mt-border modal-close text-center" (click)="modalClose('discountFailedModal');"><span class="font-weight-bold dfc-color">Got it</span></div>
  </div>
</ngx-smart-modal>

