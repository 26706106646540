<new-navigation class="mb-4"></new-navigation>
<div>
  <div class="container-fluid px-0 mx-0">
    <div class="row no-gutters vh100">
      <div class="col-12 col-md-8 col-lg-6 login-container">
        <h1>{{api.strings?.portal.reset_password}}</h1>
        <p *ngIf="!success">{{api.strings?.portal.reset_password_instructions}}</p>
        <p class="success animated fadeIn" *ngIf="success">{{api.strings?.portal.password_reset_success}}</p>
        <div class="row" *ngIf="!success">
          <div class="col-12">
            <form [formGroup]="resetForm" autocomplete="off" novalidate>
              <div class="form-group">
                <label>{{api.strings?.portal.new_account_password}}:</label>
                <input formControlName="password" type="password" class="form-control" placeholder="{{api.strings?.portal.new_account_password}}" [ngClass]="{'input-error':validationActive&&!resetForm.controls.password.disabled&&!resetForm.controls.password.valid}" (keypress)="eventHandler($event)">
                <div class="input-error-message" *ngIf="validationActive&&!resetForm.controls.password.disabled&&!resetForm.controls.password.valid" (keypress)="eventHandler($event)">* {{api.strings?.portal.invalid_new_password}}</div>
              </div>
              <div class="form-group">
                    <label>{{api.strings?.portal.confirm_new_password}}:</label>
                    <input formControlName="confirm" type="password" class="form-control" placeholder="{{api.strings?.portal.confirm_new_password}}" [ngClass]="{'input-error':validationActive&&!resetForm.controls.confirm.disabled&&!resetForm.controls.confirm.valid}" (keypress)="eventHandler($event)">
                    <div class="input-error-message" *ngIf="validationActive&&!resetForm.controls.confirm.disabled&&!resetForm.controls.confirm.valid">* {{api.strings?.portal.passwords_do_not_match}}</div>
              </div>
            </form>
          </div>
        </div>
        <a *ngIf="success" class="btn btn-primary btn-block animated fadeIn" routerLink="/login">{{api.strings?.portal.go_to_login}}</a>
        <button *ngIf="!success" class="btn btn-primary" [ngClass]="{'loading':busy}" (click)="reset();"><i class="fa fa-sign-in"></i> {{api.strings?.portal.reset_password}}</button>
      </div>
      <div class="col-12 col-md-4 col-lg-6">
        <div class="img-container h-100"></div>
      </div>
    </div>
  </div> 
</div>