<app-countdown-banner
  [displaySalesBanner]="whitelabel_config?.members_dashboard.banner && !isPremiumUser"
  [banner_txt]="whitelabel_config?.members_dashboard.banner_text">
</app-countdown-banner>
<div class="fixed-header">
    <div class="row">
      <ng-container *ngTemplateOutlet="accountActions"></ng-container>
    </div>
</div>

<ng-template  #accountActions>
  <div class="col-md-12 col-sm-12 text-right mb-2 pr-0" [ngClass]="displaySalesBanner ? 'mt-4' : 'mt-3'">
    <a [routerLink]="['/support']" class="nav-item pointer">
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="question-circle" class="svg-inline--fa fa-question-circle fa-w-16 i-md" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z"></path>
        </svg>
    </a>
    <a [routerLink]="['/profile/account']"  class="nav-item pointer ml-4">
      <i class="fa fa-lg fa-user"></i>
    </a>
    <a (click)="logout()" class="nav-item pointer ml-4">
      <img class="logout-icon" src="https://dfcmedia.s3.amazonaws.com/email-assets/logout.png" />
    </a>
  </div>
</ng-template>
