
export interface NewsletterConfig {

  imageUrl: string;
  name: string;
  id: string;
  description: string;
}

export const NEWSLETTERS: NewsletterConfig[] = [

  {
    imageUrl: 'https://dfcmedia.s3.amazonaws.com/email-assets/The+Navigator+Updated+Logo+6%3A2022..png',
    name: 'The Navigator',
    description: 'A five-minute trip through the news that matters most to travelers. ' +
      'Our free weekly newsletter brings you the top travel stories, expert tips, and everything else you need for your next adventure.',
    id: 'navigator'
  },
  {
    id: 'smartPoints',
    imageUrl: 'https://dfcmedia.s3.amazonaws.com/email-assets/Smart+Points+Updated+Logo+6%3A2022..png',
    description: 'Learn to earn. Get expert insight into travel credit cards, loyalty programs, points hacks, and more with our free weekly newsletter.',
    name: 'Smart Points'
  }
]
