import { Component, OnInit } from '@angular/core';
import {Api,MessageType} from '../../services/api.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
@Component({
  selector: 'passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['../../../assets/css/login.css']
})
export class PasswordresetComponent implements OnInit {

  validationActive:boolean=false;
  resetForm:FormGroup;
  busy:boolean = false;
  success:boolean=false;
  token:string;
  constructor(public api:Api,formBuilder:FormBuilder,public router:Router,public route: ActivatedRoute)
  {
    if(api.logged)
      router.navigateByUrl("/dashboard");
    else
    {
      this.resetForm=formBuilder.group
      (
        {
          password:['',[Validators.required,Validators.minLength(6)]],
          confirm:['',[Validators.required,Validators.minLength(6),this.matchingPasswords('password')]]
        }
      );
      this.route.params.subscribe(params => {
        if(typeof params['token']!="undefined"&&params['token']!=null&&params['token']!="")
        {

          this.api.initializedCallback.push(()=>
          {
            this.api.showPageLoader();
            //  params[token] is a URL path param
            this.api.validateUserToken(params['token']).then(success=>
              {
                this.api.hidePageLoader();
                if(!success)
                  router.navigateByUrl("/login");
                else
                  this.token=params['token'];
              },error=>router.navigateByUrl("/login")).catch(error=>router.navigateByUrl("/login"));
          })
        }
        else
          router.navigateByUrl("/login");
      });
    }

   }

  ngOnInit() {
  }
  matchingPasswords(passwordKey: string) {
    return (group: FormGroup) => {
      if(!group||!group.parent)
        return null;
      let passwordInput = group.parent.get(passwordKey);
      if (passwordInput.value !== group.value) {
        return {notEquivalent: true};
      }
    }
  }
  public disable()
  {
    this.busy=true;
    this.resetForm.controls.confirm.disable();
    this.resetForm.controls.password.disable();
  }
  public enable()
  {
    this.busy=false;
    this.resetForm.controls.confirm.enable()
    this.resetForm.controls.password.enable()
  }
  eventHandler(event)
  {
    if(event.keyCode==13)
      this.reset();
  }
  public reset()
  {
    this.validationActive=true;
    if(this.busy||!this.resetForm.valid)
      return;
    this.disable();
    this.api.resetPassword(this.resetForm.controls.password.value,this.token).then((success)=>
    {
      this.enable();
      if(!success)
        this.api.showMessage(MessageType.error,this.api.strings.portal.generic_error);
      else
          this.success=true;
    },err=>
    {
      this.enable();
      this.api.showMessage(MessageType.error,this.api.strings.portal.generic_error);
    }).catch((error)=>
    {
      this.enable();
      this.api.showMessage(MessageType.error,this.api.strings.portal.generic_error);
    });

  }
}
