<nav *ngIf="displaySalesBanner" class="navbar navbar-default text-center p-0">
  <div class="container-fluid no-gutters pb-1 pt-2 pt-sm-1">
    <div class="row center-row">
      <div class="offset-md-2 offset-lg-1 col-xs-6 col-lg-7 col-sm-6 col-xs-12 px-0 pr-sm-3 ml-auto text-right-on-desk">
        <span class="text-white fs-14">
                    {{banner_txt}} &nbsp;&nbsp;&nbsp;|

        </span>
      </div>
      <div class="col-lg-4 col-sm-4 col-xs-6 px-0 counter-container py-1 mr-auto text-white fs-14 text-left-on-desk">
        <span class="hours bord counter-numbers">{{this.hours}}</span>
        <span class="pr-3 pl-2">Hours</span>
        <span class="minutes bord counter-numbers">{{this.minutes}}</span>
        <span class="pr-3 pl-2">Mins</span>
        <span class="seconds bord counter-numbers">{{this.seconds}}</span>
        <span class="pr-3 pl-2">Secs</span>
      </div>
    </div>
  </div>
</nav>
