export interface Credentials {
  credential: string;
  partner_id: string;
}

export interface User {
  id: number;
  authentication: string;
  cityId: number;
  desktopNotifications: boolean;
  domesticNotifications: boolean;
  email: string;
  emailNotifications: boolean;
  enableDomestic: boolean;
  gender: string;
  hasPaused: boolean;
  lastDomesticRun: number;
  lastSms: number;
  membershipId: number;
  name: string;
  partnerId: string;
  password: string;
  phone: string;
  pushNotifications: boolean;
  pushToken: string;
  referralUrl: string;
  region: string;
  signupDate: number;
  smsNotifications: boolean;
  step: number;
  stripeCustomerId: string;
  stripePlanId: string;
  cartId: string;
}

export class UserModel implements User {
  id: number;
  authentication: string;
  cityId: number;
  desktopNotifications: boolean;
  domesticNotifications: boolean;
  email: string;
  emailNotifications: boolean;
  enableDomestic: boolean;
  gender: string;
  hasPaused: boolean;
  lastDomesticRun: number;
  lastSms: number;
  membershipId: number;
  name: string;
  partnerId: string;
  password: string;
  phone: string;
  pushNotifications: boolean;
  pushToken: string;
  referralUrl: string;
  region: string;
  signupDate: number;
  smsNotifications: boolean;
  step: number;
  stripeCustomerId: string;
  stripePlanId: string;
  cartId: string;

  constructor(params: Partial<UserModel>) {
    Object.assign(this, params);
  }
}

export interface Token {
  token: string;
  date: string;
}

export interface AuthResponse {
  user: User;
  token: Token;
}

export interface LoginCredentials {
  email: string,
  password: string,
}

