<div class="status-bars">
  <span *ngFor="let slide of slides" [ngClass]="['status-bar', slide.additionalClass]"></span>
</div>
<div class="container-fluid px-0 mx-0">
  <div class="row no-gutters vh100 bg-white">
    <div class="col-12 p-sm-5 col-md-8 col-xl-6 pt-5 p-4 bg-white">
       <div class="mt-4">
        <img class="img-responsive dfc-logo"
        src="https://dfcapp.s3.amazonaws.com/Dollar-Flight-Club-Logo-Original-2.png">
      </div>
      <div class="inline-block text-left pl-2 pr-4">
        <h2 class="font-weight-bold" [innerHTML]="activeSlide.title"></h2>
        <h6 class="sub-title mt-10  mb-1 mb-sm-0" [innerHTML]="activeSlide.subtitle"></h6>
        <div class="mt-4 pt-3 pl-2" *ngIf="activeSlide.step === 0">
          <form [formGroup]="newsLetterForm" (ngSubmit)="submitNewsletters()">

            <ng-container *ngFor="let newsletter of newsletters; last as isLast">
              <div class="row">
                <div class="col-12 col-sm-1">
                  <input type="checkbox" id="nextStop" [formControlName]="newsletter.id" class="checkbox">
                </div>
                <div class="col-3 col-sm-2 px-0 mt-3 mt-sm-0 mx-2 mx-sm-0">
                  <img class="px-2 newsletter-img d-block mx-sm-auto" [src]="newsletter.imageUrl">
                </div>
                <div class="col-8 col-sm-9 pr-0 pl-lg-4 mt-2 mt-sm-0 pt-1 pt-sm-0">
                  <h6 class="font-weight-bold">{{newsletter.name}}</h6>
                  <p class="font12 mb-0">{{ newsletter.description }}</p>
                </div>
              </div>
              <hr class="my-3" *ngIf="!isLast">
            </ng-container>

            <div class="col-12 col-lg-5 mx-auto text-center">
              <button class="primary-btn-dfc rounded-btn mt-4 d-inline-block py-1 w-75" [ngClass]="'primary-btn-' + this.whitelabel_config.css"
                      [disabled]="!phoneForm.valid" type="submit">Next</button>
            </div>
          </form>
        </div>
        <div class="mt-4 pt-1 mt-lg-4 mt text-center" *ngIf="activeSlide.step === 1">
          <ngb-carousel #carousel>
            <ng-template ngbSlide class="">
              <h6 class="d-inline-block font-weight-bold">Step 1:</h6>
              <p class="d-inline-block px-4 px-md-3 px-lg-5 mx-md-3 mx-xl-5">Hover over the welcome email we just sent you and click: "Add To Contacts" | <a href="https://help.activecampaign.com/hc/en-us/articles/360014531199-How-to-get-added-to-a-safe-senders-list" target="_blank">Not Gmail?</a></p>
              <img class="img-fluid mw-425 box-shadow mt-3" src="https://dfcmedia.s3.amazonaws.com/email-assets/add-to-contacts.png">
            </ng-template>
            <ng-template ngbSlide class="">
              <h6 class="d-inline-block font-weight-bold">Step 2:</h6>
              <p class="d-inline-block px-md-3 px-lg-5 mx-md-3 mx-xl-5">Select the move button and then move to your primary inbox so it doesn't get missed.</p>
              <img class="img-fluid mw-425 box-shadow mt-3" src="https://dfcmedia.s3.amazonaws.com/email-assets/move-inbox.png">
            </ng-template>
          </ngb-carousel>
        </div>
        <div class="mt-5 pt-1 mt-lg-4 mt text-center" *ngIf="activeSlide.step === 2">
          <form [formGroup]="phoneForm" (ngSubmit)="addPhoneNum()" class="d-inline-block w-auto px-5 mt-5" autocomplete="off" novalidate>
            <input
              formControlName="phone"
              class="form-control text-center"
              [ngClass]="{'input-error':phoneForm.invalid && phoneForm.enabled, 'input-success':phoneForm.valid && phoneForm.enabled && phoneForm.dirty}"
              placeholder="(000) 000-0000"
              mask="(000) 000-0000"
            >
            <div class="mt-2">
              <span class="input-error-message text-center" *ngIf="!phoneForm.controls.phone.valid&&!submitSuccess&&phoneForm.enabled">* Required format: (000) 000-0000</span>
              <span class="input-error-message text-center" *ngIf="submitError&&!submitSuccess">* Your phone number could not be updated</span>
            </div>
            <button class="primary-btn-dfc rounded-btn mt-4 d-inline-block py-1 w-50" [ngClass]="'primary-btn-' + this.whitelabel_config.css"
              [disabled]="!phoneForm.valid" type="submit">
              <span *ngIf="phoneForm.dirty">Submit</span>
              <span *ngIf="!phoneForm.dirty">Next</span>
            </button>
          </form>
        </div>
        <div class="px-0 mt-4 pt-1 text-center mb-4">
          <button *ngIf="activeSlide.step != 2 && activeSlide.step != 0" class="primary-btn-dfc rounded-btn mt-4 d-inline-block py-1" [ngClass]="'primary-btn-' + this.whitelabel_config.css" (click)="nextSlide()">
            {{activeSlide.step === slides.length-1 ? 'Skip': 'Next'}}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 col-xl-6 d-none d-md-block">
      <div class="img-container h-100"></div>
    </div>
  </div>
</div>
