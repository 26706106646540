<new-navigation class="mb-4"></new-navigation>
<div>
  <div class="container-fluid px-0 mx-0">
    <div class="row no-gutters vh100">
      <div class="col-12 col-md-8 col-lg-6 login-container">
          <h1 class="display-4 text-center pb-2">Login</h1>
          <form [formGroup]="loginForm" autocomplete="off" novalidate>
            <div class="form-group">
              <input formControlName="email" type="email" class="form-control" placeholder="{{api.strings?.portal.login_email}}" [ngClass]="{'input-error':validationActive&&!loginForm.controls.email.disabled&&!loginForm.controls.email.valid}" (keypress)="eventHandler($event)">
              <div class="input-error-message" *ngIf="validationActive&&!loginForm.controls.email.disabled&&!loginForm.controls.email.valid">* {{api.strings?.portal.email_required}}</div>
            </div>
            <div class="form-group">
              <input formControlName="password" type="password" class="form-control" placeholder="{{api.strings?.portal.password_placeholder}}" [ngClass]="{'input-error':validationActive&&!loginForm.controls.password.disabled&&!loginForm.controls.password.valid}" (keypress)="eventHandler($event)">
              <div class="input-error-message" *ngIf="validationActive&&!loginForm.controls.password.disabled&&!loginForm.controls.password.valid">* {{api.strings?.portal.password_required}}</div>
            </div>
          </form>
          <div style="height: 60px;">
            <button class="btn btn-md btn-block mt-3 px-4" [ngClass]="'primary-btn-' + whitelabel_config?.css" [ngClass]="{'loading':busy}" (click)="signIn();">Login</button>
            <a class="link my-2" routerLink="/recover">Forgot password?</a>
          </div>
          <div>
            <p class="fancy-line my-4"><span>Or</span></p>
            <div *ngIf="loginError" class="alert alert-danger" role="alert">
              {{ loginError }}
<!--              You are not a member. Please visit the <a href="https://signup.dollarflightclub.com">signup page.</a>-->
            </div>
            <button class="btn d-block mx-auto" #loginRef>
            </button>
            <p class="text-center my-2" style="font-size: 11px;">By signing up you agree to the <a href="https://dollarflightclub.com/terms-of-use/" target="_blank">Terms of Use</a> and <a href="https://dollarflightclub.com/privacy-policy/" target="_blank">Privacy Policy</a></p>
          </div>
      </div>
      <div class="col-12 col-md-4 col-lg-6">
        <div class="img-container h-100"></div>
      </div>
    </div>

  </div>
</div>
