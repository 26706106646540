import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Api} from '../../services/api.service';
import {DEFAULT_WHITELABEL, findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {CheckoutComponent} from "../checkout/checkout.component";
import {UserModel} from "../../models/Authentication";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  whitelabel_config: any;
  isPremiumUser: boolean;
  user: UserModel

  constructor(private router:Router,
              private api: Api, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    this.api.user$.subscribe(user =>{
      this.user = user
      if(user){
        this.whitelabel_config = findWhiteLabel(user.partnerId);
        this.isPremiumUser = this.api.premium;
      }
    });
  }

  upgrade(){
    const checkout = this.modalService.open(CheckoutComponent)
    const component = checkout.componentInstance as CheckoutComponent
    component.user = this.user
    component.onboardingRedirect = false
    component.location = 'Members Dashboard - Menu'
  }

  logout(){
    this.api.logout();
  }
}
