<div class="concierge-modal">
  <div class="close-button" (click)="skip()">&times;</div>
  <div class="pro-banner">
    <div>Regular Price</div>
    <div><span style="text-decoration: line-through">$1,690</span></div>
  </div>
  <div class="concierge-container">
    <img src="https://dfcapp.s3.amazonaws.com/Dollar-Flight-Club-Logo-Original-2.png"
         class="img-heading"/>
    <img src="https://dfcapp.s3.amazonaws.com/assets/images/dfc-logo-md-white.png" class="img-heading-mobile"/>
    <h4 class="con-heading"> You’re set with trip planning! How about saving 90% on every flight?</h4>
    <p class="con-p con-desc">If you sign up before midnight, try Dollar Flight Club deal alerts for just $1 to save up
      to $2k on every flight.</p>
    <div class="row perks ml-n-25 text-left">
      <div class="col-md-12">
        <ul class="fa-ul">
          <li class="con-p"><span class="fa-li"><i class="fa fa-check-circle-o perk-list pr-2"
                                                   aria-hidden="true"></i></span> Try the club for just $1. (cancel
            anytime)
          </li>
          <li class="con-p"><span class="fa-li"><i class="fa fa-check-circle-o perk-list pr-2"
                                                   aria-hidden="true"></i></span>Never miss roundtrip deals like: 🇺🇸
            Hawaii from $119, 🇬🇷 Greece from $273, 🇫🇷 Paris from $283, & more
          </li>
          <li class="con-p"><span class="fa-li"><i class="fa fa-check-circle-o perk-list pr-2"
                                                   aria-hidden="true"></i></span>Get instant alerts when we find deals
            up to 90% off leaving your home airport.
          </li>
        </ul>
        <button class="btn-lg con-btn"
                (click)="redirectToDfc()">
          <span>Try it for $1</span>
        </button>
        <div class="stripe-pwr">
          Powered by
          <img class="stripe-logo" src="https://dfcmedia.s3.amazonaws.com/email-assets/stripe-logo.png">
        </div>
      </div>
    </div>
  </div>
</div>

