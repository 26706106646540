import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Api } from '../services/api.service';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "../services/api.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
var LoggedInGuard = /** @class */ (function () {
    function LoggedInGuard(api, router, http) {
        this.api = api;
        this.router = router;
        this.http = http;
    }
    LoggedInGuard.prototype.canActivate = function (next, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 4]);
                        return [4 /*yield*/, this.http.post(environment.baseUrl + "/dfcmembers/session", {}, { withCredentials: true }).toPromise().then(function (res) {
                                var response = res;
                                if (response.error) {
                                    _this.router.navigateByUrl('/login');
                                    return false;
                                }
                                _this.api.logged = true;
                                _this.api.setUser(response);
                                return true;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_1 = _a.sent();
                        return [4 /*yield*/, this.router.navigateByUrl('/login')];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LoggedInGuard.ngInjectableDef = i0.defineInjectable({ factory: function LoggedInGuard_Factory() { return new LoggedInGuard(i0.inject(i1.Api), i0.inject(i2.Router), i0.inject(i3.HttpClient)); }, token: LoggedInGuard, providedIn: "root" });
    return LoggedInGuard;
}());
export { LoggedInGuard };
