<div class="row">
  <div class="col-sm-12 col-md mt-5 paragraph-container" [class.order-md-12]="flippedRow">
    <h1 class="text-left">
     <span [ngClass]="titleClass"> {{mainTitle}} </span>
      <br>
      <span class="cust-header" [ngClass]="highlightClass">
        {{highlightTitle}}
      </span>
    </h1>
    <div class="text-container pr-md-0">
      <p [ngClass]="paragraphClass" *ngFor="let p of paragraphs">
        {{p}}
      </p>
  <button *ngIf="button" [ngClass]="button" (click)="open(email)">Join Now &#8594;</button>
    </div>
  </div>
  <div class="col-sm-12 col-md text-center mt-4 mt-md-0">
    <img class="img-size" *ngIf="!imgClass; else perk" [ngClass]="flippedRow ? 'float-md-left' : 'float-md-right'" [src]="imgSrc" alt="">
  </div>
  <ng-template #perk>
    <div class="col-sm-12 col-md text-center mt-4 mt-md-0">
      <img class="img-size" [ngClass]="imgClass" [ngClass]="flippedRow ? 'float-md-left' : 'float-md-right'" [src]="imgSrc" alt="">
    </div>
  </ng-template>

  <ng-template #email let-modal>
    <div class="modal-body pb-0" style="padding-bottom: 10px !important;">
      <form class="mx-auto" [formGroup]="emailForm" autocomplete="off" novalidate>
        <div>
          <p class="max-w-fit mx-auto">Please enter your email:</p>
          <input type="email" class="form-control form-control-lg d-block mx-auto"
                 placeholder="Email Address"
                 formControlName="email"
                 [ngClass]="validationState(emailForm.get('email'))"
          >
          <div class="invalid-feedback my-2">
            Please enter a Valid Email
          </div>
          <button class="btn primary-btn-dfc mx-auto d-block w-100 btn-lg mt-3"
                  [disabled]="!validEmail"
                  (click)="checkoutSession()"
          >
            Done
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
