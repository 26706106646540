import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Api, CONCIERGE_ORIGIN } from '../../services/api.service';
import { validationState } from '../../utilities/helpers';
import { DfcModalComponent } from '../dfc-modal/dfc-modal.component';
import { Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { Title } from '@angular/platform-browser';
import { EmailModalComponent } from '../email-modal/email-modal.component';
var TravelProductComponent = /** @class */ (function () {
    function TravelProductComponent(api, route, formBuilder, modalService, titleService, elementRef, renderer) {
        this.api = api;
        this.route = route;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.titleService = titleService;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.isCollapsed = true;
        this.isCollapsed1 = true;
        this.isCollapsed2 = true;
        this.isCollapsed3 = true;
        this.isCollapsed4 = true;
        this.isCollapsed5 = true;
        this.isCollapsed6 = true;
        this.isCollapsed7 = true;
        this.checkoutSuccess = false;
        this.validationState = validationState;
        this.highlightClass = 'highlight-orange';
        this.paragraphClass = 'pr-lg-5';
        this.rowContent = {
            row1: {
                mainTitle: 'LIFETIME ACCESS TO EXPERTS',
                highlightTitle: 'DEDICATED TRIP PLANNING',
                paragraphs: [
                    'Concierge is our solution to travel planning stress, over spending, and wasting time.',
                    'Rather than paying every time you need a trip planned, with Concierge, you’ll get lifetime access to our expert travel concierge team who will take care of it all, from finding the best deals to crafting a daily itinerary for your dream getaway.',
                    'Just let us know where you’re going, then sit back and relax. We’ve got you covered.'
                ],
                imgSrc: 'https://dfcapp.s3.amazonaws.com/card-img-1.png'
            },
            row2: {
                mainTitle: 'THE BEST DEALS ON',
                highlightTitle: 'FLIGHTS & HOTELS',
                paragraphs: [
                    'With Concierge, you\'ll get a 10X return on your investment after just your first trip.',
                    'Our expert team of travel planners use their knowledge, skills, and the latest technology to find you the best possible deals on flights and hotels.  It’s easy - just let us know your travel preferences then we’ll get to work finding the best flights and hotels to fit your needs. Request anything from certain airlines, hotels, and much more.'
                ],
                imgSrc: 'https://dfcapp.s3.amazonaws.com/card-img-2.png'
            },
            row3: {
                mainTitle: 'WE\'LL BUILD THE',
                highlightTitle: 'ITINERARY OF YOUR DREAMS',
                paragraphs: [
                    'Cookie-cutter trip itineraries are a thing of the past with DFC Concierge.',
                    'Our trips are designed to be simple, yet unforgettable, with every detail carefully curated to meet your unique preferences and needs. Using the KISS method (Keep it simple, stupid!), we ask straightforward questions in our trip survey to learn more about your preferred activities, budget, and accommodations. Based on your responses, we\'ll create a custom itinerary just for you.',
                    'Let us plan your next adventure and get ready for the trip of a lifetime.'
                ],
                imgSrc: 'https://dfcapp.s3.amazonaws.com/card-img-3.png'
            },
            row4: {
                mainTitle: 'FIRST CLASS',
                highlightTitle: 'SUPPORT',
                paragraphs: [
                    'As a Concierge member, you get access to our priority support team. That means you get fast and reliable assistance with any questions or concerns you may have.',
                    'Need to update your travel plans? No problem. Have a question about travel? No problem.',
                    'Our priority support team is made up of experienced travel professionals  dedicated to making sure you have the best travel experience possible.'
                ],
                imgSrc: 'https://dfcapp.s3.amazonaws.com/card-img-4.png'
            }
        };
    }
    Object.defineProperty(TravelProductComponent.prototype, "validEmail", {
        get: function () {
            return this.emailForm.get('email').valid;
        },
        enumerable: true,
        configurable: true
    });
    TravelProductComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.emailForm = this.formBuilder.group({
            email: this.formBuilder.control('', [
                Validators.required,
                Validators.email,
            ])
        });
        var params = this.route.snapshot.queryParams;
        this.userEmail = params.email;
        this.redirect = params.redirect === 'true';
        this.partnerId = this.route.snapshot.params.partner_id;
        this.utmSource = params.utm_source;
        this.utmMedium = params.utm_medium;
        this.coupon = params.coupon;
        this.checkoutSuccess = params.success === 'true';
        // fixme: BEN'S dumb hack to get around using async with ngOnInit
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, existingUser;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.checkMembership(this.userEmail)];
                    case 1:
                        _a.user = _b.sent();
                        if (!(this.checkoutSuccess && (!this.user || (typeof this.user !== 'boolean' && this.user.membershipId === Memberships.free)))) return [3 /*break*/, 3];
                        existingUser = !!this.user;
                        return [4 /*yield*/, this.openDfcModal(existingUser)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    };
    TravelProductComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this.changeFavicon('https://getaconcierge.com/wp-content/uploads/2023/11/Concierge-Favicon.png'); }, 2000);
        setTimeout(function () { return _this.titleService.setTitle('Concierge'); }, 2000);
        if (this.redirect) {
            setTimeout(function () { return _this.openEmail(); }, 0);
        }
    };
    TravelProductComponent.prototype.openEmail = function () {
        var emailModal = this.modalService.open(EmailModalComponent, { centered: true });
        var component = emailModal.componentInstance;
        component.origin = CONCIERGE_ORIGIN;
        component.coupon = this.coupon;
        component.partnerId = this.partnerId;
        component.userEmail = this.userEmail;
        component.source = this.utmSource;
        component.medium = this.utmMedium;
        component.redirect = this.redirect;
    };
    TravelProductComponent.prototype.openDfcModal = function (existingUser) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var concierge, component;
            return tslib_1.__generator(this, function (_a) {
                concierge = this.modalService.open(DfcModalComponent);
                component = concierge.componentInstance;
                component.user = this.userEmail;
                component.existingUser = existingUser;
                return [2 /*return*/];
            });
        });
    };
    TravelProductComponent.prototype.changeFavicon = function (newFaviconUrl) {
        var head = this.elementRef.nativeElement.ownerDocument.head;
        var existingFavicon = head.querySelector('#app-favicon');
        if (existingFavicon) {
            this.renderer.removeChild(head, existingFavicon);
            var link = this.renderer.createElement('link');
            link.id = 'favicon';
            link.rel = 'icon';
            link.type = 'image/x-icon';
            link.href = newFaviconUrl;
            this.renderer.appendChild(head, link);
        }
    };
    TravelProductComponent.prototype.checkoutSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.userEmail = this.userEmail || this.emailForm.get('email').value;
                        if (!this.userEmail) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.api.travelProductCheckout(this.userEmail, this.partnerId, this.coupon, this.utmSource, this.utmMedium)
                                .subscribe(function (res) {
                                window.location.href = res;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TravelProductComponent.prototype.open = function () {
        this.userEmail
            ? this.checkoutSession()
            : this.openEmail();
    };
    return TravelProductComponent;
}());
export { TravelProductComponent };
