import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() {
    mixpanel.init("4c97f81c1a069acb9b64de85781bfeea");
  }

  track(msg: string, properties: any = {}){
    mixpanel.track(msg, properties);
  }

  peopleSetOnce(properties: any = {}){
    mixpanel.people.set_once(properties);
  }

  identify(id: string){
    mixpanel.identify(id);
  }

}
