<app-countdown-banner
  [displaySalesBanner]="whitelabelConfig?.signup.banner"
  [banner_txt]="whitelabelConfig?.signup.banner_text"
>
</app-countdown-banner>
<div class="container-contact100">
  <div class="wrap-contact100">
    <div class="contact100-form validate-form">
      <div>
        <img
          [ngClass]="whitelabelConfig?.css + '-logo'"
          class="img-responsive"
          [src]="whitelabelConfig?.logos.main"
        />
      </div>
      <h2>Also, have a dream destination?</h2>
      <h6 class="lh-26">
        Not only do we find incredible deals to unexpected destinations, we also strive to do the same for your top dream destinations. Choose up to 10 destinations now.
        <br>
        <br>
        Here's a few popular choices:
      </h6>
      <div class="suggested-destinations" *ngIf="suggestedDestinations.length > 0">
        <div class="suggested-destination-item"
             *ngFor="let destination of suggestedDestinations; let i = index" (click)="select(destination)">
          <span class="dest-name dest-name-select">{{ destination.text }}</span>
          <button class="remove-btn add-btn">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
      <form
        class="capture-form btn btn-md btn-block"
        [formGroup]="searchForm"
        autocomplete="off"
        novalidate
      >
        <div class="input-container">
        <input
          #destination
          autofocus
          type="text"
          class="form-control form-control-md"
          placeholder="Search Cities..."
          formControlName="searchDest"
        />
        <i class="fa fa-search search-icon"></i>
        </div>
        <ul class="list-group" *ngIf="!loading">
          <li
            class="list-group-item"
            (click)="select(destination)"
            *ngFor="let destination of destinations; let i = index"
          >
            {{ destination.text }}
          </li>
        </ul>

        <p class="fs-11 addingMsg" *ngIf="userDestinations.length > 9">
          <b>*</b> Please only add 10 dream destinations!
        </p>

        <ul class="list-group dest-list-group box suggested-destinations" *ngIf="userDestinations.length > 0">
          <li
            class="list-group-item btn btn-sm btn-block dest-btn selected-destination-item"
            *ngFor="let destination of userDestinations; let i = index">
            <span class="dest-name">{{ destination.text }}</span>
            <button class="remove-btn" (click)="removeDestination(destination)">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </li>
        </ul>

        <button
          [ngClass]="'primary-btn-' + whitelabelConfig?.css"
          [disabled]="loading || userDestinations?.length < 1"
          class="btn btn-md btn-block"
          style="margin: 35px auto 0 auto; width: 50%;"
          type="button"
          (click)="setDestination()"
        >
          <span class="spinner-border spinner-border" *ngIf="loading" role="status" aria-hidden="true"></span>
          <span *ngIf="!loading"> Next </span>
        </button>

        <p class="fs-11"><b>*</b> Add at least one dream destination!</p>

        <div class="position-relative mt-6">
          <div class="pwr-block" *ngIf="whitelabelConfig?.powered_by">
            <a
              class="d-flex"
              href="https://dollarflightclub.com/"
              target="_blank"
            >
              <span class="powered-by-txt">Powered by</span>
              <div class="powered-by-logo"></div>
            </a>
          </div>
        </div>
      </form>
    </div>

    <div
      class="contact100-more flex-col-c-m"
      style="
        background-image: url('https://dfcmedia.s3.amazonaws.com/email-assets/departure-bg-img.jpg');
        justify-content: flex-start;
      "
    ></div>
  </div>
</div>
