import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {Http} from '@angular/http';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const noop = () => {
};
@Component({
  selector: 'autocompleter',
  templateUrl: './autocompleter.component.html',
  styleUrls: ['./autocompleter.component.css'],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleterComponent),
      multi: true
    }
  ]
})
export class AutocompleterComponent implements AfterViewInit,ControlValueAccessor {

  focus:boolean = false;
  searching:boolean=false;
  options:{value:any,text:string,data:any}[]=[];
  empty:boolean=false;
  lastQuery:string="";
  disabled:boolean=false;
  dropdownLeft:string="-2000px";
  dropdownTop:string="-2000px";
  dropdownWidth:string="200px";
  selectedValue:any;

  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('textContainer') textContainer: ElementRef;
  @Output("selected") valueSelectedEvent:EventEmitter<any>=new EventEmitter<any>();
  @Input("query") query:any;
  @Input("placeholder") placeholder:string="Select...";
  @Input("loadingText") loadingText:string="Loading";
  @Input("noResultsText") noResultsText:string ="Nothing found";
  @Input("searchPlaceholder") searchPlaceholder:string="Search";
  @Input("mode") mode:string="select";
  @Input("url") url:string;
  @Input("results") results:number=10;
  @Input("selectedValue")
  set currentValue(value:any)
  {
    this.value=value;
  }

  constructor(public http:Http) { }

  ngAfterViewInit() {
    document.addEventListener("click",()=> {
      setTimeout(()=> {
        if(this.focus && this.searchBox && this.searchBox.nativeElement && document.activeElement!=this.searchBox.nativeElement)
          this.focus = false;
      },20);
    });

    document.addEventListener("scroll", ()=> {
      if(this.focus && this.textContainer && this.textContainer.nativeElement) {
        let rect = this.textContainer.nativeElement.getBoundingClientRect();
        this.dropdownLeft=rect.left.toString()+'px';
        this.dropdownTop=(rect.top+rect.height).toString()+'px';
        this.dropdownWidth=this.textContainer.nativeElement.offsetWidth+'px';
      }
    });
  }


  search()
  {
    if(this.url=="")
      this.empty=true;
    else if(this.searchBox.nativeElement.value=="")
    {
      this.searching=false;
      this.empty=false;
      this.options=[];
    }
    else if(this.searchBox.nativeElement.value!=this.lastQuery)
    {
      this.searching=true;
      this.empty=false;
      setTimeout(()=>
      {
        this.lastQuery=this.searchBox.nativeElement.value;
        this.http.post(this.url,{query:this.searchBox.nativeElement.value,results:this.results,data:this.query},{withCredentials: true}).toPromise().then(res=>
          {
            let responseInfo:any = res.json();
            if(!responseInfo||responseInfo.data.length==0)
            {
              this.options=[];
              this.searching=false;
              this.empty=true;
            }
            else
            {
              this.searching=false;
              this.empty=false;
              this.options=responseInfo.data;
            }
          }).catch((error)=>{
              this.options=[];
              this.searching=false;
              this.empty=true;
          });
      },500);

    }
  }
  focusOn()
  {
    if(this.disabled)
    {
      this.searching=false;
      this.empty=false;
      this.options=[];
      this.focus=false;
      return;
    }
    if(!this.focus)
    {

      this.searching=false;
      this.empty=false;
      this.options=[];

      let rect=this.textContainer.nativeElement.getBoundingClientRect();

      this.dropdownLeft=rect.left.toString()+'px';
      this.dropdownTop=(rect.top+rect.height).toString()+'px';
      this.dropdownWidth=this.textContainer.nativeElement.offsetWidth+'px';
    }
    this.focus=!this.focus;
    setTimeout(()=>
    {
      if (this.searchBox.nativeElement) {
        this.searchBox.nativeElement.focus();
        this.searchBox.nativeElement.value = "";
      }
    },10);
  }
  select(value:any)
  {
    this.value=value;

  }
  setDisabledState(isDisabled: boolean)
  {
    this.disabled=isDisabled;
  }

  public onTouchedCallback: () => void = noop;
  public onChangeCallback: (_: any) => void = noop;

  get value(): any
  {
    return this.selectedValue;
  };

  set value(v: any)
  {
    if(typeof v!="undefined"&&v!=null&&v)
      this.selectedValue=v;
    else
      this.selectedValue=null;
    this.onChangeCallback(this.selectedValue);
    this.valueSelectedEvent.emit(this.selectedValue);
  }
  onBlur()
  {
    this.onTouchedCallback();
  }
  writeValue(value: any)
  {
    this.value=value;
  }
  registerOnChange(fn: any)
  {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any)
  {
    this.onTouchedCallback = fn;
  }

}
