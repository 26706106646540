import { OnInit, Renderer2, } from '@angular/core';
import { Api } from '../../services/api.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CheckoutComponent } from '../checkout/checkout.component';
import { Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { ActivatedRoute } from '@angular/router';
import { findWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(api, renderer, ngxModalService, route, _document) {
        this.api = api;
        this.renderer = renderer;
        this.ngxModalService = ngxModalService;
        this.route = route;
        this._document = _document;
        this.show = false;
        this.dealsTitle = 'Flight Deals';
        this.displaySalesBanner = false;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.memberships = Memberships;
        window.scrollTo(0, 0);
        this.api.user$.subscribe(function (user) {
            if (user) {
                _this.whitelabel_config = findWhiteLabel(user.partnerId);
                _this.displaySalesBanner =
                    _this.whitelabel_config.members_dashboard.banner
                        && _this.api.user.membershipId === _this.memberships.free;
            }
        });
        this.route.queryParams.subscribe(function (params) {
            if (params.dealType) {
                params.dealType === 1 ? _this.dealsTitle = 'Weekend Getaways' : _this.dealsTitle = 'Flight Deals';
                window.scroll(0, 0);
            }
        });
    };
    Object.defineProperty(DashboardComponent.prototype, "stateName", {
        get: function () {
            return this.show ? 'show' : 'hide';
        },
        enumerable: true,
        configurable: true
    });
    DashboardComponent.prototype.toggle = function () {
        this.show = !this.show;
    };
    return DashboardComponent;
}());
export { DashboardComponent };
