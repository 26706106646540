export interface PartnerPerksModel{
    id: number;
    name: string;
    link: string;
    image: string;
    discountCopyFull: string;
    discountCopyShort: string;
    mainCopy: string;
    discountCode: string;
}
export enum ProductClass {
  DFC = 'dfc',
  PERKS = 'perks',
  CONCIERGE = 'concierge'
}
export enum ProductTier {
  free = 0,
  premium = 1,
  premiumPlus = 2,
  conciergeUser = 2
}
