import * as moment from "moment";
import {FlightClass} from "@dollar-flight-club/shared_utilities/lib/models/Deal";


const SKYSCANNER_DATE_FMT = 'YYYY-MM-DD';

export function buildSkyScannerLink(
  originCode: string,
  destinationCode: string,
  departureDateTimestamp: number,
  returnDateTimestamp: number,
  flightClass: FlightClass,
) {

  const skyScannerBaseUrl = 'https://www.skyscanner.com/g/referrals/v1/flights/day-view';
  const departureDate = moment.unix(departureDateTimestamp).utc().format(SKYSCANNER_DATE_FMT);
  const returnDate = moment.unix(returnDateTimestamp).utc().format(SKYSCANNER_DATE_FMT);
  const airportFromTo = `origin=${originCode}&destination=${destinationCode}`;
  const dates = `outboundDate=${departureDate}&inboundDate=${returnDate}`;
  const seatingClass = flightClass === FlightClass.Business ? `cabinclass=business&` : `cabinclass=economy&`;
  const otherParams = `?${seatingClass}${airportFromTo}&${dates}&adults=1&adultsv2=1&rtn=1&children=0&childrenv2=&infants=0&qp_prevCurrency=USD&qp_prevProvider=DS_RDS_Standard&adId=1100662&campaignId=13416&mediaPartnerId=2871437`;
  return `${skyScannerBaseUrl}${otherParams}`
}


const KIWI_DATE_FMT = 'YYYY-MM-DD';

export function buildKiwiLink(
  originCode: string,
  destinationCode: string,
  firstDepartureTimestamp: number,
  lastDepartureTimestamp: number,
  firstReturnTimestamp: number,
  lastReturnTimestamp: number,
  flightClass: FlightClass,
) {
  const tequilaBaseUrl = 'https://www.kiwi.com/us/search/results/';
  const departureDateStart = moment.unix(firstDepartureTimestamp).format(KIWI_DATE_FMT);
  const departureDateEnd = moment.unix(lastDepartureTimestamp).format(KIWI_DATE_FMT);
  const returnDateStart = moment.unix(firstReturnTimestamp).format(KIWI_DATE_FMT);
  const returnDateEnd = moment.unix(lastReturnTimestamp).format(KIWI_DATE_FMT);
  const airportFromTo = `${originCode}/${destinationCode}/`;
  return `${tequilaBaseUrl}${airportFromTo}${departureDateStart}_${departureDateEnd}/${returnDateStart}_${returnDateEnd}` +
    `?cabinClass=${getKiwiFlightClass(flightClass)}`;
}

function getKiwiFlightClass(flightClass: FlightClass): string {

  if(flightClass === FlightClass.FirstClass){
    return 'FIRST_CLASS';
  }
  return flightClass.toUpperCase();
}
