import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { Membership, Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { Api } from '../../../services/api.service';
import { MixpanelService } from '../../../services/mixpanel.service';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
var CancelModalComponent = /** @class */ (function () {
    function CancelModalComponent(router, ngxModalService, api, mixpanel) {
        this.router = router;
        this.ngxModalService = ngxModalService;
        this.api = api;
        this.mixpanel = mixpanel;
        //callback passed from parent to switch plans.
        this.onComplete = new EventEmitter();
        this.plans = [];
        this.benefitsShort = [];
        this.downgraded = false;
        this.pauseSuccess = false;
        this.error = false;
        this.stepTwo = false;
        this.extendTrialPeriod = false;
        this.loading = false;
        this.benefitsShort = this.getShortListBenefits();
    }
    CancelModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.couponSelected = { key: this.couponId, basePlan: 'none' };
        var mems = _.reverse(Membership.getBaseMemberships());
        this.plans = _.map(mems, function (mem) {
            var memTar = _.find(_this.benefitsShort, ['key', mem.key]);
            mem.benefits = memTar ? memTar.benefits : mem.benefits;
            return mem;
        });
        this.api.user$.subscribe(function (u) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, id, basePlan;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.membershipInfo()];
                    case 1:
                        _a.subscription = _b.sent();
                        if (this.subscription.trialActive) {
                            this.hideDiscount = true;
                            this.onTrial = true;
                        }
                        id = _.get(u, 'membership', Memberships.free);
                        this.membership = Membership.getMembershipSpec(id);
                        basePlan = _.find(this.plans, ['basePlan', this.membership.basePlan]);
                        this.setSelectedOption(basePlan);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    CancelModalComponent.prototype.close = function () {
        this.ngxModalService.close('cancelModal');
    };
    CancelModalComponent.prototype.open = function () {
        this.ngxModalService.open('cancelModal');
    };
    CancelModalComponent.prototype.setSelectedOption = function (selectedOption) {
        this.selectedOption = selectedOption;
    };
    CancelModalComponent.prototype.getShortListBenefits = function () {
        return [{
                key: Memberships.free,
                benefits: [
                    '25% of our flight deals',
                    'No domestic flight deals',
                    'Ads'
                ],
            },
            {
                key: Memberships.premium,
                benefits: [
                    '4X more of our best deals',
                    'Weekend domestic flight deals',
                    'Premium partner discounts'
                ]
            },
        ];
    };
    CancelModalComponent.prototype.openModal = function (updateBilling, modal) {
        if (updateBilling === void 0) { updateBilling = false; }
        if (modal === void 0) { modal = 'checkoutModal'; }
        var targetModal = this.ngxModalService.getModal(modal);
        if (targetModal) {
            targetModal.open();
        }
    };
    CancelModalComponent.prototype.modalClose = function (modal) {
        if (modal === void 0) { modal = 'pauseModal'; }
        var targetModal = this.ngxModalService.getModal(modal);
        if (targetModal) {
            targetModal.close();
            this.loading = false;
            window.location.href = '/profile/account';
        }
    };
    CancelModalComponent.prototype.takeSurvey = function () {
        window.open('https://form.typeform.com/to/kbpfzc8j', '_blank', 'noopener');
    };
    CancelModalComponent.prototype.getMembershipLabel = function () {
        return _.replace(this.membership.label, ' Trial', '');
    };
    CancelModalComponent.prototype.pauseAccount = function () {
        var _this = this;
        this.loading = true;
        this.api.pause().pipe(tap(function () {
            _this.loading = false;
            _this.pauseSuccess = true;
            _this.openModal(false, 'pauseModal');
        }), catchError(function (error) {
            _this.loading = false;
            _this.pauseSuccess = false;
            _this.openModal(false, 'pauseModal');
            console.error(error);
            return of(null);
        })).subscribe();
    };
    CancelModalComponent.prototype.extendTrial = function () {
        var _this = this;
        this.loading = true;
        this.api.extendTrial().pipe(tap(function () {
            _this.loading = false;
            _this.extendTrialPeriod = true;
            _this.openModal(false, 'trialExtendedModal');
        }), catchError(function (error) {
            _this.loading = false;
            _this.extendTrialPeriod = false;
            _this.openModal(false, 'trialExtendedModal');
            console.error(error);
            return of(null);
        })).subscribe();
    };
    CancelModalComponent.prototype.trackClick = function (msg) {
        this.mixpanel.track("Clicked " + msg + " button");
    };
    CancelModalComponent.prototype.continueOn = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.stepTwo = true;
                return [2 /*return*/];
            });
        });
    };
    CancelModalComponent.prototype.cancelAccount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var success;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.downgraded = true;
                        this.stepTwo = false;
                        this.api.showPageLoader();
                        return [4 /*yield*/, this.api.unsubscribe()];
                    case 1:
                        success = _a.sent();
                        if (!success) {
                            this.error = true;
                        }
                        this.api.hidePageLoader();
                        return [2 /*return*/];
                }
            });
        });
    };
    CancelModalComponent.prototype.keepPlanWithCoupon = function () {
        var _this = this;
        this.close();
        var couponCode = this.onTrial ? environment.trialCoupon : environment.cancellationCoupon;
        this.api.applyCoupon(couponCode).pipe(tap(function () {
            _this.openModal(false, 'discountModal');
        }), catchError(function (error) {
            _this.openModal(false, 'discountFailedModal');
            console.error(error);
            return of(null);
        })).subscribe();
    };
    return CancelModalComponent;
}());
export { CancelModalComponent };
