import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {Api} from '../../services/api.service';
import {Router} from '@angular/router';
import {environment} from "../../../environments/environment";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-dfc',
  templateUrl: './dfc-modal.component.html',
  styleUrls: ['./dfc-modal.component.css']
})
export class DfcModalComponent {
  @Input() user: string;
  @Input() existingUser: boolean;
  loading = false

  constructor(private cd: ChangeDetectorRef,
              private api: Api,
              private router: Router,
              private modalService: NgbModal) {
  }

  redirectToDfc() {
    const link = `${environment.url}/${this.existingUser ? 'upgrade' : 'signup'}/14daytrial?email=${this.user}&concierge=true`;
    window.open(link, '__blank', 'noopener, noreferrer');
    this.modalService.dismissAll();
  }

  skip(){
    this.modalService.dismissAll()
  }
}

