import {Component, OnInit} from '@angular/core';
import {filterMenuAnimations} from '../../utilities/animations';
import {Router} from '@angular/router';
import {Api} from '../../services/api.service';
import {LegacyPlans, MembershipName, Memberships} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {DEFAULT_WHITELABEL, findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import * as _ from 'lodash';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {MixpanelService} from '../../services/mixpanel.service';
import {CheckoutComponent} from "../checkout/checkout.component";
import {UserModel} from "../../models/Authentication";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'vertical-nav',
  templateUrl: './vertical-nav.component.html',
  styleUrls: ['./vertical-nav.component.css'],
  animations: filterMenuAnimations
})
export class VerticalNavComponent implements OnInit {

  screenWidth: any;
  show: boolean;
  memberships: typeof Memberships;
  showFlights = true;
  showSettings: boolean;
  whitelabel_config: any;
  premiumPlusSet: Memberships[];
  premiumSet: Memberships[];
  skyscanner = 'ss';
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  user: UserModel

  constructor(private router:Router, public api: Api, private mixpanel:MixpanelService, private modalService: NgbModal) {
    this.premiumPlusSet = [Memberships.premiumPlus, Memberships.premiumPlusTrial];
    this.premiumSet = _.concat([Memberships.premium, Memberships.premiumTrial], LegacyPlans);
  }

  ngOnInit() {
    this.memberships = Memberships;
    this.checkScreenSize();
    this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    this.api.user$.subscribe(user =>{
      if(user){
        this.user = user
        this.whitelabel_config = findWhiteLabel(user.partnerId);
      }
    });
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.checkScreenSize();
    });
  }

  get stateName() {
      return this.show ? 'show' : 'hide';
  }

  checkScreenSize(): void {
    this.screenWidth = window.innerWidth;
    this.screenWidth < 1200 ? this.show = false: this.show = true;
  }

  toggle() {
    this.show = !this.show;
  }

  upgrade(){
    const checkout = this.modalService.open(CheckoutComponent)
    const component = checkout.componentInstance as CheckoutComponent
    component.user = this.user
    component.onboardingRedirect = false
    component.location = 'Members Dashboard - Vertical Nav Bar'
  }

  isActive(instruction: string): boolean {
    return this.router.isActive(instruction, true);
  }

  getNavLogo(): string {
    if (_.includes(this.premiumPlusSet, _.get(this.api, 'user.membershipId'))) {
      return this.whitelabel_config.logos[MembershipName.premiumPlus];
    } else if (_.includes(this.premiumSet, _.get(this.api, 'user.membershipId'))) {
      return this.whitelabel_config.logos[MembershipName.premium];
    } else {
      return this.whitelabel_config.logos[MembershipName.free];
    }
  }

  navTracking(tabName: string){
    this.mixpanel.track(`Clicked ${tabName} Tab`);
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }

}
