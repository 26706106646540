<new-navigation class="mb-4"></new-navigation>
<div>
  <div class="container-fluid px-0 mx-0">
    <div class="row no-gutters vh100">
      <div class="col-12 col-md-8 col-lg-6 login-container">
        <h1>{{api.strings?.portal.recover_password}}</h1>
        <p *ngIf="!success">{{api.strings?.portal.password_recover_instructions}}</p>
        <p class="success animated fadeIn" *ngIf="success">{{api.strings?.portal.recover_instructions_sent}}</p>
        <div class="row" *ngIf="!success">
          <div class="col-12">
              <form [formGroup]="recoverForm" autocomplete="off" novalidate>
            <div class="form-group">
              <label>{{api.strings?.portal.email}}:</label>
              <input formControlName="email" type="email" class="form-control" placeholder="{{api.strings?.portal.login_email}}" [ngClass]="{'input-error':validationActive&&!recoverForm.controls.email.disabled&&!recoverForm.controls.email.valid}" (keypress)="eventHandler($event)">
              <div class="input-error-message" *ngIf="validationActive&&!recoverForm.controls.email.disabled&&!recoverForm.controls.email.valid" (keypress)="eventHandler($event)">* {{api.strings?.portal.email_required}}</div>
            </div>
              </form>
          </div>
        </div>
          <a class="btn btn-primary btn-block animated fadeIn" *ngIf="success" href="{{api.config.public_area}}">{{api.strings?.portal.go_home}}</a>
          <a class="link" *ngIf="!success" routerLink="/login">{{api.strings?.portal.cancel}}</a>
          <button *ngIf="!success" class="btn btn-primary" [ngClass]="{'loading':busy}" (click)="recover();"><i class="fa fa-send"></i> {{api.strings?.portal.submit}}</button>
      </div>
      <div class="col-12 col-md-4 col-lg-6">
        <div class="img-container h-100"></div>
      </div>
    </div>
  </div>
</div>