import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '../../services/api.service';
import { LegacyPlans, MembershipName, Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { DEFAULT_WHITELABEL, findWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import * as _ from 'lodash';
import { fromEvent } from 'rxjs';
import { MixpanelService } from '../../services/mixpanel.service';
import { CheckoutComponent } from "../checkout/checkout.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
var VerticalNavComponent = /** @class */ (function () {
    function VerticalNavComponent(router, api, mixpanel, modalService) {
        this.router = router;
        this.api = api;
        this.mixpanel = mixpanel;
        this.modalService = modalService;
        this.showFlights = true;
        this.skyscanner = 'ss';
        this.premiumPlusSet = [Memberships.premiumPlus, Memberships.premiumPlusTrial];
        this.premiumSet = _.concat([Memberships.premium, Memberships.premiumTrial], LegacyPlans);
    }
    VerticalNavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.memberships = Memberships;
        this.checkScreenSize();
        this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
        this.api.user$.subscribe(function (user) {
            if (user) {
                _this.user = user;
                _this.whitelabel_config = findWhiteLabel(user.partnerId);
            }
        });
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(function (evt) {
            _this.checkScreenSize();
        });
    };
    Object.defineProperty(VerticalNavComponent.prototype, "stateName", {
        get: function () {
            return this.show ? 'show' : 'hide';
        },
        enumerable: true,
        configurable: true
    });
    VerticalNavComponent.prototype.checkScreenSize = function () {
        this.screenWidth = window.innerWidth;
        this.screenWidth < 1200 ? this.show = false : this.show = true;
    };
    VerticalNavComponent.prototype.toggle = function () {
        this.show = !this.show;
    };
    VerticalNavComponent.prototype.upgrade = function () {
        var checkout = this.modalService.open(CheckoutComponent);
        var component = checkout.componentInstance;
        component.user = this.user;
        component.onboardingRedirect = false;
        component.location = 'Members Dashboard - Vertical Nav Bar';
    };
    VerticalNavComponent.prototype.isActive = function (instruction) {
        return this.router.isActive(instruction, true);
    };
    VerticalNavComponent.prototype.getNavLogo = function () {
        if (_.includes(this.premiumPlusSet, _.get(this.api, 'user.membershipId'))) {
            return this.whitelabel_config.logos[MembershipName.premiumPlus];
        }
        else if (_.includes(this.premiumSet, _.get(this.api, 'user.membershipId'))) {
            return this.whitelabel_config.logos[MembershipName.premium];
        }
        else {
            return this.whitelabel_config.logos[MembershipName.free];
        }
    };
    VerticalNavComponent.prototype.navTracking = function (tabName) {
        this.mixpanel.track("Clicked " + tabName + " Tab");
    };
    VerticalNavComponent.prototype.ngOnDestroy = function () {
        this.resizeSubscription$.unsubscribe();
    };
    return VerticalNavComponent;
}());
export { VerticalNavComponent };
