<app-countdown-banner
  [displaySalesBanner]="whitelabelConfig?.signup.banner"
  [banner_txt]="whitelabelConfig?.signup.banner_text"
>
</app-countdown-banner>
<div class="container-contact100"
  [ngClass]="{'mt-counter': whitelabelConfig?.signup.banner}"
>
  <div class="wrap-contact100">
    <div class="contact100-more flex-col-c-m"
         style="background-image: url('https://dfcmedia.s3.amazonaws.com/email-assets/bg-backslpash.jpg'); justify-content: flex-start;">
      <div>
        <img class="dfc-white-logo" src="https://dfcmedia.s3.amazonaws.com/email-assets/dfc-plane-white.png">
      </div>
      <div class="txt-alignment container">
        <div class="row">
          <div class="offset-1 col-10 offset-1 px-md-5">
            <h1 class="text-white header-font">
              Get our best deals and features with premium<span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer"> plus</span>.
            </h1>
            <p class="text-white text-center top-subtitle">
              If you book just 1 flight we share with you, you can get up to a 10x return on your investment.
            </p>
          </div>
        </div>
      </div>
      <div class="btnbar">
        <button
          *ngIf="whitelabelConfig.signup.display_continue_btn"
          class="btn-lg btn-continue btn-free-hover"
            style="color: grey; background-color: white; font-weight: bold; border: 1px solid white"
          (click)="basicModal()">
          {{whitelabelConfig?.signup.btn_free}}
        </button>
        <button
          class="btn-lg btn-continue"
          [ngClass]="'primary-btn-' + whitelabelConfig?.css"
          (click)="checkoutModal()">
          <b>
            {{whitelabelConfig?.signup.btn_premium}}
          </b>
        </button>
      </div>
      <img
        class="arrow-down"
        src="https://dfcmedia.s3.amazonaws.com/email-assets/arrow-down.png"
      >
    </div>
  </div>
</div>

<div class="bg-white">
<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 full-card">
      <h1 class="text-left cust-header">
        GET ALL THE
        <br>
        <span class="highlight">
          DEALS
        </span>
      </h1>
      <p class="cust-info">
        Premium
        <span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer">
          Plus
        </span>
        members get ALL of the deals we find leaving their selected airports...that means all the mistake fares and highly
        discounted international and domestic deals from up to 4 selected departure airports. If you're serious about travel Premium
        <span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer">
          Plus
        </span>
        is a must have.
      </p>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img
        class="card-img box-shadow"
        src="https://dfcmedia.s3.amazonaws.com/email-assets/card-all-the-deals.png"
      >
    </div>
  </div>
</div>

<div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 grey-bg mt-6 pb-5 pt-5 container gopremium-div">
    <div class="container">
      <h1 class="go-premium-txt">Go Premium<span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer"> Plus</span> to get amazing deals like these...</h1>
      <div class="row mt-5 justify-content-center">
        <div class="sm-card col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-greece.png">
          <div class="sm-card-text">
            <p>Greece</p>
            <p>$980</p>
            <p>$298 Roundtrip</p>
          </div>
        </div>
        <div class="sm-card col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-peru.png">
          <div class="sm-card-text">
            <p>Peru</p>
            <p>$2,850</p>
            <p>$450 Roundtrip</p>
          </div>
        </div>
        <div class="sm-card col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-switzerland.png">
          <div class="sm-card-text">
            <p>Switzerland</p>
            <p>$1020</p>
            <p>$325 Roundtrip</p>
          </div>
        </div>
        <div class="sm-card col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-thailand.png">
          <div class="sm-card-text">
            <p>Thailand</p>
            <p>$3,450</p>
            <p>$1875 Roundtrip</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" style="padding-bottom: 80px">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 airport-xs-img order-12 order-lg-1">
      <img class="card-img neg-ml-2" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-multiple-airports.png">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 full-card airport-xs-txt order-1 order-lg-12">
      <h1 class="text-left cust-header">MULTIPLE
        <br><span class="highlight">AIRPORTS</span></h1>
      <p class="cust-info">Life is all about options. With Premium<span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer"> Plus</span> you can choose up to 4 departure airports that you want to get deals
        from. This is perfect for digital nomads or someone with a few good airports in their proximity.
      </p>
    </div>
  </div>
</div>

  <div class="grey-bg position-relative overflow-hidden">
    <div class="container-fluid cards-container pt-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center">
            You're in Good Company...
          </h1>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-5">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-1 float-left">
              <p class="font-weight-bold">Michael</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "I got a flight to Morocco and to Porto, Portugal for $480 round trip with luggage! Unreal. This flight would usually be $950+ depending on the time of the year. Great service!"
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-5">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-2 float-left">
              <p class="font-weight-bold">Lauren</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "I booked flights to Iceland for $350 round trip and cruised the country searching for all of its beauties. Easily saved over $500. Such a great membership, paid for itself with my first flight.”
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-3 float-left">
              <p class="font-weight-bold">Tahsan</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "How could I not book this Business class flight to Costa Rica for $542 roundtrip????? Saved me over $1,500 on airfare! Absolutely an amazing deal, I did it, and I'm super excited. your premium newsletter is f$%#@g awesome"
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-4 float-left">
              <p class="font-weight-bold">Susan</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "Dollar Flight Club, I can't thank you enough! I have been watching fares to Sydney like a hawk for nearly 25 years and this is the absolute lowest I've ever seen from my part of the country. Your service is the real deal! Keep doing what you do!"
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="bg-white" style="padding-bottom: 80px">
  <div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 full-card">
      <h1 class="text-left cust-header">MISTAKE
        <br><span class="highlight">FARES</span></h1>
      <p class="cust-info">How does flying First Class to Europe for $129 roundtrip sound? Airlines make pricing mistakes, we find
        these errors, email you about them, and help you save $2,000+ on these happy little accidents. Premium<span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer"> Plus</span> members are the only
        members that get access to these deals.</p>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <img class="card-img box-shadow" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-mistake-fares.png">
    </div>
  </div>
  </div>
</div>
<div class="grey-bg" style="padding-bottom: 80px">
  <div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ad-xs-img order-12 order-lg-1">
      <img class="card-img neg-ml-2 box-shadow" src="https://dfcmedia.s3.amazonaws.com/email-assets/email-1.png">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 full-card ad-xs-txt order-1 order-lg-12">
      <h1 class="text-left cust-header">REAL
        <br><span class="highlight">ADVENTURES</span></h1>
      <p class="cust-info">You can be our next success story, like Paula C., who got tickets to New Zealand for $250. She saved $1,800+ on roundtrip
        tickets and so can you. Our 1,000,000+. members have saved tens of millions on airfare..
      </p>
    </div>
  </div>
  </div>
</div>

</div>
  <div class="row">
    <div class="col bottom-sqr text-white text-center">
      <h4 class="mt-4">
        <span>{{whitelabelConfig.signup.btn_premium}}</span>
      </h4>
      <p class="mt-3">LOVED BY OVER 1,000,000 MEMBERS</p>
      <button class="btn mt-4 bottom-btn primary-btn-dfc"
              (click)="checkoutModal()">
                Go Premium
      </button>
      <div class="terms-n-policy">
    <div class="text-center text-white">View our <a class="text-white" href="dollarflightclub.com/terms-of-use" target="_blank" rel="noreferrer noopener">Terms</a> and <a class="text-white" href="dollarflightclub.com/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a></div>
    </div>
    </div>
  </div>


<ng-template #basic let-modal>
  <div class="modal-body">
    <button aria-label="Close" (click)="basicModalClose()" class="arrow-close nsm-dialog-btn-close" type="button"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
    <h1 class="basic-modal-header">If you are serious about traveling, give<br>Premium<span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer"> Plus</span> a second thought...</h1>
    <div class="row padded">
      <div class="col-lg-6 col-sm-12">
        <div class="card cust-card">
          <div class="card-body">
            <h2 class="card-title" style="text-align:center;">DFC Basic</h2>
            <h3 class="card-text">$0/year</h3>
            <hr />
            <ul class="perk-list">
              <li><i class="fa fa-check"></i>20% of flight deals</li>
              <div class="hide-feat">
                <li><i class="fa fa-check"></i>Up to 4 active departure airports</li>
                <li><i class="fa fa-check"></i>Domestic Flight Deals (U.S Only)</li>
                <li><i class="fa fa-check"></i>Set multiple departure airports</li>
                <li><i class="fa fa-check"></i>All the mistake fares</li>
                <li><i class="fa fa-check"></i>No ads</li>
              </div>
            </ul>
            <div class="btnbar-checkout">
              <button class="btn-oval btn-lg" (click)="goBasic()"
                      [ngClass]="'primary-btn-' + whitelabelConfig?.css">
                Join For Free
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 order-first order-lg-last">
        <div class="card cust-card">
          <div class="card-body">
            <h2 class="card-title" style="text-align:center;">DFC Premium<span *ngIf="whitelabelConfig?.signup?.premiumPlus_offer">+</span></h2>
            <h3 class="card-text">
              <span>{{whitelabelConfig?.signup.yearly_price}}</span>
            </h3>
            <hr />
            <ul class="perk-list">
              <li><i class="fa fa-check"></i>All flight deals (5X More)</li>
              <li><i class="fa fa-check"></i>Up to 4 active departure airports</li>
              <li><i class="fa fa-check"></i>Domestic Flight Deals (U.S Only)</li>
              <li><i class="fa fa-check"></i>Set multiple departure airports</li>
              <li><i class="fa fa-check"></i>All the mistake fares</li>
              <li><i class="fa fa-check"></i>No ads</li>
            </ul>
            <div class="btnbar-checkout">
              <button class="btn-oval btn-lg btn-primary" (click)="basicToCheckoutModal()"
                      [ngClass]="'primary-btn-' + whitelabelConfig?.css">
                <span><b>{{whitelabelConfig.signup.btn_premium}}</b></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
