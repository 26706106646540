import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { Api, TrialCodes } from '../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Membership, MembershipName, Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { findWhiteLabel, IWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { CheckoutComponent } from '../checkout/checkout.component';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
var UpgradeComponent = /** @class */ (function () {
    function UpgradeComponent(route, router, api, modalService, formBuilder) {
        this.route = route;
        this.router = router;
        this.api = api;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.cantUpgrade = false;
        this.emailValidationActive = false;
        this.rfsn = false;
        this.highlightClass = 'hightlight-blue';
        this.paragraphClass = 'pr-lg-5';
        this.rowContent = {
            row1: {
                mainTitle: 'GET ALL THE',
                highlightTitle: 'DEALS',
                paragraphs: [
                    'Premium members get ALL of the deals we find leaving their selected airports...that means all the mistake fares and highly discounted international and domestic deals from up to 4 selected departure airports. If you\'re serious about travel Premium is a must have.'
                ],
                imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-all-the-deals.png'
            },
            row3: {
                mainTitle: 'MULTIPLE',
                highlightTitle: 'AIRPORTS',
                paragraphs: [
                    'Life is all about options. With Premium you can choose up to 4 departure airports that you want to get deals from. This is perfect for digital nomads or someone with a few good airports in their proximity.'
                ],
                imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-multiple-airports.png'
            },
            row4: {
                mainTitle: 'MISTAKE',
                highlightTitle: 'FARES',
                paragraphs: [
                    'How does flying First Class to Europe for $129 roundtrip sound? Airlines make pricing mistakes, we find these errors, email you about them, and help you save $2,000+ on these happy little accidents. Premium members are the only members that get access to these deals.'
                ],
                imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-mistake-fares.png'
            },
            row5: {
                mainTitle: 'AD-FREE',
                highlightTitle: 'DEALS',
                paragraphs: [
                    'We get it....advertisements can be annoying. With Premium you\'ll be add free. No more 3rd party advertisements trying to sell you things.'
                ],
                imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-ad-free-deals.png'
            },
            row6: {
                mainTitle: 'REAL',
                highlightTitle: 'ADVENTURES',
                paragraphs: [
                    'You can be our next success story, like Paula C., who got tickets to New Zealand for $250. She saved $1,800+ on roundtrip tickets and so can you. Our 1,000,000+. members have saved tens of millions on airfare.'
                ],
                imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/email-1.png'
            }
        };
        this.captureForm = formBuilder.group({
            email: ["", [Validators.required, Validators.email,
                    Validators.pattern("^[a-z0-9A-Z]+[a-zA-Z0-9.\\-\\_]*@[a-zA-Z0-9.\\-\\_]+.[a-z0-9A-Z]{2,}$")]
            ]
        });
        this.premiumSet = [Memberships.premium, Memberships.premiumTrial];
        this.membershipNames = MembershipName;
        this.nextMembership = Membership.getNextPlanTier(Memberships.free);
    }
    UpgradeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.api.addInitCallback(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var params, whitelabelId, environmentVar, _a, cartId, rfsn;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = this.route.snapshot.queryParams;
                        whitelabelId = this.api.getWhiteLabelId(this.route.snapshot.params.partner_id, params.lifetime);
                        this.whitelabel_config = findWhiteLabel(whitelabelId);
                        this.partnerId = this.whitelabel_config.id;
                        environmentVar = environment.production ? 'production_plans' : 'development_plans';
                        if (this.whitelabel_config.stripe[environmentVar].premium_plus.invoice_item || params.lifetime) {
                            this.lifetime = true;
                        }
                        this.utm_source = params.utm_source;
                        this.utm_medium = params.utm_medium;
                        this.trafficSource = params.source;
                        if (!params.email) return [3 /*break*/, 3];
                        this.api.userEmail = params.email;
                        _a = this;
                        return [4 /*yield*/, this.api.checkMembership(params.email)];
                    case 1:
                        _a.user = (_b.sent());
                        this.membership = Membership.getMembershipSpec(this.user.membershipId);
                        return [4 /*yield*/, this.setUserMembership()];
                    case 2:
                        _b.sent();
                        this.setACEmail();
                        _b.label = 3;
                    case 3:
                        if (this.whitelabel_config) {
                            this.premiumPlusUpgrade = this.whitelabel_config.signup.premiumPlus_offer || this.membership.basePlan === MembershipName.premium || this.lifetime;
                        }
                        if (params.rfsn) {
                            this.rfsn = true;
                        }
                        if (params.coupon) {
                            this.coupon = params.coupon;
                            this.api.checkCoupon(this.coupon).pipe(tap(function (response) {
                                if (response && response.error) {
                                    console.error('Error checking coupon:', response.message);
                                    _this.discount = '';
                                }
                                else {
                                    _this.discount = response.percent_off ? response.percent_off + "%" : "$" + response.amount_off / 100;
                                }
                            })).subscribe();
                        }
                        if (params.code) {
                            this.code = this.trialCode;
                        }
                        if (!(this.lifetime && this.rfsn && !this.user.cartId)) return [3 /*break*/, 5];
                        cartId = this.api.genUniqueId();
                        return [4 /*yield*/, this.api.updateCartId(this.user.id, cartId)];
                    case 4:
                        _b.sent();
                        rfsn = {
                            cart: cartId,
                            id: localStorage.getItem("rfsn_v4_id"),
                            url: window.location.href,
                            aid: localStorage.getItem("rfsn_v4_aid"),
                            cs: localStorage.getItem("rfsn_v4_cs")
                        };
                        r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); });
        var deadline = new Date(); //today
        deadline.setHours(0, 0, 0, 0);
        deadline.setDate(deadline.getDate() + 1);
        setInterval(function () { return _this.setTimeRemaining(deadline); }, 1000);
    };
    UpgradeComponent.prototype.closeEmailModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.checkMembership(this.captureForm.controls.email.value)];
                    case 1:
                        _a.user = (_b.sent());
                        return [4 /*yield*/, this.setUserMembership()];
                    case 2:
                        _b.sent();
                        if (this.user) {
                            this.api.userEmail = this.captureForm.controls.email.value;
                            this.membership = Membership.getMembershipSpec(this.user.membershipId);
                            this.setACEmail();
                            this.checkMembership = {
                                message: "<i class=\"fa fa-check-circle\" aria-hidden=\"true\"></i> Great to see you again!",
                                class: 'alert alert-success m-t-10 m-b-10'
                            };
                            setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.emailInputRef.close();
                                            if (!(this.membership.basePlan !== MembershipName.premiumPlus && !this.lifetime)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.checkoutModal()];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }, 2000);
                        }
                        else {
                            this.checkMembership = {
                                message: "This email is not associated with a DFC account.<br/>Please try again or create a new account.",
                                class: 'alert alert-danger m-t-10 m-b-10',
                                CTA: 'Create new account'
                            };
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UpgradeComponent.prototype.setACEmail = function () {
        vgo('setEmail', this.api.userEmail);
        vgo('process');
        console.log("Active campaigns tracked", this.api.userEmail);
    };
    UpgradeComponent.prototype.setTimeRemaining = function (endtime) {
        var d = new Date();
        var t = Date.parse(endtime.toString()) - Date.parse(d.toString());
        if (t < 0) {
            return false;
        }
        this.seconds = Math.floor((t / 1000) % 60);
        this.minutes = Math.floor((t / 1000 / 60) % 60);
        this.hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        this.days = Math.floor(t / (1000 * 60 * 60 * 24));
    };
    UpgradeComponent.prototype.checkoutModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var checkout, component;
            return tslib_1.__generator(this, function (_a) {
                if (this.user && this.currentCustomer) {
                    checkout = this.modalService.open(CheckoutComponent);
                    component = checkout.componentInstance;
                    component.lifetime = this.lifetime;
                    component.coupon = this.coupon;
                    component.user = this.user;
                    component.partnerId = this.partnerId;
                    component.rfsn = this.rfsn;
                    component.onboardingRedirect = true;
                    component.trialCode = this.code;
                    component.location = 'Upgrade Page';
                    component.utm_source = this.utm_source;
                    component.utm_medium = this.utm_medium;
                    component.trafficSource = this.trafficSource;
                }
                else {
                    this.emailInputRef = this.modalService.open(this.emailInput, {
                        centered: true
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    UpgradeComponent.prototype.setUserMembership = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.user.partnerId === 'lifetimepremiumplus' || !this.lifetime && this.user.membershipId > Memberships.premium) {
                    this.cantUpgrade = true;
                }
                if (this.user.membershipId) {
                    this.currentCustomer = true;
                }
                else {
                    this.currentCustomer = false;
                }
                localStorage.setItem('favicon', this.whitelabel_config.logos.favicon);
                localStorage.setItem('title', this.whitelabel_config.name);
                return [2 /*return*/];
            });
        });
    };
    UpgradeComponent.prototype.handleKeyDown = function (event) {
        if (event.keyCode === 13) {
            if (this.captureForm.controls.email.valid) {
                this.api.userEmail = this.captureForm.controls.email.value;
                this.setACEmail();
            }
            else {
                this.emailValidationActive = true;
            }
        }
    };
    Object.defineProperty(UpgradeComponent.prototype, "trialCode", {
        get: function () {
            var code = this.route.snapshot.queryParams['code'];
            if (Object.values(TrialCodes).includes(code)) {
                return code;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return UpgradeComponent;
}());
export { UpgradeComponent };
