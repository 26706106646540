import * as tslib_1 from "tslib";
import { findWhiteLabel } from "@dollar-flight-club/shared_utilities/lib/utils/whitelabel";
import { Api } from "./api.service";
import DFC_CONFIG from "@dollar-flight-club/shared_utilities/lib/utils/dfc_whitelabel";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var WhitelabelResolver = /** @class */ (function () {
    function WhitelabelResolver(api) {
        this.api = api;
    }
    WhitelabelResolver.prototype.resolve = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, user, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = route.params;
                        if (params.partner_id) {
                            return [2 /*return*/, findWhiteLabel(params.partner_id)];
                        }
                        if (state.url === '/signup') {
                            return [2 /*return*/, DFC_CONFIG];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.getCurrentUser()];
                    case 2:
                        user = _b.sent();
                        if (user && user.partnerId) {
                            return [2 /*return*/, findWhiteLabel(user.partnerId)];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, DFC_CONFIG];
                }
            });
        });
    };
    WhitelabelResolver.ngInjectableDef = i0.defineInjectable({ factory: function WhitelabelResolver_Factory() { return new WhitelabelResolver(i0.inject(i1.Api)); }, token: WhitelabelResolver, providedIn: "root" });
    return WhitelabelResolver;
}());
export { WhitelabelResolver };
