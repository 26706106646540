import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {findWhiteLabel, IWhiteLabel} from "@dollar-flight-club/shared_utilities/lib/utils/whitelabel";
import {Api} from "./api.service";
import DFC_CONFIG from "@dollar-flight-club/shared_utilities/lib/utils/dfc_whitelabel";

@Injectable({
  providedIn: 'root'
})
export class WhitelabelResolver implements Resolve<IWhiteLabel> {

  constructor(private api: Api) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IWhiteLabel> {

    const params = route.params;
    if (params.partner_id) {
      return findWhiteLabel(params.partner_id);
    }

    if (state.url === '/signup') {
      return DFC_CONFIG;
    }

    try {
      const user = await this.api.getCurrentUser();
      if (user && user.partnerId) {
        return findWhiteLabel(user.partnerId);
      }
    } catch {}

    return DFC_CONFIG;
  }


}
