import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import * as i0 from "@angular/core";
var CanDeactivateGuard = /** @class */ (function () {
    function CanDeactivateGuard() {
    }
    CanDeactivateGuard.prototype.canDeactivate = function (component, next, state) {
        if (component.canDeactivate()) {
            return confirm('Are you sure you want to leave this page? If you quit, you will lose your changes.');
        }
        else {
            return true;
        }
    };
    CanDeactivateGuard.ngInjectableDef = i0.defineInjectable({ factory: function CanDeactivateGuard_Factory() { return new CanDeactivateGuard(); }, token: CanDeactivateGuard, providedIn: "root" });
    return CanDeactivateGuard;
}());
export { CanDeactivateGuard };
