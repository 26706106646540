/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-navigation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./new-navigation.component";
import * as i5 from "../../services/api.service";
var styles_NewNavigationComponent = [i0.styles];
var RenderType_NewNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewNavigationComponent, data: {} });
export { RenderType_NewNavigationComponent as RenderType_NewNavigationComponent };
function View_NewNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "a", [["class", "logo-area"], ["href", "https://dollarflightclub.com/"]], null, null, null, null, null))], null, null); }
function View_NewNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "logo-area"], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null)], function (_ck, _v) { var currVal_2 = "/dashboard"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NewNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "space-filler"]], null, null, null, null, null))], null, null); }
function View_NewNavigationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "btn btn-mobile px-2 py-1 font14 neg-mt-10"], ["routerLink", "/signup"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Sign Up "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn btn-mobile px-2 py-1 font14 neg-mt-10"; var currVal_3 = ("primary-btn-" + ((_co.whitelabel_config == null) ? null : _co.whitelabel_config.css)); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "/signup"; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_NewNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "nav", [["class", "new mg-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "logo-container"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "img-responsive partner-logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewNavigationComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewNavigationComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewNavigationComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewNavigationComponent_4)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "logo-container"; var currVal_1 = (((_co.whitelabel_config == null) ? null : _co.whitelabel_config.css) + "-nav-logo"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = !_co.api.user; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.api.user; _ck(_v, 7, 0, currVal_4); var currVal_5 = !_co.api.user; _ck(_v, 9, 0, currVal_5); var currVal_6 = !_co.api.user; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getNavLogo(); _ck(_v, 3, 0, currVal_2); }); }
export function View_NewNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "new-navigation", [], null, null, null, View_NewNavigationComponent_0, RenderType_NewNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i4.NewNavigationComponent, [i5.Api, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewNavigationComponentNgFactory = i1.ɵccf("new-navigation", i4.NewNavigationComponent, View_NewNavigationComponent_Host_0, {}, {}, []);
export { NewNavigationComponentNgFactory as NewNavigationComponentNgFactory };
