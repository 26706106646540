import { AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { ControlValueAccessor } from '@angular/forms';
var noop = function () {
};
var ɵ0 = noop;
var AutocompleterComponent = /** @class */ (function () {
    function AutocompleterComponent(http) {
        this.http = http;
        this.focus = false;
        this.searching = false;
        this.options = [];
        this.empty = false;
        this.lastQuery = "";
        this.disabled = false;
        this.dropdownLeft = "-2000px";
        this.dropdownTop = "-2000px";
        this.dropdownWidth = "200px";
        this.valueSelectedEvent = new EventEmitter();
        this.placeholder = "Select...";
        this.loadingText = "Loading";
        this.noResultsText = "Nothing found";
        this.searchPlaceholder = "Search";
        this.mode = "select";
        this.results = 10;
        this.onTouchedCallback = noop;
        this.onChangeCallback = noop;
    }
    Object.defineProperty(AutocompleterComponent.prototype, "currentValue", {
        set: function (value) {
            this.value = value;
        },
        enumerable: true,
        configurable: true
    });
    AutocompleterComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        document.addEventListener("click", function () {
            setTimeout(function () {
                if (_this.focus && _this.searchBox && _this.searchBox.nativeElement && document.activeElement != _this.searchBox.nativeElement)
                    _this.focus = false;
            }, 20);
        });
        document.addEventListener("scroll", function () {
            if (_this.focus && _this.textContainer && _this.textContainer.nativeElement) {
                var rect = _this.textContainer.nativeElement.getBoundingClientRect();
                _this.dropdownLeft = rect.left.toString() + 'px';
                _this.dropdownTop = (rect.top + rect.height).toString() + 'px';
                _this.dropdownWidth = _this.textContainer.nativeElement.offsetWidth + 'px';
            }
        });
    };
    AutocompleterComponent.prototype.search = function () {
        var _this = this;
        if (this.url == "")
            this.empty = true;
        else if (this.searchBox.nativeElement.value == "") {
            this.searching = false;
            this.empty = false;
            this.options = [];
        }
        else if (this.searchBox.nativeElement.value != this.lastQuery) {
            this.searching = true;
            this.empty = false;
            setTimeout(function () {
                _this.lastQuery = _this.searchBox.nativeElement.value;
                _this.http.post(_this.url, { query: _this.searchBox.nativeElement.value, results: _this.results, data: _this.query }, { withCredentials: true }).toPromise().then(function (res) {
                    var responseInfo = res.json();
                    if (!responseInfo || responseInfo.data.length == 0) {
                        _this.options = [];
                        _this.searching = false;
                        _this.empty = true;
                    }
                    else {
                        _this.searching = false;
                        _this.empty = false;
                        _this.options = responseInfo.data;
                    }
                }).catch(function (error) {
                    _this.options = [];
                    _this.searching = false;
                    _this.empty = true;
                });
            }, 500);
        }
    };
    AutocompleterComponent.prototype.focusOn = function () {
        var _this = this;
        if (this.disabled) {
            this.searching = false;
            this.empty = false;
            this.options = [];
            this.focus = false;
            return;
        }
        if (!this.focus) {
            this.searching = false;
            this.empty = false;
            this.options = [];
            var rect = this.textContainer.nativeElement.getBoundingClientRect();
            this.dropdownLeft = rect.left.toString() + 'px';
            this.dropdownTop = (rect.top + rect.height).toString() + 'px';
            this.dropdownWidth = this.textContainer.nativeElement.offsetWidth + 'px';
        }
        this.focus = !this.focus;
        setTimeout(function () {
            if (_this.searchBox.nativeElement) {
                _this.searchBox.nativeElement.focus();
                _this.searchBox.nativeElement.value = "";
            }
        }, 10);
    };
    AutocompleterComponent.prototype.select = function (value) {
        this.value = value;
    };
    AutocompleterComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    Object.defineProperty(AutocompleterComponent.prototype, "value", {
        get: function () {
            return this.selectedValue;
        },
        set: function (v) {
            if (typeof v != "undefined" && v != null && v)
                this.selectedValue = v;
            else
                this.selectedValue = null;
            this.onChangeCallback(this.selectedValue);
            this.valueSelectedEvent.emit(this.selectedValue);
        },
        enumerable: true,
        configurable: true
    });
    ;
    AutocompleterComponent.prototype.onBlur = function () {
        this.onTouchedCallback();
    };
    AutocompleterComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    AutocompleterComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    AutocompleterComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    return AutocompleterComponent;
}());
export { AutocompleterComponent };
export { ɵ0 };
