/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./pagination.component";
var styles_PaginationComponent = [i0.styles];
var RenderType_PaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "pull-left pagination-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Showing from ", " to ", " of ", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.start; var currVal_1 = _co.end; var currVal_2 = _co.results; var currVal_3 = _co.content; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_PaginationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "page-item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disabled": 0, "active": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "page-link"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage((_v.context.$implicit - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "page-item"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit == 0), (_co.current == (_v.context.$implicit - 1))); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit > 0) ? _v.context.$implicit : "..."); _ck(_v, 4, 0, currVal_2); }); }
function View_PaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "nav", [["class", "d-flex"], ["style", "justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "ul", [["class", "pagination pagination-sm pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "li", [["class", "page-item"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "disabled": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "page-link"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage((_co.current - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00AB"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_3)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "li", [["class", "page-item"]], null, null, null, null, null)), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "disabled": 0 }), (_l()(), i1.ɵeld(14, 0, null, null, 1, "a", [["class", "page-link"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage((_co.current + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00BB"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.results > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = "page-item"; var currVal_2 = _ck(_v, 6, 0, (_co.page == 0)); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.pagesList; _ck(_v, 10, 0, currVal_3); var currVal_4 = "page-item"; var currVal_5 = _ck(_v, 13, 0, (_co.page == (_co.pagesList[(_co.pagesList.length - 1)] - 1))); _ck(_v, 12, 0, currVal_4, currVal_5); }, null); }
export function View_PaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pagesList.length > 1); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i1.ɵccf("pagination", i3.PaginationComponent, View_PaginationComponent_Host_0, { content: "content", size: "size", results: "results", page: "page", pages: "pages" }, { navigationEvent: "navigation" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
