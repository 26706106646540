import { Component, Input, OnInit } from '@angular/core';
import * as _ from "lodash";

@Component({
  selector: 'app-countdown-banner',
  templateUrl: './countdown-banner.component.html',
  styleUrls: ['./countdown-banner.component.css']
})
export class CountdownBannerComponent implements OnInit {
  @Input() banner_txt: string;
  @Input() displaySalesBanner: boolean = false;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;

  constructor() { }

  ngOnInit() {
    const deadline = new Date(); //today
    deadline.setHours(0, 0, 0, 0);
    deadline.setDate(deadline.getDate() + 1);
    const timeInterval = setInterval(() => this.setTimeRemaining(deadline), 1000);
  }

  setTimeRemaining(endTime: Date) {
    var d = new Date();
    var t = Date.parse(endTime.toString()) - Date.parse(d.toString());
    if (t < 0) {
      return false;
    }
    this.seconds = Math.floor((t / 1000) % 60);
    this.minutes = Math.floor((t / 1000 / 60) % 60);
    this.hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    this.days = Math.floor(t / (1000 * 60 * 60 * 24));
  }


}
