<vertical-nav></vertical-nav>
<div class="content-area">
  <menu-bar></menu-bar>
  <div class="content-wrapper mb-5">
    <div class="row pl-3 pl-md-3 pl-lg-1">
      <div class="col-12 px-0 text-center">
        <img src="https://dfcapp.s3.amazonaws.com/DFC+Perks+Card+Logo.png" alt="Perks Logo" class="perks-logo">
      </div>
      <div class="container-fluid full-width-container">
      <div class="upgrade-header-container" *ngIf="!canClickPerk()">
      <h6>Get instant access to over $1,000 in discounts from 30+ of the worlds best travel brands and services when you upgrade to Premium Plus</h6>
        <button  class="btn primary-btn-dfc" (click)="upgrade()">Try it for just $1 today</button>
      </div>
        <div class="row">
          <div *ngFor="let perk of perkList" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
            <div class="card h-100"
                 (click)="showPerk(perk.name)">
              <img class="card-img-top" [src]="perk.image" [alt]="perk.name">
              <div class="card-body-txt">
                <h5 class="perk-name">{{ perk.name }}</h5>
                <p class="perk-discount">
                  {{ perk.name === 'TSA PreCheck'
                  ? hasValidPerksSubscription
                    ? perk.discountCopyShort
                    : 'Get $5 back when you sign up for TSA PreCheck through Clear'
                  : perk.discountCopyShort }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<static-footer></static-footer>


