/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./autocompleter.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./autocompleter.component";
import * as i5 from "@angular/http";
var styles_AutocompleterComponent = [i0.styles];
var RenderType_AutocompleterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutocompleterComponent, data: {} });
export { RenderType_AutocompleterComponent as RenderType_AutocompleterComponent };
function View_AutocompleterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "autocompleter-loading-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "..."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingText; _ck(_v, 1, 0, currVal_0); }); }
function View_AutocompleterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "autocompleter-loading-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "..."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noResultsText; _ck(_v, 1, 0, currVal_0); }); }
function View_AutocompleterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; _ck(_v, 1, 0, currVal_0); }); }
function View_AutocompleterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ul", [["class", "options-list"]], [[4, "left", null], [4, "top", null], [4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [["class", "input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["searchBox", 1]], null, 0, "input", [["class", "form-control"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.search($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutocompleterComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutocompleterComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutocompleterComponent_4)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.searching; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.empty; _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.options; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropdownLeft; var currVal_1 = _co.dropdownTop; var currVal_2 = _co.dropdownWidth; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.searchPlaceholder, "..."); _ck(_v, 2, 0, currVal_3); }); }
export function View_AutocompleterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { searchBox: 0 }), i1.ɵqud(402653184, 2, { textContainer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "autocompleter-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["textContainer", 1]], null, 3, "div", [["class", "text-container form-control"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.focusOn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "down": 0, "up": 1, "disabled": 2 }), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutocompleterComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "text-container form-control"; var currVal_1 = _ck(_v, 5, 0, !_co.focus, _co.focus, _co.disabled); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _co.focus; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.selectedValue == null) ? _co.placeholder : _co.selectedValue.text); _ck(_v, 6, 0, currVal_2); }); }
export function View_AutocompleterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "autocompleter", [], null, null, null, View_AutocompleterComponent_0, RenderType_AutocompleterComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.AutocompleterComponent]), i1.ɵdid(2, 4243456, null, 0, i4.AutocompleterComponent, [i5.Http], null, null)], null, null); }
var AutocompleterComponentNgFactory = i1.ɵccf("autocompleter", i4.AutocompleterComponent, View_AutocompleterComponent_Host_0, { query: "query", placeholder: "placeholder", loadingText: "loadingText", noResultsText: "noResultsText", searchPlaceholder: "searchPlaceholder", mode: "mode", url: "url", results: "results", currentValue: "selectedValue" }, { valueSelectedEvent: "selected" }, []);
export { AutocompleterComponentNgFactory as AutocompleterComponentNgFactory };
