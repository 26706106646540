import { Injectable } from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import {HasUnsavedData} from "../models/has-unsaved-data";

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<HasUnsavedData> {

  canDeactivate(
    component: HasUnsavedData,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (component.canDeactivate()) {
      return confirm('Are you sure you want to leave this page? If you quit, you will lose your changes.');
    } else {
      return true;
    }
  }

}
