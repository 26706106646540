<nav *ngIf="coupon && discount && membership?.basePlan !== membershipNames.premiumPlus && !lifetime" class="navbar container-fluid banner-color py-2">
  <div class="row mx-auto">
    <div class="col-12 text-center">
        <span class="text-white fs-14 font-weight-bold">
          Get Premium
          <span *ngIf="membership?.basePlan === membershipNames.premium || premiumPlusUpgrade">Plus</span>
          for {{discount}} off today! 🔥
        <span  class="hours bord counter-numbers">{{this.hours}}</span>
        <span class="pr-3 pl-2">Hours</span>
        <span class="minutes bord counter-numbers">{{this.minutes}}</span>
        <span class="pr-3 pl-2">Mins</span>
        <span class="seconds bord counter-numbers">{{this.seconds}}</span>
        <span class="pr-3 pl-2">Secs</span>
    </span>
    </div>
  </div>
</nav>


<div class="container-contact100"
     style="background-image: url('https://dfcmedia.s3.amazonaws.com/email-assets/bg-backslpash.jpg'); background-repeat: no-repeat;background-size: cover;background-position: top;">
  <div class="container text-center mt-5">
    <img class="dfc-white-logo mt-4" src="https://dfcmedia.s3.amazonaws.com/email-assets/dfc-plane-white.png">
  </div>

  <div class="container text-center mt-5"
       *ngIf="membership?.basePlan !== membershipNames?.premiumPlus && !cantUpgrade || lifetime && !cantUpgrade">
    <h1 class="text-white header-font mt-4 px-3">Get our best deals and features with Premium<span
      *ngIf="premiumPlusUpgrade"> Plus</span><span *ngIf="lifetime"> Lifetime</span>.
    </h1>
    <p class="text-white text-center top-subtitle">If you book just 1 flight we share with you, you can get up to a 10x
      return on your investment.</p>
    <div>
      <div class="btnbar" *ngIf="membership?.basePlan !== membershipNames.premiumPlus && !cantUpgrade || lifetime && !cantUpgrade">
        <button *ngIf="api.coupon && lifetime" [ngClass]="'primary-btn-' + this.whitelabel_config?.css" class="btn-oval btn-lg" (click)="checkoutModal()">
        <span *ngIf="!lifetime">
        Get Premium
        <span *ngIf="premiumPlusUpgrade">Plus</span>
        </span>
          <span *ngIf="lifetime">Get Lifetime </span>Today
        </button>
        <button *ngIf="api.coupon && !lifetime" [ngClass]="'primary-btn-' + this.whitelabel_config?.css" class="btn-oval btn-lg" (click)="checkoutModal()">
          Get {{api.discount}}% off Premium<span *ngIf="membership?.basePlan === membershipNames.premium || premiumPlusUpgrade"> Plus</span> Today
        </button>
        <button *ngIf="!api.coupon" [ngClass]="'primary-btn-' + this.whitelabel_config?.css" class="btn-oval btn-lg" (click)="checkoutModal()">
        <span *ngIf="!lifetime">
        Get Premium
        <span *ngIf="premiumPlusUpgrade && !lifetime">Plus </span>
        </span>
          <span *ngIf="lifetime">Get Lifetime </span>Today
        </button>
      </div>
    <br>
    <img class="arrow-down text-center" src="https://dfcmedia.s3.amazonaws.com/email-assets/arrow-down.png">
  </div>
  </div>
  <div class="container m-t-30 text-center" *ngIf="cantUpgrade">
    <h1 class="text-white header-font mt-4 px-5">You already have a Premium Plus <span *ngIf="lifetime && user.partnerId === 'lifetimepremiumplus'">Lifetime</span> membership</h1>
    <p class="text-white text-center top-subtitle">Please login to checkout the latest deals</p>
    <div class="btnbar text-center" style="display:contents;">
      <a href="https://app.dollarflightclub.com/dashboard/" class="btn-oval btn-lg btn-primary">
        Login to Dashboard
      </a>
    </div>
    <br>
    <img class="arrow-down text-center" src="https://dfcmedia.s3.amazonaws.com/email-assets/arrow-down.png">
  </div>
</div>

<div class="bg-white">
  <div class="container-fluid cards-container justify-content-center">
    <app-marketing-row [mainTitle]="rowContent.row1.mainTitle"
                       [highlightTitle]="rowContent.row1.highlightTitle"
                       [highlightClass]="highlightClass"
                       [paragraphs]="rowContent.row1.paragraphs"
                       [paragraphClass]="paragraphClass"
                       [imgSrc]="rowContent.row1.imgSrc"
    >
    </app-marketing-row>
  </div>

  <div class="grey-bg">
    <div class="container-fluid cards-container">
      <h1 class="go-premium-txt text-center">
        Go <span class="font-italic">Premium</span> <span
        *ngIf="membership?.basePlan === membershipNames.premium || premiumPlusUpgrade"> Plus</span> to get amazing deals
        like these...
      </h1>
      <div class="row mt-5 justify-content-start mx-auto">
        <div class="col-sm-6 col-lg pl-sm-0 pr-sm-4 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-greece.png">
            <div class="sm-card-text">
              <p>Greece</p>
              <p>$980</p>
              <p>$298 Roundtrip</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-peru.png">
            <div class="sm-card-text">
              <p>Peru</p>
              <p>$650</p>
              <p>$250 Roundtrip</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg pl-sm-0 pr-sm-4 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-switzerland.png">
            <div class="sm-card-text">
              <p>Switzerland</p>
              <p>$1020</p>
              <p>$325 Roundtrip</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-thailand.png">
            <div class="sm-card-text">
              <p>Thailand</p>
              <p>$950</p>
              <p>$375 Roundtrip</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="container-fluid cards-container justify-content-center">
      <app-marketing-row [mainTitle]="rowContent.row3.mainTitle"
                         [highlightTitle]="rowContent.row3.highlightTitle"
                         [highlightClass]="highlightClass"
                         [paragraphs]="rowContent.row3.paragraphs"
                         [paragraphClass]="paragraphClass"
                         [imgSrc]="rowContent.row3.imgSrc"
                         [flippedRow]="true"
      >
      </app-marketing-row>
    </div>
  </div>

  <div class="grey-bg position-relative overflow-hidden">
    <div class="container-fluid cards-container pt-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center">
            You're in Good Company...
          </h1>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-5">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-1 float-left">
              <p class="font-weight-bold">Michael S.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "I got a flight to Morocco and to Porto, Portugal for $480 round trip with luggage! Unreal. This flight would usually be $950+ depending on the time of the year. Great service!"
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-5">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-2 float-left">
              <p class="font-weight-bold">Lauren N.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "I booked flights to Iceland for $350 round trip and cruised the country searching for all of its beauties. Easily saved over $500. Such a great membership, paid for itself with my first flight.”
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-3 float-left">
              <p class="font-weight-bold">Tahsan M.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "How could I not book this Business class flight to Costa Rica for $542 roundtrip????? Saved me over $1,500 on airfare! Absolutely an amazing deal, I did it, and I'm super excited. your premium newsletter is f$%#@g awesome"
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-4 float-left">
              <p class="font-weight-bold">Susan P.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                "Dollar Flight Club, I can't thank you enough! I have been watching fares to Sydney like a hawk for nearly 25 years and this is the absolute lowest I've ever seen from my part of the country. Your service is the real deal! Keep doing what you do!"
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-5">
    <div class="container-fluid cards-container justify-content-center">
      <app-marketing-row [mainTitle]="rowContent.row4.mainTitle"
                         [highlightTitle]="rowContent.row4.highlightTitle"
                         [highlightClass]="highlightClass"
                         [paragraphs]="rowContent.row4.paragraphs"
                         [paragraphClass]="paragraphClass"
                         [imgSrc]="rowContent.row4.imgSrc"
      >
      </app-marketing-row>
    </div>
    </div>
  <div class=" mb-5 grey-bg">
<div >
    <div class="container-fluid cards-container justify-content-center">
      <app-marketing-row [mainTitle]="rowContent.row5.mainTitle"
                         [highlightTitle]="rowContent.row5.highlightTitle"
                         [highlightClass]="highlightClass"
                         [paragraphs]="rowContent.row5.paragraphs"
                         [paragraphClass]="paragraphClass"
                         [imgSrc]="rowContent.row5.imgSrc"
                         [flippedRow]="true"
      >
      </app-marketing-row>
    </div>
  </div>
    </div>
  <div>

    <div class="container-fluid cards-container justify-content-center">
      <app-marketing-row [mainTitle]="rowContent.row6.mainTitle"
                         [highlightTitle]="rowContent.row6.highlightTitle"
                         [highlightClass]="highlightClass"
                         [paragraphs]="rowContent.row6.paragraphs"
                         [paragraphClass]="paragraphClass"
                         [imgSrc]="rowContent.row6.imgSrc"
      >
      </app-marketing-row>
    </div>
  </div>

  <ng-template #email let-modal>
    <div class="modal-body">
      <form class="capture-form" [formGroup]="captureForm" autocomplete="off" novalidate>
        <div class="display:flex;justify-content: center;align-items:center;flex-direction:column;">
          <p style="text-align: center; font-weight: bold;">Please enter your DFC account email:</p>
          <div [className]="checkMembership?.class" [innerHTML]="checkMembership?.message"></div>
          <input type="email" class="form-control form-control-lg" placeholder="Email Address"
                 formControlName="email"
                 [ngClass]="{'input-error':emailValidationActive&&!captureForm.controls.email.disabled&&!captureForm.controls.email.valid}"/>
          <div class="input-error-message"
               *ngIf="emailValidationActive&&!captureForm.controls.email.disabled&&!captureForm.controls.email.valid">*
            {{this.api.strings.ui_messages.email_not_valid}}
          </div>
          <div class="text-center">
            <a *ngIf="checkMembership?.CTA" class="create-account" href="https://signup.dollarflightclub.com/">
              {{checkMembership.CTA}}
            </a>
          </div>
          <button style="margin-top:1em; width: 100%;" type="button"
                  [disabled]="!captureForm.controls.email.valid||!captureForm.controls.email.value.length"
                  class="btn btn-primary btn-lg btn-block" (click)="closeEmailModal()">
            Done
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
