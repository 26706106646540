import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Api, MessageType } from '../../services/api.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Membership, MembershipName, Memberships } from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import { findWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CheckoutComponent } from '../checkout/checkout.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(api, formBuilder, route, modalService) {
        var _this = this;
        this.api = api;
        this.route = route;
        this.modalService = modalService;
        this.editPasssword = false;
        this.profileFormBusy = false;
        this.validationActive = false;
        this.loadingDestinations = true;
        this.destinations = [];
        this.loadingAirlines = true;
        this.airlines = [];
        this.loadingDepartures = true;
        this.departures = [];
        this.activeSection = 'departure';
        this.updateBilling = false;
        this.couponId = 10000;
        this.displayPhone = false;
        this.generic_error = 'There has been an error, please try again. If the problem persists refresh the page.';
        this.displaySalesBanner = false;
        this.memberships = Memberships;
        this.emailNotificationControl = new FormControl();
        this.route.params.subscribe(function (params) {
            if (params['section']) {
                _this.activeSection = params['section'];
                window.scroll(0, 0);
            }
        });
        this.profileForm = formBuilder.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9A-Z]+[a-zA-Z0-9\.\\-\\_]*\@[a-zA-Z0-9\.\\-\\_]+\.[a-z0-9A-Z]{2,}$')]],
            phone: [''],
            password: ['', [Validators.required, Validators.minLength(6)]],
            new_password: ['', [Validators.required, Validators.minLength(6), this.matchingPasswords('password')]],
        });
        this.favoritesSelectionForm = formBuilder.group({
            destination: [''],
            departure: [''],
            airline: ['']
        });
        this.emailNotificationControl.valueChanges.pipe(switchMap(function (enabled) {
            return _this.api.toggleNotifications(enabled);
        })).subscribe();
        this.api.addInitCallback(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, destinations, error_1, departures, error_2, departures, error_3;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.membershipInfo()];
                    case 1:
                        _a.subscription = _b.sent();
                        if (this.api.user) {
                            this.profileForm.controls.name.setValue(this.api.user.name);
                            this.profileForm.controls.email.setValue(this.api.user.email);
                            this.profileForm.controls.phone.setValue(this.api.user.phone);
                            this.profileForm.controls.password.setValue(this.api.user.email);
                            this.profileForm.controls.new_password.setValue(this.api.user.email);
                            this.emailNotificationControl.patchValue(this.api.user.emailNotifications, { emitEvent: false });
                        }
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.api.getFavoriteDestinations()];
                    case 3:
                        destinations = _b.sent();
                        this.loadingDestinations = false;
                        this.destinations = destinations;
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _b.sent();
                        this.loadingDestinations = false;
                        this.destinations = [];
                        return [3 /*break*/, 5];
                    case 5:
                        _b.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.api.getFavoriteDepartures()];
                    case 6:
                        departures = _b.sent();
                        this.loadingDepartures = false;
                        this.departures = departures;
                        return [3 /*break*/, 8];
                    case 7:
                        error_2 = _b.sent();
                        this.loadingDepartures = false;
                        this.departures = [];
                        return [3 /*break*/, 8];
                    case 8:
                        _b.trys.push([8, 10, , 11]);
                        return [4 /*yield*/, this.api.getFavoriteAirlines()];
                    case 9:
                        departures = _b.sent();
                        this.loadingAirlines = false;
                        this.airlines = departures;
                        return [3 /*break*/, 11];
                    case 10:
                        error_3 = _b.sent();
                        this.loadingAirlines = false;
                        this.airlines = [];
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        }); });
        this.api.user$.subscribe(function (u) {
            _this.user = u;
            if (_this.user) {
                _this.myMembership = Membership.getMembershipSpec(_this.user.membershipId);
                _this.whitelabel_config = findWhiteLabel(_this.user.partnerId);
                _this.displaySalesBanner = _this.whitelabel_config.members_dashboard.banner
                    && _this.api.user.membershipId === _this.memberships.free;
                if (_this.user.membershipId > 1) {
                    _this.displayPhone = true;
                }
            }
        });
    }
    ProfileComponent.prototype.destinationSelected = function (destination) {
        if (destination)
            this.destination = destination;
    };
    ProfileComponent.prototype.airlineSelected = function (airline) {
        if (airline)
            this.airline = airline;
    };
    ProfileComponent.prototype.departureSelected = function (departure) {
        if (departure)
            this.departure = departure;
    };
    ProfileComponent.prototype.removeDestination = function (id) {
        this.destinations.splice(this.destinations.indexOf(this.destinations.filter(function (item) { return item.id === id; })[0]), 1);
        this.api.removeFavoriteDestination(id).then(function (success) { }, function (error) { }).catch(function (error) { });
    };
    ProfileComponent.prototype.removeAirline = function (id) {
        this.airlines.splice(this.airlines.indexOf(this.airlines.filter(function (item) { return item.id === id; })[0]), 1);
        this.api.removeFavoriteAirline(id).then(function (success) { }, function (error) { }).catch(function (error) { });
    };
    ProfileComponent.prototype.removeDeparture = function (id) {
        this.departures.splice(this.departures.indexOf(this.departures.filter(function (item) { return item.id === id; })[0]), 1);
        this.api.removeFavoriteDeparture(id).then(function (success) { }, function (error) { }).catch(function (error) { });
    };
    ProfileComponent.prototype.addDestination = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var destination, _a, error_4;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.destination) return [3 /*break*/, 7];
                        this.loadingDestinations = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.api.addFavoriteDestination(this.destination.value)];
                    case 2:
                        destination = _b.sent();
                        this.loadingDestinations = false;
                        if (!destination) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.api.getFavoriteDestinations()];
                    case 3:
                        _a.destinations = _b.sent();
                        this.destination = null;
                        this.favoritesSelectionForm.controls.destination.reset();
                        return [3 /*break*/, 5];
                    case 4:
                        this.api.showMessage(MessageType.error, this.generic_error);
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_4 = _b.sent();
                        this.loadingDestinations = false;
                        this.api.showMessage(MessageType.error, this.generic_error);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.addAirline = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var airline, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.airline) return [3 /*break*/, 4];
                        this.loadingAirlines = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.addFavoriteAirline(this.airline.value)];
                    case 2:
                        airline = _a.sent();
                        this.loadingAirlines = false;
                        if (airline) {
                            this.airlines.push(airline);
                            this.airline = null;
                            this.favoritesSelectionForm.controls.airline.reset();
                        }
                        else
                            this.api.showMessage(MessageType.error, this.generic_error);
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _a.sent();
                        this.loadingAirlines = false;
                        this.api.showMessage(MessageType.error, this.generic_error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.addDeparture = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var departure, _a, error_6;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.departure) return [3 /*break*/, 7];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        this.loadingDepartures = true;
                        return [4 /*yield*/, this.api.addFavoriteDeparture(this.departure.airport)];
                    case 2:
                        departure = _b.sent();
                        this.loadingDepartures = false;
                        if (!departure) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.api.getFavoriteDepartures()];
                    case 3:
                        _a.departures = _b.sent();
                        this.departure = null;
                        this.favoritesSelectionForm.controls.departure.reset();
                        return [3 /*break*/, 5];
                    case 4:
                        this.api.showMessage(MessageType.error, this.generic_error);
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_6 = _b.sent();
                        this.loadingDepartures = false;
                        this.api.showMessage(MessageType.error, this.generic_error);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.changePassword = function () {
        this.editPasssword = true;
        this.profileForm.controls.password.enable();
        this.profileForm.controls.password.setValue('');
        this.profileForm.controls.new_password.setValue('');
    };
    ProfileComponent.prototype.cancelPassword = function () {
        this.editPasssword = false;
        this.profileForm.controls.password.setValue(this.api.user.email);
        this.profileForm.controls.new_password.setValue(this.api.user.email);
        this.profileForm.controls.password.disable();
    };
    ProfileComponent.prototype.getPhone = function () {
        var phone = _.get(this.api.user, 'phone');
        if (phone) {
            var area = phone.slice(0, 3);
            var mid = phone.slice(3, 6);
            var end = phone.slice(6, 10);
            return "(" + area + ") " + mid + "-" + end;
        }
        return '';
    };
    ProfileComponent.prototype.matchingPasswords = function (passwordKey) {
        var _this = this;
        return function (group) {
            if (!group || !group.parent || !_this.editPasssword)
                return null;
            var passwordInput = group.parent.get(passwordKey);
            if (passwordInput.value !== group.value) {
                return { notEquivalent: true };
            }
        };
    };
    ProfileComponent.prototype.update = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var success, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.stopPropagation();
                        this.validationActive = true;
                        if (!this.profileForm.valid && !this.profileForm.controls.phone.valid)
                            return [2 /*return*/];
                        this.profileFormBusy = true;
                        this.profileForm.disable();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.updateAccountInfo({ name: this.profileForm.controls.name.value, email: this.profileForm.controls.email.value, phone: this.profileForm.controls.phone.value, password: this.profileForm.controls.password.value })];
                    case 2:
                        success = _a.sent();
                        this.profileFormBusy = false;
                        this.profileForm.enable();
                        if (success) {
                            this.api.showMessage(MessageType.success, this.api.strings.portal.account_information_updated);
                            this.activeSubsection = '';
                        }
                        else
                            this.api.showMessage(MessageType.error, this.api.strings.portal.account_update_error);
                        return [3 /*break*/, 4];
                    case 3:
                        error_7 = _a.sent();
                        this.profileForm.enable();
                        this.profileFormBusy = false;
                        this.api.showMessage(MessageType.error, this.api.strings.portal.account_update_error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProfileComponent.prototype.ngOnInit = function () {
        window.scrollTo(0, 0);
    };
    ProfileComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params['openModal']) {
                _this.openModal(true);
            }
        });
    };
    ProfileComponent.prototype.closeAccountSection = function (event, section) {
        var _this = this;
        event.stopPropagation();
        setTimeout(function () {
            _this.activeSubsection = '';
            if (section !== 'password')
                _this.profileForm.controls[section].setValue(_this.api.user[section]);
            else {
                _this.profileForm.controls.password.setValue(_this.api.user.email);
                _this.profileForm.controls.new_password.setValue(_this.api.user.email);
            }
        }, 100);
    };
    ProfileComponent.prototype.stopEvent = function (event) {
        event.stopPropagation();
    };
    ProfileComponent.prototype.region = function (event) {
        if (event.target.checked)
            this.api.updateRegion(event.target.value).then(function (success) { }, function (error) { }).catch(function (error) { });
    };
    ProfileComponent.prototype.openModal = function (updateBilling) {
        if (updateBilling === void 0) { updateBilling = false; }
        this.updateBilling = updateBilling;
        var checkout = this.modalService.open(CheckoutComponent);
        var component = checkout.componentInstance;
        component.user = this.user;
        component.onboardingRedirect = false;
        component.updateBilling = this.updateBilling;
        component.location = 'Members Dashboard - Profile';
    };
    ProfileComponent.prototype.getDate = function (date) {
        return moment.unix(date).format('MM/DD/YYYY');
    };
    ProfileComponent.prototype.doesIncludeLifetime = function (partnerId) {
        return partnerId.includes('lifetime');
    };
    ProfileComponent.prototype.getMembershipLabel = function () {
        if (this.api.user && this.subscription) {
            var membership = Membership.getMembershipSpec(this.api.user.membershipId);
            var membershipName = void 0;
            if (membership.basePlan === MembershipName.premiumPlus) {
                membershipName = 'Premium Plus';
            }
            else if (membership.basePlan === MembershipName.premium) {
                membershipName = 'Premium';
            }
            else {
                membershipName = 'Free';
            }
            if (this.subscription.trialActive) {
                this.hideDiscount = true;
                this.hidePause = true;
            }
            return this.subscription.trialActive ? membershipName + " Trial" : membershipName;
        }
    };
    return ProfileComponent;
}());
export { ProfileComponent };
