<div class="overflow"></div>
<vertical-nav></vertical-nav>
<div class="content-area">
    <menu-bar></menu-bar>
    <div class="content-wrapper">
      <customscripts></customscripts>
        <div class="row">
            <div class="col-12 px-0">
                <h3 class="bold mb-4 mt-3 ml-3 mt-md-0 d-inline-block">{{dealsTitle}}</h3>
            </div>
        </div>
        <latestdeals [fullSize]="true"></latestdeals>
    </div>
</div>

<router-outlet></router-outlet>

<static-footer></static-footer>
