import {Component, OnInit} from '@angular/core';
import {Api, PREMIUM_PLUS_MEMBERSHIPS} from '../../services/api.service';
import {Router} from '@angular/router';
import {Membership, MembershipName, Memberships, MembershipSpec} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {UserModel} from '../../models/Authentication';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PartnerPerksModel, ProductClass, ProductTier} from '../../models/PartnerPerksModel';
import {tap} from 'rxjs/operators';
import {CheckoutComponent} from '../checkout/checkout.component';

@Component({
  selector: 'app-perks',
  templateUrl: './perks.component.html',
  styleUrls: ['./perks.component.css']
})
export class PerksComponent implements OnInit {
  myMembership: MembershipSpec;
  membershipNames: typeof MembershipName = MembershipName;
  displaySalesBanner = false;
  memberships: typeof Memberships = Memberships;
  whitelabel_config: any;
  user: UserModel;
  perkList: PartnerPerksModel[];
  hasValidPerksSubscription: boolean = false;
  hasValidConciergeSubscription: boolean = false;

  constructor(public api: Api, private router: Router, private modalService: NgbModal) {}

  ngOnInit() {
    this.api.getAllPerks().subscribe(perks => {
        this.perkList = perks
    });
    this.api.user$.subscribe((user: UserModel) => {
      this.user = user;
      this.myMembership = Membership.getMembershipSpec(user.membershipId);
      if (user) {
        this.whitelabel_config = findWhiteLabel(user.partnerId);
      }
    });
    this.api.getUserPerkProductSubscription(ProductClass.PERKS).pipe(
      tap(subscriptions => {
        this.hasValidPerksSubscription = subscriptions.some(sub => sub.tier === ProductTier.premium);
        this.hasValidConciergeSubscription = subscriptions.some(sub => sub.tier === ProductTier.conciergeUser);

      })
    ).subscribe();
  }

  canClickPerk(): boolean {
    return this.hasValidPerksSubscription || PREMIUM_PLUS_MEMBERSHIPS.includes(this.user.membershipId) || this.hasValidConciergeSubscription;
  }

  showPerk(perkName: string) {
    if (this.canClickPerk()) {
      return this.router.navigate(['/perks', perkName], { queryParams: { hasSub: this.hasValidPerksSubscription } });
    }
    this.upgrade();
  }

  upgrade() {
    const checkout = this.modalService.open(CheckoutComponent);
    const component = checkout.componentInstance as CheckoutComponent;
    component.user = this.user;
    component.partnerId = this.user.partnerId;
    component.location = 'Dashboard - Perks';
    component.forcePremiumPlus = true;
  }
}
