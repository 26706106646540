<nav *ngIf="!checkoutSuccess" class="navbar container-fluid banner-color py-2" xmlns="http://www.w3.org/1999/html">
  <div class="row mx-auto">
    <div class="col-12 text-center">
        <span class="text-white fs-14 font-weight-bold">
                    Pay once, get Lifetime access to Perks - Only $149 (90% off) 🎉</span>
    </div>
  </div>
</nav>
<nav class="second-nav">
  <div class="logo-container float-left">
    <img class="img-responsive dfc-logo mb-0" src="https://dfcapp.s3.amazonaws.com/DFC+Perks+Card+Logo.png">
  </div>
  <div class="align-me float-right" *ngIf="!checkoutSuccess">
    <a class="view-all-btn" href="https://getperkscard.com/view-all-perks" target="_blank" rel="noopener noreferrer">
      VIEW ALL PERKS
    </a>
    <button class="join-btn nav-perk-btn" (click)="open()">
      Get 90% Off &#8594;
    </button>
  </div>
  <div class="hamburger-menu" (click)="toggleMobileMenu()">
    <div class="bar"></div>
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
  <div class="mobile-menu float-right" *ngIf="isMobileMenuOpen">
    <div class="mobile-menu-item">
      <a class="view-all-btn" href="https://getperkscard.com/view-all-perks" target="_blank" rel="noopener noreferrer">
        VIEW ALL PERKS
      </a>
    </div>
    <div class="mobile-menu-item">
      <button class="join-btn nav-perk-btn" (click)="open()">
        Get 90% Off &#8594;
      </button>
    </div>
  </div>
</nav>
<div class="container-contact100 overflow-hidden">
  <div class="contact100-more flex-row-c-m">
    <div class="container-fluid hero-cont pr-md-4 pt-md-3 pr-xl-5 pt-xl-4">
      <div class="row position-relative">
        <div *ngIf="!checkoutSuccess" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-2 order-md-1 text-center text-sm-left">
          <div class="heading-container">
            <h1 class="header-txt mt-4 mt-sm-5 pl-sm-2">
              Get $5,000+ in immediate travel savings with Perks.
            </h1>
            <h4 class="mt-4 pl-sm-3 sub-txt">Unlock next level travel benefits, for life. Get discounts from the world’s best travel brands you won’t find anywhere else. No credit required.</h4>
            <div class="position-relative">
              <button class="join-btn mt-4" (click)="open()">
                Get 90% Off &#8594;
              </button>
              <p class="limited-time">*Comes with a 3-day money-back guarantee</p>
              <img class="arrow-position-1" src="https://dfcapp.s3.amazonaws.com/Navigator+2.0+Page+Images+(9).png">
            </div>
          </div>
        </div>
        <div *ngIf="checkoutSuccess" class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-2 order-md-1 text-center text-sm-left">
          <div class="heading-container heading-after">
            <h1 class="header-txt mt-4 mt-sm-5 pl-sm-2">
              Welcome to Perks!
            </h1>
            <h4 class="mt-4 pl-sm-3 sub-txt">Check your inbox for a welcome email from us with all the details on getting access to the perks. Or, click the button below and input your password for immediate access. Enjoy the savings!</h4>
            <div class="position-relative">
              <div class="view-perks-btn all-perks-btn dashboard-password" style="width: fit-content;" (click)="copyInputMessage('DFCPerks2023!')">PASSWORD: DFCPerks2023!
                <i class="fa fa-clone" aria-hidden="true" *ngIf="!copied"></i>
                <span *ngIf="copied" style="color: green;">✓</span></div>
            <button (click)="getPerks()" class="join-btn mt-4" style="margin-top: 0 !important; min-width: 268.64px;">GO TO DASHBOARD &#8594;</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-1 order-md-2 text-right text-md-right">
          <img class="img-fluid" src="https://dfcapp.s3.amazonaws.com/Hero+Image+For+Upgrade+Page+Perks+.png">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white overflow-hidden" style="height: 20vh" *ngIf="checkoutSuccess">
</div>
<div *ngIf="!checkoutSuccess">
<div class="grey-bg">
  <div class="container-fluid cards-container">
    <h3 class="feat-sub-text text-center">A GROWING LIST OF PERKS</h3>
    <h1 class="go-premium-txt text-center">
      Get up to a 50x return on your one-time<br> membership cost in just the first year.
    </h1>
    <div class="row mt-5 justify-content-start mx-auto">
      <div class="col-sm-6 col-lg pl-sm-0 pr-sm-4 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm ">
        <a href="https://www.tsa.gov/precheck" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/53.png">
        </a>
          <div>
            <p class="card-partner">TSA PreCheck</p>
            <p class="card-offer">5 YEARS FREE</p>
            <p class="limited-time-2">$75 Value*</p>
        </div>
      </div>
      <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm">
        <a href="https://www.prioritypass.com/" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/Perks-and-Navigator-Tools-partner-Images-4.png">
        </a>
        <div>
          <p class="card-partner">Priority Pass</p>
          <p class="card-offer">50% OFF MEMBERSHIP</p>
          <p class="limited-time-2">$50/Year Value*</p>
          </div>
      </div>
      <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm">
        <a href="https://www.tripadvisor.com/" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/44.png">
        </a>
        <div>
          <p class="card-partner">Trip Advisor</p>
          <p class="card-offer">14% OFF EXPERIENCES</p>
          <p class="limited-time-2">$100+/Year Value*</p>
        </div>
      </div>
      <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm">
        <a href="https://www.rentalcars.com/" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/43.png">
        </a>
        <div>
          <p class="card-partner">Rentalcars.com</p>
          <p class="card-offer">10% OFF RENTALS</p>
          <p class="limited-time-2">$100/Year Value*</p>
        </div>
      </div>
    </div>
    <div class="row mt-5 justify-content-start mx-auto">
      <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm">
        <a href="https://www.alltrails.com/" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/54.png">
        </a>
        <div>
          <p class="card-partner">All Trails</p>
          <p class="card-offer">6 MONTHS FREE</p>
          <p class="limited-time-2">$18 Value*</p>
        </div>
      </div>
      <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm">
        <a href="https://autocamp.com/" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/3-3.png">
        </a>
        <div>
          <p class="card-partner">Autocamp</p>
          <p class="card-offer">4th NIGHT FREE</p>
          <p class="limited-time-2">$300/Year Value*</p>
        </div>
      </div>
      <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm">
        <a href="https://www.rosettastone.com/" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/80.png">
        </a>
        <div>
          <p class="card-partner">Rosetta Stone</p>
          <p class="card-offer">50% OFF LIFETIME ACCESS</p>
          <p class="limited-time-2">$150/Year Value*</p>
        </div>
      </div>
      <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5 card-sm">
        <a href="https://www.viator.com/" target="_blank" rel="noopener noreferrer">
          <img class="sm-img" src="https://getperkscard.com/wp-content/uploads/2023/10/42.png">
        </a>
        <div>
          <p class="card-partner">Viator</p>
          <p class="card-offer">8% OFF BOOKINGS</p>
          <p class="limited-time-2">$100/Year Value*</p>
        </div>
      </div>
    </div>
  </div>
  <button class="view-perks-btn text-center all-perks-btn perk-btn mt-4" (click)="allPerks()">View All The Perks &#8594;</button>
</div>
<div class="bg-white">
  <div class="container-fluid lifetime-asset-container">
    <app-marketing-row [mainTitle]="rowContent.row1.mainTitle"
                       [highlightTitle]="rowContent.row1.highlightTitle"
                       [highlightClass]="highlightClass"
                       [paragraphs]="rowContent.row1.paragraphs"
                       [paragraphClass]="paragraphClass"
                       [imgSrc]="rowContent.row1.imgSrc"
                       [titleClass]="titleClass"
                       [button]="button"
                       [imgClass]="imgClass1"
    >
    </app-marketing-row>
  </div>
</div>
<div class="grey-bg">
  <div class="container-fluid cards-container">
    <h3 class="feat-sub-text text-center">INSTANT VALUE</h3>
    <h1 class="go-premium-txt text-center">
      Stack up the savings.
    </h1>
    <div class="row mt-5 justify-content-start mx-auto">
      <div class="col-sm-4 col-lg pl-sm-0 pr-sm-3 px-lg-0 mt-3 mt-lg-0 pr-lg-3 pr-xl-4">
        <div class="card-savings text-center">
          <div class="savings-header">~20%</div>
          <p class="savings-sub">Average Discount</p>
          <p class="savings-desc">We work with partners in the travel space to get you the best discounts
            possible.</p>
        </div>
      </div>
      <div class="col-sm-4 col-lg pl-sm-0 pr-sm-3 px-lg-0 mt-3 mt-lg-0 pr-lg-3 pr-xl-4">
        <div class="card-savings text-center">
          <div class="savings-header">$1,000+</div>
          <p class="savings-sub">Instant Savings</p>
          <p class="savings-desc">The moment you become a member, you’ll have an instant 10X return on your
            investment.</p>
        </div>
      </div>
      <div class="col-sm-4 col-lg pl-sm-0 pr-sm-3 px-lg-0 mt-3 mt-lg-0 pr-lg-3 pr-xl-4">
        <div class="card-savings text-center">
          <div class="savings-header">$5,000+</div>
          <p class="savings-sub">Lifetime Savings</p>
          <p class="savings-desc">We continue to add partners weekly, but even with our preexisting partners, there are
            over $5,000 in savings</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container-fluid">
    <div class="big-img-container">
      <img class="big-img" src="https://dfcapp.s3.amazonaws.com/Partner+Perks+2.0+Pitch+Deck+(2).png">
    </div>
  </div>
</div>
<div class="bg-white">
  <div class="container-fluid cards-container justify-content-center ">
    <app-marketing-row [mainTitle]="rowContent.row2.mainTitle"
                       [highlightTitle]="rowContent.row2.highlightTitle"
                       [highlightClass]="highlightClass"
                       [paragraphClass]="paragraphClass"
                       [imgSrc]="rowContent.row2.imgSrc"
                       [flippedRow]="true"
                       [titleClass]="titleClass"
                       [button]="button"
                       [imgClass]="imgClass"
    >
    </app-marketing-row>
    <div class="mt-2">
      <app-marketing-row [mainTitle]="rowContent.row3.mainTitle"
                         [highlightTitle]="rowContent.row3.highlightTitle"
                         [highlightClass]="highlightClass"
                         [paragraphClass]="paragraphClass"
                         [imgSrc]="rowContent.row3.imgSrc"
                         [titleClass]="titleClass"
                         [imgClass]="imgClass"
      >
      </app-marketing-row>
    </div>
    <app-marketing-row [mainTitle]="rowContent.row4.mainTitle"
                       [highlightTitle]="rowContent.row4.highlightTitle"
                       [highlightClass]="highlightClass"
                       [paragraphClass]="paragraphClass"
                       [imgSrc]="rowContent.row4.imgSrc"
                       [flippedRow]="true"
                       [titleClass]="titleClass"
                       [imgClass]="imgClass"
    >
    </app-marketing-row>
  </div>
  <div class="grey-bg position-relative overflow-hidden">
    <img class="position-absolute star-position" src="https://dfcapp.s3.amazonaws.com/48.png">
    <div class="container-fluid cards-container mt-5 pt-0">
      <h1 class="text-center">FAQ</h1>
      <div class="row mt-5">
        <div class="col-12 faq-border border-dark pt-4 pb-2 mt-3">
          <h5 class="faq-header">Will I get charged a membership fee again?<span class="float-right"><button
            type="button" class="collapse-btn" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapse1">
           <i *ngIf="isCollapsed" class="fa-solid fa-plus"></i>
          <i *ngIf="!isCollapsed" class="fa-solid fa-xmark"></i>
          </button></span></h5>
          <p class="collapse-txt" [ngbCollapse]="isCollapsed">Nope. Pay once and enjoy growing benefits for life :)
          </p>
        </div>
        <div class="col-12 faq-border border-dark pt-3 pb-2">
          <h5 class="faq-header">Do you add more benefits and discounts over time?<span class="float-right"><button
            type="button" class="collapse-btn" (click)="isCollapsed1 = !isCollapsed1"
            [attr.aria-expanded]="!isCollapsed1" aria-controls="collapse2">
           <i *ngIf="isCollapsed1" class="fa-solid fa-plus"></i>
          <i *ngIf="!isCollapsed1" class="fa-solid fa-xmark"></i>
          </button></span></h5>
          <p class="collapse-txt" [ngbCollapse]="isCollapsed1">Yes! We are constantly adding new partners and benefits
            to the membership. We will notify you via email when we add new benefits.
          </p>
        </div>
        <div class="col-12 faq-border border-dark pt-3 pb-2">
          <h5 class="faq-header">How much are the benefits worth? <span class="float-right"><button type="button"
                                                                                                    class="collapse-btn"
                                                                                                    (click)="isCollapsed2 = !isCollapsed2"
                                                                                                    [attr.aria-expanded]="!isCollapsed2"
                                                                                                    aria-controls="collapse3">
           <i *ngIf="isCollapsed2" class="fa-solid fa-plus"></i>
          <i *ngIf="!isCollapsed2" class="fa-solid fa-xmark"></i>
          </button></span></h5>
          <p class="collapse-txt" [ngbCollapse]="isCollapsed2">
            The benefits are worth over $5,000 and growing. We are constantly adding new partners and benefits to the
            membership. We will notify you via email when we add new benefits.
          </p>
        </div>
        <div class="col-12 faq-border border-dark pt-3 pb-2">
          <h5 class="faq-header">Can you request new brand discounts? <span class="float-right"><button type="button"
                                                                                                        class="collapse-btn"
                                                                                                        (click)="isCollapsed3 = !isCollapsed3"
                                                                                                        [attr.aria-expanded]="!isCollapsed3"
                                                                                                        aria-controls="collapse4">
           <i *ngIf="isCollapsed3" class="fa-solid fa-plus"></i>
          <i *ngIf="!isCollapsed3" class="fa-solid fa-xmark"></i>
          </button></span></h5>
          <p class="collapse-txt" [ngbCollapse]="isCollapsed3">
            Yes! We love to hear what brands you want to see in the membership. Please email us or request a partner
            here.
          </p>
        </div>
        <div class="col-12 faq-border border-dark pt-3 pb-2">
          <h5 class="faq-header">Are the benefits and discounts unique?<span class="float-right"><button type="button"
                                                                                                         class="collapse-btn"
                                                                                                         (click)="isCollapsed4 = !isCollapsed4"
                                                                                                         [attr.aria-expanded]="!isCollapsed4"
                                                                                                         aria-controls="collapse5">
           <i *ngIf="isCollapsed4" class="fa-solid fa-plus"></i>
          <i *ngIf="!isCollapsed4" class="fa-solid fa-xmark"></i>
          </button></span></h5>
          <p class="collapse-txt" [ngbCollapse]="isCollapsed4">
            Yes! We work with our partners to create unique discounts and benefits for our members.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
