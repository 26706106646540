import {Component, OnInit} from '@angular/core';
import {Api, MessageType} from '../../services/api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Membership, MembershipName, Memberships, MembershipSpec} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import * as _ from 'lodash';
import * as moment from 'moment';
import {CheckoutComponent} from '../checkout/checkout.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css', '../../../assets/css/portal.css', '../../../assets/css/portal-responsive.css', '../../../assets/fonts/elegantfont.css']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  editPasssword = false;
  profileFormBusy = false;
  validationActive = false;
  loadingDestinations = true;
  destinations: { id: number, name: string, image: string }[] = [];
  loadingAirlines = true;
  airlines: { id: number, name: string, image: string }[] = [];
  loadingDepartures = true;
  departures: { id: number, name: string, code: string, image: string, airport: number }[] = [];
  favoritesSelectionForm: FormGroup;
  destination: any;
  departure: any;
  airline: any;
  activeSection = 'departure';
  activeSubsection: string;
  subscription: any;
  updateBilling = false;
  couponId = 10000;
  user: any;
  displayPhone = false;
  myMembership: MembershipSpec;
  generic_error = 'There has been an error, please try again. If the problem persists refresh the page.';
  whitelabel_config: any;
  displaySalesBanner = false;
  memberships: typeof Memberships = Memberships;
  hidePause: boolean;
  hideDiscount: boolean;


  emailNotificationControl = new FormControl();

  constructor(public api: Api, formBuilder: FormBuilder, public route: ActivatedRoute,  private modalService: NgbModal) {
    this.route.params.subscribe(params => {
      if (params['section']) {
        this.activeSection = params['section'];
        window.scroll(0,0);
      }
    });
    this.profileForm = formBuilder.group(
      {
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9A-Z]+[a-zA-Z0-9\.\\-\\_]*\@[a-zA-Z0-9\.\\-\\_]+\.[a-z0-9A-Z]{2,}$')]],
        phone: [''],
        password: ['', [Validators.required, Validators.minLength(6)]],
        new_password: ['', [Validators.required, Validators.minLength(6), this.matchingPasswords('password')]],
      });
    this.favoritesSelectionForm = formBuilder.group(
      {
        destination: [''],
        departure: [''],
        airline: ['']
      }
    );

    this.emailNotificationControl.valueChanges.pipe(
      switchMap(enabled => {
        return this.api.toggleNotifications(enabled)
      })
    ).subscribe();

    this.api.addInitCallback(async () => {
      this.subscription = await this.api.membershipInfo();
      if (this.api.user) {
        this.profileForm.controls.name.setValue(this.api.user.name);
        this.profileForm.controls.email.setValue(this.api.user.email);
        this.profileForm.controls.phone.setValue(this.api.user.phone);
        this.profileForm.controls.password.setValue(this.api.user.email);
        this.profileForm.controls.new_password.setValue(this.api.user.email);
        this.emailNotificationControl.patchValue(this.api.user.emailNotifications, {emitEvent: false});
      }
      try {
        const destinations = await this.api.getFavoriteDestinations();
        this.loadingDestinations = false;
        this.destinations = destinations;
      } catch (error) {
        this.loadingDestinations = false;
        this.destinations = [];
      }

      try {
        const departures = await this.api.getFavoriteDepartures();
        this.loadingDepartures = false;
        this.departures = departures;
      } catch (error) {
        this.loadingDepartures = false;
        this.departures = [];
      }

      try {
        const departures = await this.api.getFavoriteAirlines();
        this.loadingAirlines = false;
        this.airlines = departures;
      } catch (error) {
        this.loadingAirlines = false;
        this.airlines = [];
      }

    });
    this.api.user$.subscribe(u => {
      this.user = u;
      if(this.user) {
        this.myMembership = Membership.getMembershipSpec(this.user.membershipId);
        this.whitelabel_config = findWhiteLabel(this.user.partnerId);
        this.displaySalesBanner = this.whitelabel_config.members_dashboard.banner
          && this.api.user.membershipId === this.memberships.free;
        if (this.user.membershipId > 1) {
          this.displayPhone = true
        }
      }
    });
  }

  destinationSelected(destination: any) {
    if (destination)
      this.destination = destination;
  }
  airlineSelected(airline: any) {
    if (airline)
      this.airline = airline;
  }
  departureSelected(departure: any) {
    if (departure)
      this.departure = departure;
  }
  removeDestination(id: number) {
    this.destinations.splice(this.destinations.indexOf(this.destinations.filter(item => item.id === id)[0]), 1);
    this.api.removeFavoriteDestination(id).then(success => { }, error => { }).catch(error => { });
  }
  removeAirline(id: number) {
    this.airlines.splice(this.airlines.indexOf(this.airlines.filter(item => item.id === id)[0]), 1);
    this.api.removeFavoriteAirline(id).then(success => { }, error => { }).catch(error => { });
  }
  removeDeparture(id: number) {
    this.departures.splice(this.departures.indexOf(this.departures.filter(item => item.id === id)[0]), 1);
    this.api.removeFavoriteDeparture(id).then(success => { }, error => { }).catch(error => { });
  }
  async addDestination() {
    if (this.destination) {
      this.loadingDestinations = true;
      try {
        const destination = await this.api.addFavoriteDestination(this.destination.value);
        this.loadingDestinations = false;
        if (destination) {
          this.destinations = await this.api.getFavoriteDestinations();
          this.destination = null;
          this.favoritesSelectionForm.controls.destination.reset();
        } else
          this.api.showMessage(MessageType.error, this.generic_error);

      } catch (error) {
        this.loadingDestinations = false;
        this.api.showMessage(MessageType.error, this.generic_error);
      }
    }
  }
  async addAirline() {
    if (this.airline) {
      this.loadingAirlines = true;
      try {
        const airline = await this.api.addFavoriteAirline(this.airline.value);
        this.loadingAirlines = false;
        if (airline) {
          this.airlines.push(airline);
          this.airline = null;
          this.favoritesSelectionForm.controls.airline.reset();
        } else
          this.api.showMessage(MessageType.error, this.generic_error);

      } catch (error) {
        this.loadingAirlines = false;
        this.api.showMessage(MessageType.error, this.generic_error);
      }
    }
  }
  async addDeparture() {
    if (this.departure) {
      try {
        this.loadingDepartures = true;
        const departure = await this.api.addFavoriteDeparture(this.departure.airport);
        this.loadingDepartures = false;
        if (departure) {
          this.departures = await this.api.getFavoriteDepartures();
          this.departure = null;
          this.favoritesSelectionForm.controls.departure.reset();
        } else
          this.api.showMessage(MessageType.error, this.generic_error);
      } catch (error) {
        this.loadingDepartures = false;
        this.api.showMessage(MessageType.error, this.generic_error);
      }
    }

  }
  changePassword() {
    this.editPasssword = true;
    this.profileForm.controls.password.enable();
    this.profileForm.controls.password.setValue('');
    this.profileForm.controls.new_password.setValue('');
  }
  cancelPassword() {
    this.editPasssword = false;
    this.profileForm.controls.password.setValue(this.api.user.email);
    this.profileForm.controls.new_password.setValue(this.api.user.email);
    this.profileForm.controls.password.disable();

  }

  getPhone(){
    const phone = _.get(this.api.user, 'phone');
    if(phone){
      const area = phone.slice(0,3);
      const mid = phone.slice(3,6);
      const end = phone.slice(6,10);
      return `(${area}) ${mid}-${end}`;
    }
    return '';
  }

  matchingPasswords(passwordKey: string) {
    return (group: FormGroup) => {
      if (!group || !group.parent || !this.editPasssword)
        return null;
      const passwordInput = group.parent.get(passwordKey);
      if (passwordInput.value !== group.value) {
        return { notEquivalent: true };
      }
    };
  }

  async update(event: any) {
    event.stopPropagation();
    this.validationActive = true;
    if (!this.profileForm.valid && !this.profileForm.controls.phone.valid)
      return;
    this.profileFormBusy = true;
    this.profileForm.disable();
    try {
      const success = await this.api.updateAccountInfo({name: this.profileForm.controls.name.value, email: this.profileForm.controls.email.value, phone: this.profileForm.controls.phone.value, password: this.profileForm.controls.password.value})
      this.profileFormBusy = false;
      this.profileForm.enable();
      if (success) {
        this.api.showMessage(MessageType.success, this.api.strings.portal.account_information_updated);
        this.activeSubsection = '';
      } else
        this.api.showMessage(MessageType.error, this.api.strings.portal.account_update_error);
    } catch (error) {
      this.profileForm.enable();
      this.profileFormBusy = false;
      this.api.showMessage(MessageType.error, this.api.strings.portal.account_update_error);
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngAfterViewInit(){
    this.route.queryParams.subscribe(params => {
      if(params['openModal']){
        this.openModal(true);
      }
    });
  }
  closeAccountSection(event: any, section: string) {
    event.stopPropagation();
    setTimeout(() => {
      this.activeSubsection = '';
      if (section !== 'password')
        this.profileForm.controls[section].setValue(this.api.user[section]);
      else {
        this.profileForm.controls.password.setValue(this.api.user.email);
        this.profileForm.controls.new_password.setValue(this.api.user.email);
      }
    }, 100);

  }
  stopEvent(event: any) {
    event.stopPropagation();
  }

  region(event: any) {
    if (event.target.checked)
      this.api.updateRegion(event.target.value).then(success => { }, error => { }).catch(error => { });
  }

  public openModal(updateBilling: boolean = false){
    this.updateBilling = updateBilling;
    const checkout = this.modalService.open(CheckoutComponent)
    const component = checkout.componentInstance as CheckoutComponent
    component.user = this.user
    component.onboardingRedirect = false
    component.updateBilling = this.updateBilling
    component.location = 'Members Dashboard - Profile'
  }

  getDate(date:number){
    return moment.unix(date).format('MM/DD/YYYY');
  }
  doesIncludeLifetime(partnerId: string): boolean {
    return partnerId.includes('lifetime');
  }
  getMembershipLabel() {
    if (this.api.user && this.subscription) {
      const membership: MembershipSpec = Membership.getMembershipSpec(this.api.user.membershipId);
      let membershipName: string;
      if(membership.basePlan === MembershipName.premiumPlus){
        membershipName = 'Premium Plus';
      } else if (membership.basePlan === MembershipName.premium){
        membershipName = 'Premium';
      } else {
        membershipName = 'Free';
      }
      if(this.subscription.trialActive){
        this.hideDiscount = true;
        this.hidePause = true;
      }
      return this.subscription.trialActive ? `${membershipName} Trial` : membershipName;
    }
  }

}
