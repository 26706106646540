<div class="loader" *ngIf="api.loading && api.error">
  <p>Ups! I can't connect to the server. Please refresh the page to try again <a *ngIf="api.error" href="/">Retry</a></p>
</div>
<div class="modal-message-container" *ngIf="api.modalMessage">
  <div class="message {{api.modalMessageClass}} animated jackInTheBox">
    <a href="javascript:void(0);" (click)="api.modalMessage=false;" (click)="api.modalMessage=false;">X</a>
    <h1>{{api.modalMessageTitle}}</h1>
    <p>
        {{api.modalMessageText}}
    </p>
  </div>
</div>
<ul class="alerts">
  <li *ngFor="let alert of api.alerts; let i=index"  class="{{alert?.class}} animated slideInRight">
    <div class="alert-icon">
      <i class="{{alert?.icon}}"></i>
    </div>
    <div class="alert-message">
        <a href="javascript:void(0);" (click)="api.removeAlert(i);">x</a>
      {{alert?.message}}
    </div>
  </li>
</ul>

<div class="modal-message-container" *ngIf="api.confirmUpgrade">
  <div class="message warning animated jackInTheBox">
      <a href="javascript:void(0);" class="action" (click)="api.confirmUpgrade=false;">{{api.strings.portal.cancel}}</a>
    <h1>{{api.strings.portal.confirm_membership_upgrade_title}}</h1>
    <p>
      {{api.strings.portal.confirm_membership_upgrade_text}}
    </p>
    <button class="btn btn-primary btn-lg btn-block" (click)="api.confirmUpgrade=false;api.confirmUpgradeCallback();">{{api.strings.portal.confirm}}</button>
  </div>
</div>

<div class="modal-message-container" *ngIf="api.confirmFree">
  <div class="message warning animated jackInTheBox">
    <a href="javascript:void(0);" class="action" (click)="api.confirmFree=false;api.freeCallback();">{{api.strings.wizard.free_membership_warning_confirmation}}</a>
    <h1>{{api.strings.wizard.free_membership_warning_title}}</h1>
    <p>
        {{api.strings.wizard.free_membership_warning_text}}
    </p>
    <button class="btn btn-primary btn-lg btn-block" (click)="api.confirmFree=false;">{{api.strings.wizard.free_membership_warning_back_button}}</button>
  </div>
</div>
