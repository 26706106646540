import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Api} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DEFAULT_WHITELABEL, findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import * as _ from 'lodash';
import {DealTypes, FlightClass} from '@dollar-flight-club/shared_utilities/lib/models/Deal';
import {buildKiwiLink, buildSkyScannerLink} from "../../utilities/link-generators";
import {DealDto, TicketInfo} from "../../models/DealDto";
import {CheckoutComponent} from "../checkout/checkout.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserModel} from "../../models/Authentication";

@Component({
  selector: 'deal',
  templateUrl: './deal.component.html',
  styleUrls: ['../../../assets/css/portal.css','../../../assets/css/portal-responsive.css','../../../assets/fonts/elegantfont.css', './deal.component.css']
})
export class DealComponent implements OnInit, OnDestroy {
  dealTypes: typeof DealTypes = DealTypes;
  flightClass = FlightClass;
  whitelabel_config: any;
  currentDeal: DealDto;
  skyScannerLink: string;
  googleFlightsLink: string;
  tequilaDeepLink: string;
  tequilaGeneralLink: string;
  user: UserModel


  constructor(public api:Api,
              private eRef: ElementRef,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    this.api.user$.subscribe(user =>{
      if(user){
        this.user = user
        this.whitelabel_config = findWhiteLabel(user.partnerId);
      }
    });

    this.api.getDeal(this.route.snapshot.params['id']).subscribe(
      deal => {
        this.currentDeal = deal;
        this.googleFlightsLink = deal.googleFlightsLink;
        this.skyScannerLink = buildSkyScannerLink(
          deal.featured_deal.origin_airport,
          deal.featured_deal.destination_airport,
          deal.dates.find(dategrp => dategrp.code === deal.featured_deal.origin_airport).from_date,
          deal.dates.find(dategrp => dategrp.code === deal.featured_deal.origin_airport).to_date,
          deal.featured_deal.flightClass
        );
        this.tequilaDeepLink = deal.featured_deal.link;
        const originDates = deal.dates.filter(dategrp => dategrp.code === deal.featured_deal.origin_airport);
        const earliestDepartDate = originDates.map(dateRange => dateRange.from_date).sort().shift();
        const latestDepartDate = originDates.map(dateRange => dateRange.from_date).sort().reverse().shift();
        const earliestReturnDate = originDates.map(dateRange => dateRange.to_date).sort().shift();
        const latestReturnDate = originDates.map(dateRange => dateRange.to_date).sort().reverse().shift();

        this.tequilaGeneralLink = buildKiwiLink(
          deal.featured_deal.origin_airport,
          deal.featured_deal.destination_airport,
          earliestDepartDate,
          latestDepartDate,
          earliestReturnDate,
          latestReturnDate,
          deal.featured_deal.flightClass
        );
      }
    );
  }

  ngOnDestroy(): void {
    console.log('dealcomponent onDestroy')
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(event.target){
      if(_.includes(event.target.className, 'dealcover')) {
        this.close();
      }
    }
  }
  upgrade(){
    const checkout = this.modalService.open(CheckoutComponent)
    const component = checkout.componentInstance as CheckoutComponent
    component.user = this.user
    component.onboardingRedirect = false
    component.location = 'Members Dashboard - Deal Component'
  }
  close() {
    this.router.navigate(['dashboard'])
  }

  getSkyscannerLink(deal: TicketInfo & {memberships: string}) {
    const fromDate = this.currentDeal.dates.find(dategrp => dategrp.code === deal.origin_airport).from_date;
    const toDate = this.currentDeal.dates.find(dategrp => dategrp.code === deal.origin_airport).to_date;

    return buildSkyScannerLink(
      deal.origin_airport,
      deal.destination_airport,
      fromDate,
      toDate,
      deal.flightClass
    )
  }
}
