<vertical-nav></vertical-nav>
<div class="content-area">
  <menu-bar></menu-bar>
  <div class="content-wrapper mb-5" *ngIf="perkDetails; else loading">
    <div class="row pl-3 pl-md-3 pl-lg-1">
      <div class="container-fluid full-width-container">
        <div class="row perk-details-container">
          <div class="col-12">
            <button class="back-button" (click)="goBack()">
              &#8592;
            </button>
          </div>
          <div class="col-12 col-md-6 perk-image">
            <img [src]="getPerkImage()" [alt]="perkDetails?.name">
          </div>
          <div class="col-12 col-md-6 perk-details">
            <h1>{{ perkDetails?.name }}</h1>
            <p class="discount-full">{{ perkDetails?.discountCopyFull }}</p>
            <p class="main-copy">{{ perkDetails?.mainCopy }}</p>
            <div class="discount-code">
              <span>Discount Code: </span> <span>{{ perkDetails?.discountCode }}</span>
            </div>
            <a [href]="perkDetails?.link" target="_blank" rel="noreferrer noopener">
             <button class="use-perk-button" >USE THE PERK &rarr; </button>
            </a>
            <div class="support-box">
              If you have any problems redeeming your perk, please contact our support team and they’ll help you rectify the issue. <a href="#">Contact Support here.</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <static-footer></static-footer>
</div>
<ng-template #loading>
  <div class="loading-container">
    <p>Loading...</p>
  </div>
</ng-template>
