<app-countdown-banner
  [displaySalesBanner]="whitelabelConfig?.signup.banner"
  [banner_txt]="whitelabelConfig?.signup.banner_text"
>
</app-countdown-banner>
<div class="container-contact100">
  <div class="wrap-contact100">
    <div class="contact100-form validate-form">
      <div>
        <img [ngClass]="whitelabelConfig?.css + '-logo'"
             class="img-responsive" [src]="whitelabelConfig?.logos.main">
      </div>
      <h2>Pick your departure airport.</h2>
      <h6 class="lh-26">We send alerts when we find flights up to 90% off leaving your airport.</h6>

      <form class="capture-form" [formGroup]="searchForm" autocomplete="off" novalidate>
        <div *ngIf="suggestedAirports && suggestedAirports.length > 0" class="suggested-airports">
          <ul class="list-group">
            <li class="list-group-item suggested-airport" (click)="selectSuggestedAirport(airport)"
                *ngFor="let airport of suggestedAirports">
              {{airport.city}} ({{airport.code}})
              <i class="fa fa-plus add-icon"></i>
            </li>
          </ul>
        </div>
        <div *ngIf="!selectedAirport" class="input-container">
          <input #departure autofocus type="text" class="form-control form-control-md" placeholder="Search Airport..." formControlName="search" />
          <i class="fa fa-search search-icon"></i>
        </div>
          <ul class="list-group">
            <li class="list-group-item" (click)="select(airport.ID)"
                *ngFor="let airport of airports; let i = index">
              {{airport.city}} ({{airport.code}})
            </li>
          </ul>

        <div *ngIf="selectedAirport" class="selected-airport">
          <div class="selected"><b>{{selectedAirport?.city}} ({{selectedAirport?.code}})</b> <button class="btn remove-btn" (click)="removeSelectedAirport()">x</button></div>
        </div>

        <button *ngIf="selectedAirport" [ngClass]="'primary-btn-' + whitelabelConfig?.css"
                class="btn btn-md btn-block" (click)="setAirport()">
          <span class="spinner-border spinner-border" *ngIf="loading" role="status" aria-hidden="true"></span>
          <span *ngIf="!loading"> Next </span>
        </button>
        <p class="fs-11 pro-tip"><b style="font-weight: bold; text-transform: uppercase;">Pro-tip:</b> Select the largest international airport near your home to ensure you get the best deals. You can add or update airports later.</p>
        <div class="position-relative mt-6"><div class="pwr-block" *ngIf="whitelabelConfig?.powered_by">
          <a class="d-flex" href="https://dollarflightclub.com/" target="_blank">
            <span class="powered-by-txt">Powered by</span>
            <div class="powered-by-logo"></div>
          </a>
        </div></div>
      </form>
    </div>
    <div class="contact100-more flex-col-c-m"
         style="background-image: url('https://dfcmedia.s3.amazonaws.com/email-assets/departure-bg-img.jpg'); justify-content: flex-start;">
    </div>
  </div>
</div>
