import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Api } from '../../services/api.service';
import { PartnerPerksModel } from '../../models/PartnerPerksModel';

@Component({
  selector: 'app-perk-details',
  templateUrl: './perk-details.component.html',
  styleUrls: ['./perk-details.component.css']
})
export class PerkDetailsComponent implements OnInit {
  perkDetails: PartnerPerksModel | null = null;
  hasValidPerksSubscription: boolean;

  constructor(
    private route: ActivatedRoute,
    private api: Api,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.hasValidPerksSubscription = this.route.snapshot.queryParams.hasSub === 'true';
    this.api.getPerk(this.route.snapshot.params.name).subscribe(
      details => {
        this.perkDetails = details;
        if (this.perkDetails.name === 'TSA PreCheck' && !this.hasValidPerksSubscription) {
            this.perkDetails.mainCopy = 'TSA PreCheck is like a VIP pass for airport security; you get to skip the hassle of taking off your shoes and pulling out your laptop. It\'s a real time-saver and a game-changer for regular travelers who just want to breeze through security.';
            this.perkDetails.discountCopyFull = 'Get $5 back when you sign up for TSA PreCheck through Clear';
            this.perkDetails.link = 'https://form.typeform.com/to/TaWFedGC';
        }
      },
      error => {
        console.error('Error loading perk details:', error);
      }
    );
  }

  getPerkImage(): string {
    return this.perkDetails.image
  }

  goBack(): void {
    this.location.back();
  }
}
