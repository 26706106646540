import { Component, OnInit } from '@angular/core';
import { Api } from '../../services/api.service';
import * as moment from "moment";

@Component({
  selector: 'customscripts',
  templateUrl: './customscripts.component.html',
  styleUrls: ['./customscripts.component.css']
})
export class CustomscriptsComponent implements OnInit {

  constructor(public api:Api) { }

  ngOnInit() {
    if (this.api.user) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.appendChild(document.createTextNode(`window.intercomSettings = {app_id: "idayx9bm", custom_launcher_selector:'#my_custom_link',name: "${this.api.user.name || 'Customer'}",email: "${this.api.user.email}",created_at: "${moment().unix()}"};`));
      document.getElementsByTagName('body')[0].appendChild(script);
    }


    let interComScript=document.createElement('script');
    interComScript.src = 'https://app.dollarflightclub.com/assets/js/intercom.js';
    document.getElementsByTagName('body')[0].appendChild(interComScript);
  }

}
