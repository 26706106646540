import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_WHITELABEL, findWhiteLabel } from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import { Api } from '../../services/api.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NEWSLETTERS } from '../../models/newsletters';
var OnboardingComponent = /** @class */ (function () {
    function OnboardingComponent(route, router, api, config, formBuilder, ngxModalService) {
        this.route = route;
        this.router = router;
        this.api = api;
        this.ngxModalService = ngxModalService;
        this.onboarding = false;
        this.slides = [];
        this.activeSlide = {};
        this.submitSuccess = false;
        this.submitError = false;
        this.newsletters = NEWSLETTERS;
        this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
        localStorage.setItem('favicon', this.whitelabel_config.logos.favicon);
        localStorage.setItem('title', this.whitelabel_config.name);
        config.interval = 0;
        config.keyboard = false;
        this.phoneForm = formBuilder.group({ phone: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]] });
        var newsletterControls = {};
        this.newsletters.forEach(function (item) {
            newsletterControls[item.id] = formBuilder.control(false);
        });
        this.newsLetterForm = formBuilder.group(newsletterControls);
    }
    OnboardingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setUpSlides();
        this.api.user$.subscribe(function (user) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (user) {
                    this.whitelabel_config = findWhiteLabel(user.partnerId);
                }
                return [2 /*return*/];
            });
        }); });
        this.onboarding = JSON.parse(this.route.snapshot.queryParamMap.get('onboard'));
        if (this.onboarding) {
            var plan = this.route.snapshot.paramMap.get('plan');
            this.trackEvent(plan);
            return;
        }
        this.router.navigateByUrl('/dashboard');
    };
    OnboardingComponent.prototype.nextSlide = function () {
        if (this.activeSlide.step === this.slides.length - 1) {
            this.router.navigateByUrl('/dashboard');
            return;
        }
        this.activeSlide = this.slides[this.activeSlide.step + 1];
        this.activeSlide.additionalClass = 'active';
    };
    OnboardingComponent.prototype.openModal = function (modal) {
        if (modal === void 0) { modal = 'videoModal'; }
        var targetModal = this.ngxModalService.getModal(modal);
        if (targetModal) {
            targetModal.open();
        }
    };
    OnboardingComponent.prototype.submitNewsletters = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newsletters;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.nextSlide();
                        newsletters = this.newsLetterForm.value;
                        return [4 /*yield*/, this.api.newsLetterSignup(newsletters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    OnboardingComponent.prototype.addPhoneNum = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var success, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.phoneForm.disable();
                        return [4 /*yield*/, this.api.updateAccountInfo({ phone: this.phoneForm.value.phone })];
                    case 1:
                        success = _a.sent();
                        success ? this.submitSuccess = true : this.submitError = true;
                        this.phoneForm.enable();
                        this.nextSlide();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.phoneForm.controls.phone.reset();
                        this.phoneForm.enable();
                        this.submitSuccess = false;
                        this.submitError = true;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OnboardingComponent.prototype.trackEvent = function (plan) {
        gtag('event', 'signup', {
            'event_category': plan,
            'event_label': plan === 'basic' ? 'Free membership sign up' : 'Premium signup',
            'value': 69 //price
        });
    };
    OnboardingComponent.prototype.setUpSlides = function () {
        this.slides = [
            {
                step: 0,
                title: "Welcome to " + this.whitelabel_config.name + "!",
                subtitle: "Before we get started, did you want to subscribe to some of our travel newsletters?",
                additionalClass: 'active',
            },
            {
                step: 1,
                title: "First, some homework!",
                subtitle: "Take 1 minute to add us as an email contact & add us to your primary inbox.",
                additionalClass: ''
            },
            {
                step: 2,
                title: "Want a text alert when we find a flight to your dream destination?",
                subtitle: "Add your phone number and we'll send you a quick text alert so you can snag that dream deal before it's gone. Feature coming soon.",
                additionalClass: ''
            }
        ];
        this.activeSlide = this.slides[0];
    };
    return OnboardingComponent;
}());
export { OnboardingComponent };
