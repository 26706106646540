import {Component, OnInit} from '@angular/core';
import {Api} from '../../services/api.service';
import {Memberships} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';

@Component({
  selector: 'support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css','../profile/profile.component.css', '../../../assets/css/portal.css', '../../../assets/css/portal-responsive.css', '../../../assets/fonts/elegantfont.css']
})
export class SupportComponent implements OnInit {

  displaySalesBanner = false;
  whitelabel_config: any;
  user: any;

  constructor(public api:Api) {
    this.api.user$.subscribe(u => {
      this.user = u;
      if(this.user){
        this.whitelabel_config = findWhiteLabel(this.user.partner_id);
        this.displaySalesBanner = this.whitelabel_config.members_dashboard.banner
          && this.api.user.membershipId === Memberships.free;
      }
    });
  }
  ngOnInit() {}
}
