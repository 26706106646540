import { Component, OnInit } from '@angular/core';
import {Api,MessageType} from '../../services/api.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { Router } from '@angular/router';
@Component({
  selector: 'passwordrecover',
  templateUrl: './passwordrecover.component.html',
  styleUrls: ['../../../assets/css/login.css']
})
export class PasswordrecoverComponent implements OnInit {

  validationActive:boolean=false;
  recoverForm:FormGroup;
  busy:boolean = false;
  success:boolean=false;
  constructor(public api:Api,public router:Router,formBuilder:FormBuilder) 
  {
    if(api.logged)
      router.navigateByUrl("/dashboard");
    else
      this.recoverForm=formBuilder.group
      (
        {
          email:['',[Validators.required,Validators.email,Validators.pattern("^[a-z0-9A-Z]+[a-zA-Z0-9\.\\-\\_]*\@[a-zA-Z0-9\.\\-\\_]+\.[a-z0-9A-Z]{2,}$")]]
        }
      );
   }

  ngOnInit() {
  }
  public disable()
  {
    this.busy=true;
    this.recoverForm.controls.email.disable();
  }
  public enable()
  {
    this.busy=false;
    this.recoverForm.controls.email.enable();
  }
  eventHandler(event)
  {
    if(event.keyCode==13)
      this.recover();
  }
  public recover()
  {
    this.validationActive=true;
    if(this.busy||!this.recoverForm.valid)
      return;
    this.disable();
    this.api.recover(this.recoverForm.controls.email.value).then((success)=>
    {
      if(success)
        this.success=true;
      else
      {
        this.enable();
        this.api.showMessage(MessageType.error,this.api.strings.portal.generic_error);
      }
    },err=>
    {
      this.enable();
      this.api.showMessage(MessageType.error,this.api.strings.portal.generic_error);
    }).catch((error)=>
    {
      this.enable();
      this.api.showMessage(MessageType.error,this.api.strings.portal.generic_error);
    });
    
  }
}
