<div class="modal-overlay" (click)="closeModal()" [style.background-color]="getModalOverlayColor()" style="display: block;"></div>
<div class="modern-email-modal">
  <p class="close-button" (click)="closeModal()">x</p>
  <div class="modal-content">
    <img [src]="getNavLogo()" alt="Your Logo" class="modal-logo">
    <p class="enter-email">Enter your email to get started:</p>
    <form [formGroup]="emailForm" autocomplete="off" novalidate>
      <input type="email" class="form-control" placeholder="Email Address" formControlName="email"
             [ngClass]="validationState(emailForm.get('email'))">
      <div class="invalid-feedback">Please enter a Valid Email</div>
      <button class="btn-lg" [style.border-radius.px]="getBorderRadius()" [style.background-color]="getButtonColor()" [disabled]="!validEmail" (click)="checkoutSession()">
        <span *ngIf="!loading">Continue</span>
        <span *ngIf="loading" class="loading-spinner"></span>
      </button>
    </form>
  </div>
</div>
