import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {findWhiteLabel, IWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel'
import {tap} from 'rxjs/operators';
import {Api} from '../../services/api.service';


declare global {
  interface Window {
    tdl:any;
  }
}
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {
  whitelabelConfig: IWhiteLabel;
  partnerId: string
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  coupon: any;
  discount: string;

  constructor(private route: ActivatedRoute, private api: Api) {
  }

  ngOnInit() {
    this.whitelabelConfig = this.route.snapshot.data.whitelabel;
    this.partnerId = this.route.snapshot.params.partner_id;
    localStorage.setItem('favicon',this.whitelabelConfig.logos.favicon);
    localStorage.setItem('title',this.whitelabelConfig.name);
    var deadline = new Date(); //today
    deadline.setHours(0, 0, 0, 0);
    deadline.setDate(deadline.getDate() + 1);
    setInterval(() => this.setTimeRemaining(deadline), 1000);
    if (this.whitelabelConfig.id === 'lifetimefinancebuzz'){
      this.bootstrapFinanceBuzz();
    }
    const params = this.route.snapshot.queryParams;
    if (params.coupon) {
      this.coupon = params.coupon;
      this.api.checkCoupon(this.coupon).pipe(
        tap(response => {
          if (response && response.error) {
            console.error('Error checking coupon:', response.message);
            this.discount = '';
          } else {
            this.discount = response.percent_off ? `${response.percent_off}%` : `$${response.amount_off / 100}`;
          }
        })
      ).subscribe();
    }
  }
  setTimeRemaining(endtime: Date) {
    var d = new Date();
    var t = Date.parse(endtime.toString()) - Date.parse(d.toString());
    if (t < 0) {
      return false;
    }
    this.seconds = Math.floor((t / 1000) % 60);
    this.minutes = Math.floor((t / 1000 / 60) % 60);
    this.hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    this.days = Math.floor(t / (1000 * 60 * 60 * 24));
  }

  bootstrapFinanceBuzz() {

    var o = window.tdl = window.tdl || [];
    if (o.invoked) window.console && console.error && console.error("Tune snippet has been included more than once.");
    else {
      o.invoked = !0, o.methods = ["init", "identify", "convert"], o.factory = function(n) {
        return function() {
          var e = Array.prototype.slice.call(arguments);
          return e.unshift(n), o.push(e), o
        }
      };
      for (var e = 0; e < o.methods.length; e++) {
        var n = o.methods[e];
        o[n] = o.factory(n)
      }
      o.init = function(e) {
        var n = document.createElement("script");
        n.type = "text/javascript", n.async = !0, n.src = "https://js.go2sdk.com/v2/tune.js";
        var t = document.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(n, t), o.domain = e
      }
      o.init({
        'servetrack': 'https://servetrack.go2cloud.org'
      });
      o.identify();
      o.convert({
        'adv_sub': 'dollarflightclub-js'
      });
    }
  }
}
