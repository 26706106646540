import {Component, Inject, OnInit, Renderer2, ViewChild,} from '@angular/core';
import {Api} from '../../services/api.service';
import {DOCUMENT} from '@angular/common';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {CheckoutComponent} from '../checkout/checkout.component';
import {filterMenuAnimations} from '../../utilities/animations';
import {Memberships} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {ActivatedRoute} from '@angular/router';
import {findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';

declare var gtag: Function;


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../../assets/css/portal.css', '../../../assets/css/portal-responsive.css', '../../../assets/fonts/elegantfont.css', './dashboard.component.css'],
  animations: filterMenuAnimations,
})
export class DashboardComponent implements OnInit {
  @ViewChild(CheckoutComponent) checkoutComponent: CheckoutComponent;

  show = false;
  whitelabel_config: any;
  memberships: typeof Memberships;
  dealsTitle = 'Flight Deals';
  displaySalesBanner = false;

  constructor(public api: Api,
    public renderer: Renderer2,
    public ngxModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private _document
  ) {

  }

  ngOnInit() {
    this.memberships = Memberships;
    window.scrollTo(0, 0);
    this.api.user$.subscribe(user =>{
    if(user){
      this.whitelabel_config = findWhiteLabel(user.partnerId);
      this.displaySalesBanner =
        this.whitelabel_config.members_dashboard.banner
        && this.api.user.membershipId === this.memberships.free;
    }
    });
    this.route.queryParams.subscribe(params => {
      if (params.dealType) {
        params.dealType === 1 ? this.dealsTitle = 'Weekend Getaways' : this.dealsTitle = 'Flight Deals';
        window.scroll(0,0);
      }
    });
  }

  get stateName() {
    return this.show ? 'show' : 'hide';
  }

  toggle() {
    this.show = !this.show;
  }
}
