<form [formGroup]="captureForm" autocomplete="off" class="capture-form" novalidate>
  <p class="text-center fs-14">
    Signup with your email address
  </p>
  <div>
    <input #email (keydown)="handleKeyDown($event)" [ngClass]="validationState(captureForm.get('email'))" autofocus
           class="form-control form-control-md" formControlName="email"  placeholder="Email" type="email"
    />
    <div class="invalid-feedback mb-3">
      Please enter a valid Email
    </div>
  </div>
  <div>
    <input #password (keydown)="handleKeyDown($event)" *ngIf="showPassword"
           [ngClass]="validationState(captureForm.get('password'))" class="form-control form-control-md" formControlName="password"
           placeholder="Password" type="password"
    />
    <div class="invalid-feedback mb-3">
      Please enter a valid Password
    </div>
  </div>
  <button (click)="onSignupClick()" [ngClass]="'primary-btn-' + whitelabelConfig?.css" class="btn btn-md btn-block"
          type="button">
    <span *ngIf="loading" aria-hidden="true" class="spinner-border spinner-border" role="status"></span>
    <span *ngIf="!loading&&!showPassword">
      {{whitelabelConfig?.signup.btn_home}}
    </span>
    <span *ngIf="!loading&&showPassword">
      Next
    </span>
  </button>
</form>

<div class="capture-form">
  <div>
    <p class="fancy-line my-4"><span>Or</span></p>
    <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
      A user already exists with this email. Please
      <a href="https://app.dollarflightclub.com/login"> login here</a>.
    </div>
    <button  #loginRef class="btn d-block mx-auto">
    </button>
  </div>

  <p class="text-center my-2" style="font-size: 11px;">
    By signing up you agree to the
    <a href="https://dollarflightclub.com/terms-of-use/" target="_blank">
      Terms of Use
    </a>
    and
    <a href="https://dollarflightclub.com/privacy-policy/" target="_blank">
      Privacy Policy
    </a>
  </p>
  <div *ngIf="whitelabelConfig?.powered_by" class="pwr-block mt-5">
    <a class="d-flex" href="https://dollarflightclub.com/" target="_blank">
      <span class="powered-by-txt d-block">
        Powered by
      </span>
      <div class="powered-by-logo"></div>
    </a>
  </div>
</div>
