import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Api} from '../services/api.service';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
config: any;
  constructor(private api: Api, private router: Router, public http: HttpClient) {
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      return await this.http.post(`${environment.baseUrl}/dfcmembers/session`, {}, {withCredentials: true}).toPromise().then(res => {
          const response: any = res;
          if (response.error) {
            this.router.navigateByUrl('/login')
            return false;
          }
          this.api.logged = true
          this.api.setUser(response)
          return true;
        });
    } catch (err) {
      await this.router.navigateByUrl('/login')
    }
  }

}
