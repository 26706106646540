import {Component, OnInit} from '@angular/core';
import {Api} from '../../services/api.service';
import {LegacyPlans, MembershipName, Memberships} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import * as _ from 'lodash';
import {DEFAULT_WHITELABEL, findWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'new-navigation',
  templateUrl: './new-navigation.component.html',
  styleUrls: ['./new-navigation.component.css']
})
export class NewNavigationComponent implements OnInit {
  premiumPlusSet: Memberships[];
  premiumSet: Memberships[];
  whitelabel_config: any;
  default_partner = DEFAULT_WHITELABEL;

  constructor(public api: Api, private route: ActivatedRoute) {
    this.premiumPlusSet = [Memberships.premiumPlus, Memberships.premiumPlusTrial];
    this.premiumSet = _.concat([Memberships.premium, Memberships.premiumTrial], LegacyPlans);
  }

  ngOnInit() {
    this.whitelabel_config = findWhiteLabel(DEFAULT_WHITELABEL);
    localStorage.setItem('favicon',this.whitelabel_config.logos.favicon);
    localStorage.setItem('title',this.whitelabel_config.name);
    this.route.params.subscribe(params => {
      if(params.partner_id){
        this.whitelabel_config = findWhiteLabel(params.partner_id);
        localStorage.setItem('favicon',this.whitelabel_config.logos.favicon);
        localStorage.setItem('title',this.whitelabel_config.name);
      }
    });

    this.api.user$.subscribe(user =>{
      if(user){
        this.whitelabel_config = findWhiteLabel(user.partnerId);
        localStorage.setItem('favicon',this.whitelabel_config.logos.favicon);
        localStorage.setItem('title',this.whitelabel_config.name);
      }
    });
  }


  checkCTA(): string {
    const mem = _.get(this.api, 'user.membership');
    if (mem === Memberships.free) {
      return 'Try Premium';
    } else if (_.includes(this.premiumSet, mem)) {
      return 'Try Premium Plus';
    }
    else {
      return '';
    }
  }

  membershipLogo(): string {
    if (_.includes(this.premiumPlusSet, _.get(this.api, 'user.membership'))) {
      return 'premium-plus';
    } else if (_.includes(this.premiumSet, _.get(this.api, 'user.membership'))) {
      return 'premium';
    } else {
      return 'guest';
    }
  }

  getNavLogo(): string {
    if (_.includes(this.premiumPlusSet, _.get(this.api, 'user.membership'))) {
      return this.whitelabel_config.logos[MembershipName.premiumPlus];
    } else if (_.includes(this.premiumSet, _.get(this.api, 'user.membership'))) {
      return this.whitelabel_config.logos[MembershipName.premium];
    } else {
      return this.whitelabel_config.logos.main;
    }
  }

}
