import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {HttpModule} from '@angular/http';
import {AppComponent} from './app.component';
import {InterfaceComponent} from './components/interface/interface.component';
import {Api} from './services/api.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './components/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {PasswordrecoverComponent} from './components/passwordrecover/passwordrecover.component';
import {PasswordresetComponent} from './components/passwordreset/passwordreset.component';
import {LatestdealsComponent} from './components/latestdeals/latestdeals.component';
import {AutocompleterComponent} from './components/autocompleter/autocompleter.component';
import {DealComponent} from './components/deal/deal.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {SupportComponent} from './components/support/support.component';
import {ProfileComponent} from './components/profile/profile.component';
import {StaticFooterComponent} from './components/static-footer/static-footer.component';
import {NewNavigationComponent} from './components/new-navigation/new-navigation.component';
import {CustomscriptsComponent} from './components/customscripts/customscripts.component';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import {PerksComponent} from './components/perks/perks.component';
import {CheckoutComponent} from './components/checkout/checkout.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OnboardingComponent} from './components/onboarding/onboarding.component';
import {CancelModalComponent} from './components/shared/cancel-modal/cancel-modal.component';
import {UpdateCardComponent} from './components/shared/update-card/update-card.component';
import {VerticalNavComponent} from './components/vertical-nav/vertical-nav.component';
import {SplashBgComponent} from './components/splash-bg/splash-bg.component';
import {MenuBarComponent} from './components/menu-bar/menu-bar.component';
import {NgxMaskModule} from 'ngx-mask';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {HttpClientModule} from '@angular/common/http';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {CaptureFormComponent} from './components/capture-form/capture-form.component';
import {CountdownBannerComponent} from './components/countdown-banner/countdown-banner.component';
import {DepartureComponent} from './components/departure/departure.component';
import {RouterModule} from '@angular/router';
import {DreamDestinationComponent} from './components/dream-destination/dream-destination.component';
import {LoggedInGuard} from './guards/logged-in-guard.service';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {CanDeactivateGuard} from './guards/can-deactivate.guard';
import {TravelProductComponent} from './components/travel-product/travel-product.component';
import {MarketingRowComponent} from './components/marketing-row/marketing-row.component';
import {UpgradeComponent} from './components/upgrade/upgrade.component';
import {DfcModalComponent} from './components/dfc-modal/dfc-modal.component';
import {PerksProductComponent} from './components/perks-product/perks-product.component';
import {EmailModalComponent} from './components/email-modal/email-modal.component';
import {SentryErrorHandler} from './app-error-handler';
import {PerkDetailsComponent} from './components/perk-details/perk-details.component';
import {WhitelabelResolver} from './services/whitelabel';


@NgModule({
  declarations: [
    AppComponent,
    InterfaceComponent,
    LoginComponent,
    DashboardComponent,
    PasswordrecoverComponent,
    PasswordresetComponent,
    LatestdealsComponent,
    AutocompleterComponent,
    DealComponent,
    PaginationComponent,
    SupportComponent,
    ProfileComponent,
    StaticFooterComponent,
    NewNavigationComponent,
    CustomscriptsComponent,
    PerksComponent,
    CheckoutComponent,
    OnboardingComponent,
    CancelModalComponent,
    UpdateCardComponent,
    VerticalNavComponent,
    SplashBgComponent,
    MenuBarComponent,
    MenuBarComponent,
    DateFormatPipe,
    SignUpComponent,
    CaptureFormComponent,
    CountdownBannerComponent,
    DepartureComponent,
    DreamDestinationComponent,
    SubscribeComponent,
    TravelProductComponent,
    MarketingRowComponent,
    UpgradeComponent,
    DfcModalComponent,
    PerksProductComponent,
    EmailModalComponent,
    PerkDetailsComponent
  ],
  entryComponents: [EmailModalComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    NgbModule,
    NgxSmartModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    RouterModule.forRoot([
        {
          path: 'dashboard',
          component: DashboardComponent,
          canActivate: [LoggedInGuard],
          children: [
            {
              path: 'deals/:id',
              component: DealComponent,
              canActivate: [LoggedInGuard]
            }
          ]
        },
        {
          path: 'checkout',
          component: CheckoutComponent,
          canActivate: [LoggedInGuard]
        },
        {
          path: 'onboarding',
          component: OnboardingComponent,
          canActivate: [LoggedInGuard]
        },
        {
          path: 'support',
          component: SupportComponent,
          canActivate: [LoggedInGuard]
        },
        {
          path: 'profile/:section',
          component: ProfileComponent,
          canActivate: [LoggedInGuard]
        },
        {
          path: 'profile',
          component: ProfileComponent,
          canActivate: [LoggedInGuard]
        },
        {
          path: 'login/:hash',
          component: LoginComponent
        },
        {
          path: 'login',
          component: LoginComponent
        },
        {
          path: ':partner_id/login',
          component: LoginComponent
        },
        {
          path: 'recover',
          component: PasswordrecoverComponent
        },
        {
          path: 'reset/:token',
          component: PasswordresetComponent
        },
        {
          path: 'perks',
          children: [
            {
              path: '',
              component: PerksComponent,
              canActivate: [LoggedInGuard]
            },
            {
              path: ':name',
              component: PerkDetailsComponent,
              canActivate: [LoggedInGuard]
            }
          ]
        },
        {
          path: 'concierge',
          children: [
            {
              path: '',
              component: TravelProductComponent
            },
            {
              path: ':partner_id',
              component: TravelProductComponent
            }
          ]
        },
        {
          path: 'upgrade',
          children: [
            {
              path: '',
              component: UpgradeComponent
            },
            {
              path: ':partner_id',
              component: UpgradeComponent,
            },
          ]
        },
        {
          path: 'dfc-modal',
          component: DfcModalComponent
        },
        {
          path: 'signup',
          children: [
            {
              path: '',
              component: SignUpComponent,
              resolve: {
                whitelabel: WhitelabelResolver
              }
            },
            {
              path: 'departure',
              component: DepartureComponent,
              canDeactivate: [CanDeactivateGuard],
              canActivate: [LoggedInGuard],
              resolve: {
                whitelabel: WhitelabelResolver
              }
            },
            {
              path: 'dream-destination',
              component: DreamDestinationComponent,
              canDeactivate: [CanDeactivateGuard],
              canActivate: [LoggedInGuard],
              resolve: {
                whitelabel: WhitelabelResolver
              }
            },
            {
              path: 'go-premium',
              component: SubscribeComponent,
              canDeactivate: [CanDeactivateGuard],
              canActivate: [LoggedInGuard],
              resolve: {
                whitelabel: WhitelabelResolver
              }
            },
            {
              path: ':partner_id',
              component: SignUpComponent,
              resolve: {
                whitelabel: WhitelabelResolver
              }
            },
          ]
        },
      {
        path: 'partner-perks',
        children: [
          {
            path: '',
            component: PerksProductComponent
          },
          {
            path: ':partner_id',
            component: PerksProductComponent
          }
        ]
      },
        {
          path: '**',
          redirectTo: "dashboard"
        },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 0]
      }
    )
  ],
  providers: [
    Api,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


