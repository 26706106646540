import { OnInit, EventEmitter } from '@angular/core';
var PaginationComponent = /** @class */ (function () {
    function PaginationComponent() {
        this.navigationEvent = new EventEmitter();
        this.current = 0;
        this.pagesList = [];
    }
    Object.defineProperty(PaginationComponent.prototype, "page", {
        set: function (page) {
            this.current = page;
            this.start = (page * this.size) + 1;
            this.end = Math.min(this.start + this.size - 1, this.start + (this.results - (page * this.size) - 1));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationComponent.prototype, "pages", {
        set: function (pages) {
            this.pagesList = [];
            if (pages > 0) {
                var start = Math.max(1, this.current - 3);
                var end = (pages <= 6) ? pages : Math.max(6, Math.min(pages, this.current + 3));
                for (var i = start; i <= end; i++) {
                    this.pagesList.push(i);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PaginationComponent.prototype.ngOnInit = function () {
    };
    PaginationComponent.prototype.goToPage = function (page) {
        this.navigationEvent.emit(page);
    };
    return PaginationComponent;
}());
export { PaginationComponent };
