<div *ngIf="!checkoutSuccess; else successPage" >
<nav class="navbar container-fluid banner-color py-2" xmlns="http://www.w3.org/1999/html">
  <div class="row mx-auto">
    <div class="col-12 text-center">
        <span class="text-white fs-14 font-weight-bold">
          Exclusive offer: Get 93% off DFC Concierge for life before prices go up. 🔥
        </span>
    </div>
  </div>
</nav>
<div class="container-contact100 overflow-hidden">
  <div class="contact100-more flex-col-c-m">
    <div class="container-fluid pl-md-4 pt-md-3 pl-xl-5 pt-xl-4">
      <div class="row position-relative">
        <div class="col-sm-9 col-md-8 col-lg-7 col-xl-5 text-center text-sm-left">
          <img class="img-responsive dfc-logo mb-0" src="https://dfcapp.s3.amazonaws.com/Concierge+Logo+11202023+-+Dollar+Flight+Club+DFC+Media.png">
          <div *ngIf="!checkoutSuccess">
            <h1 class="mt-4 mt-sm-5 pr-sm-2">
              Save $2,000 and 20 hours of your time on every vacation.
            </h1>
            <h4 class="mt-4 pr-sm-3 sub-txt">Get Lifetime access to an expert travel planner, dedicated to making every vacation you take the best one of your life.</h4>
            <div class="position-relative">
              <button class="btn btn-lg concierge-btn rounded-pill text-white mt-4"
                      (click)="open()"
              >
                Try Concierge
              </button>
              <img class="d-none d-md-block position-absolute arrow-position-3" src="https://dfcapp.s3.amazonaws.com/White+Squiggle+Line.png">
            </div>
            <span class="d-block mt-2 mt-sm-3" style="font-size: small; margin-bottom: 3rem;">*3-day money back guarantee.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-white">
  <div class="container-fluid cards-container justify-content-center">
    <app-marketing-row [mainTitle]="rowContent.row1.mainTitle"
                       [highlightTitle]="rowContent.row1.highlightTitle"
                       [highlightClass]="highlightClass"
                       [paragraphs]="rowContent.row1.paragraphs"
                       [paragraphClass]="paragraphClass"
                       [imgSrc]="rowContent.row1.imgSrc"
    >
    </app-marketing-row>
  </div>
  <div class="grey-bg">
    <div class="container-fluid cards-container">
      <h1 class="go-premium-txt text-center">
        Get a customized trip like these planned, every year...
      </h1>
      <div class="row mt-5 justify-content-start mx-auto">
        <div class="col-sm-6 col-lg pl-sm-0 pr-sm-4 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-greece.png">
            <div class="sm-card-text">
              <p>Greece - 10 Days</p>
              <p style="text-decoration: line-through">$5,544</p>
              <p>$2,500 (with flights)</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-peru.png">
            <div class="sm-card-text">
              <p>Peru - 8 Days</p>
              <p style="text-decoration: line-through">$3,944</p>
              <p>$1,900 (with flights)</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg pl-sm-0 pr-sm-4 px-lg-0 mt-4 mt-lg-0 pr-lg-4 pr-xl-5">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-switzerland.png">
            <div class="sm-card-text">
              <p>France - 5 Days </p>
              <p style="text-decoration: line-through">$4,544</p>
              <p>$2,100 (with flights)</p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg pl-sm-4 pr-sm-0 px-lg-0 mt-4 mt-lg-0">
          <div class="sm-card">
            <img class="sm-img" src="https://dfcmedia.s3.amazonaws.com/email-assets/card-3-thailand.png">
            <div class="sm-card-text">
              <p>Bali - 10 Days</p>
              <p style="text-decoration: line-through">$3,200</p>
              <p>$1,700 (with flights)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid cards-container justify-content-center">
    <app-marketing-row [mainTitle]="rowContent.row2.mainTitle"
                       [highlightTitle]="rowContent.row2.highlightTitle"
                       [highlightClass]="highlightClass"
                       [paragraphs]="rowContent.row2.paragraphs"
                       [paragraphClass]="paragraphClass"
                       [imgSrc]="rowContent.row2.imgSrc"
    >
    </app-marketing-row>
    <div class="mt-2">
      <app-marketing-row [mainTitle]="rowContent.row3.mainTitle"
                         [highlightTitle]="rowContent.row3.highlightTitle"
                         [highlightClass]="highlightClass"
                         [paragraphs]="rowContent.row3.paragraphs"
                         [paragraphClass]="paragraphClass"
                         [imgSrc]="rowContent.row3.imgSrc"
                         [flippedRow]="true"
      >
      </app-marketing-row>
    </div>
  </div>
  <div class="grey-bg position-relative overflow-hidden">
    <img class="position-absolute arrow-position-1 w-25" src="https://dfcapp.s3.amazonaws.com/arrow-2.png">
    <img class="https://dfcapp.s3.amazonaws.com/arrow-2.png">
    <div class="container-fluid cards-container pt-5">
      <div class="row">
        <div class="col-12">
          <h1 class="text-center">
            You're in Good Company...
          </h1>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-5">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-1 float-left">
              <p class="font-weight-bold ">Leslie W.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
               “DFC Concierge took all of the stress out of planning my bachelorette party in Nashville! Wedding planning is already stressful, but Concierge made planning so easy. I requested the cheapest flight available and only spent $65 roundtrip from Pittsburgh.”
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-5">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-2 float-left">
              <p class="font-weight-bold">Noah M.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                “I wanted to surprise my wife with a romantic anniversary trip to Paris, but I didn’t know where to start. Luckily, DFC Concierge took care of everything for me. They even recommended a restaurant for our anniversary dinner that was perfect - my wife was so impressed! Thanks, DFC Concierge!”
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-3 float-left">
              <p class="font-weight-bold">Rachel S.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
                “DFC Concierge saved me so much time and money on my recent trip. I was able to save $300 on my flight to Lima, which more than covered the cost of the service. Plus, the team was so helpful and responsive throughout the planning process. Highly recommend!”
              </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-4">
          <div class="row">
            <div class="col-3 col-sm-2 col-md-3">
              <img class="testimonial-img testimonial-4 float-left">
              <p class="font-weight-bold">Richard R.</p>
            </div>
            <div class="col-9 col-sm-10 col-md-9 pl-3">
              <div class="testimonial-bg p-3 p-md-4">
                <span>
               “So happy that I got to specify certain hotels and airlines so that I could use points to book my travel! Had a fantastic weekend in New York.”
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid cards-container justify-content-center">
    <app-marketing-row [mainTitle]="rowContent.row4.mainTitle"
                       [highlightTitle]="rowContent.row4.highlightTitle"
                       [highlightClass]="highlightClass"
                       [paragraphs]="rowContent.row4.paragraphs"
                       [paragraphClass]="paragraphClass"
                       [imgSrc]="rowContent.row4.imgSrc"
    >
    </app-marketing-row>
  </div>
</div>
</div>

<ng-template #successPage>
  <div class="contact100-more ">
          <div class=" text-center text-sm-left success-content">
            <img class="img-responsive dfc-logo success-logo success-logo mb-0" src="https://dfcapp.s3.amazonaws.com/Concierge+Logo+11202023+-+Dollar+Flight+Club+DFC+Media.png">
            <div>
              <h1 class="success-text">
                We can’t wait to help you plan your next amazing trip!

              </h1>
              <h6 class="success-text" style="margin-bottom: 2rem;">
                <p>To get started click the button below. If you don’t want to start planning a trip today, don’t worry because we’ll also send you an email with the details!</p>

                <p>Our team of experienced travel experts is ready to guide you every step of the way and create a customized travel plan tailored to your specific needs. At Concierge, we believe that travel is not just about the destination, but about the journey itself, and we’re looking forward to many journeys with you!</p>

                <a href="https://form.typeform.com/to/I2235iq7" rel="noreferrer, noopener" target="_blank"><button class="btn btn-lg concierge-btn rounded-pill text-white mt-4">Get Started</button></a>
              </h6>
            </div>
          </div>

  </div>
</ng-template>
<div class="faq-bg position-relative overflow-hidden">
  <img class="position-absolute star-position w-25" src="https://dfcapp.s3.amazonaws.com/star.png">
  <div class="container-fluid cards-container mt-5 pt-0">
    <h1 class="text-center" id="faq">FAQs</h1>
    <div class="row mt-5">
      <div class="col-12 faq-border border-dark pt-4 pb-2 mt-3">
        <h5 class="faq-header">What can I expect from DFC Concierge? <span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse1">
           <i *ngIf="isCollapsed" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p class="collapse-txt" [ngbCollapse]="isCollapsed">DFC Concierge provides personalized travel planning services to help you plan your dream vacation. Our team of travel experts uses our proprietary technology to find the best deals on flights and hotels and create custom itineraries tailored to your preferences and budget.
          <br>
          <br>
          After you sign up we will send you a welcome email with more information. Whenever you are ready to request your trip you can fill out the survey linked in the email and we will start planning your adventure (we prioritize itineraries based on travel dates and ask that members submit their request a minimum of 4 weeks prior to their trip).
          <br>
          <br>
          Please reach out to us at concierge@dollarflightclub.com if you have any questions.
        </p>
      </div>
      <div class="col-12 faq-border border-dark pt-3 pb-2">
        <h5 class="faq-header">How often can I request a custom trip itinerary? <span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="!isCollapsed1" aria-controls="collapse2">
           <i *ngIf="isCollapsed1" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed1" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p  class="collapse-txt" [ngbCollapse]="isCollapsed1">As a DFC Concierge Lifetime member, your membership includes 1 custom trip itinerary per year to help you make that dream trip unforgettable. That means you’ll receive 1 custom trip itinerary per year for life.
          <br>
          <br>
          For any additional custom trip itineraries within a year period, the cost is $15/day. Please reach out to us at concierge@dollarflightclub.com if you would like a second itinerary within a year period. Whether you're planning a quick weekend getaway or a month-long adventure, our team is here to help.
        </p>
      </div>
      <div class="col-12 faq-border border-dark pt-3 pb-2">
        <h5 class="faq-header">How long of a trip can you plan for? <span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="!isCollapsed2" aria-controls="collapse3">
           <i *ngIf="isCollapsed2" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed2" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p class="collapse-txt" [ngbCollapse]="isCollapsed2">Trips between 2 and 10 days long are included in your lifetime membership, whether you're looking for a quick weekend getaway or a 10-day adventure we'll create a custom itinerary tailored to your preferences and budget.
          <br>
          <br>
          If you’d like a trip itinerary longer than 10 days there is an additional cost of $15/a day. If you would like an itinerary longer than 10 days just let us know after you submit your trip survey by emailing us at concierge@dollarflightclub.com.
          <br>
          <br>
          If your trip is, for example, 15 days long but you want us to only plan 10 of the days we can do that at no extra cost.
        </p>
      </div>
      <div class="col-12 faq-border border-dark pt-3 pb-2">
        <h5 class="faq-header">Where can you plan trips? <span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="!isCollapsed3" aria-controls="collapse4">
           <i *ngIf="isCollapsed3" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed3" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p class="collapse-txt" [ngbCollapse]="isCollapsed3">We can plan trips to destinations all over the world, from popular tourist spots to off-the-beaten-path destinations. Just let us know where you want to go and we'll take care of the rest.</p>
      </div>
      <div class="col-12 faq-border border-dark pt-3 pb-2">
        <h5 class="faq-header">Can you plan around different budgets? <span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed4 = !isCollapsed4" [attr.aria-expanded]="!isCollapsed4" aria-controls="collapse5">
           <i *ngIf="isCollapsed4" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed4" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p class="collapse-txt" [ngbCollapse]="isCollapsed4">Yes, our team is experienced in planning trips for all types of budgets. We'll work with you to find the best deals on flights, hotels, and activities to fit your budget. Please note, flight prices change all the time and some routes/dates can be expensive. We will do our best to stay within your budget but if there are no flights within your budget we will provide the best options available.</p>
      </div>
      <div class="col-12 faq-border border-dark pt-3 pb-2">
        <h5 class="faq-header">Are flights, hotels, or activity costs included in my membership fee? <span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed5 = !isCollapsed5" [attr.aria-expanded]="!isCollapsed5" aria-controls="collapse6">
           <i *ngIf="isCollapsed5" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed5" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p class="collapse-txt" [ngbCollapse]="isCollapsed5">No, your membership fee only covers the cost of the planning services. However, our team will work with you to find the best deals on flights, hotels, and activities to fit your budget.</p>
      </div>
      <div class="col-12 faq-border border-dark pt-3 pb-2">
        <h5 class="faq-header">Can I change my preferences for a trip itinerary? <span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed6 = !isCollapsed6" [attr.aria-expanded]="!isCollapsed6" aria-controls="collapse7">
           <i *ngIf="isCollapsed6" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed6" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p class="collapse-txt" [ngbCollapse]="isCollapsed6">You are able to have 1 round of feedback so that we can adjust your itinerary to make it perfect.</p>
      </div>
      <div class="col-12 faq-border border-dark pt-3 pb-2">
        <h5 class="faq-header">How far in advance do I need to submit my trip request?<span class="float-right" ><button type="button" class="collapse-btn" (click)="isCollapsed7 = !isCollapsed7" [attr.aria-expanded]="!isCollapsed7" aria-controls="collapse7">
           <i *ngIf="isCollapsed7" class="fa fa-thin fa-chevron-down"></i>
          <i *ngIf="!isCollapsed7" class="fa fa-thin fa-chevron-up"></i>
          </button></span></h5>
        <p class="collapse-txt" [ngbCollapse]="isCollapsed7">Please submit your trip itinerary request at least 4 weeks before your trip takes place. We cannot accommodate itinerary requests for trips that are happening within the next 4 weeks.
          <br>
          <br>
          We prioritize itineraries based on travel dates. For example, if we receive an itinerary request for a trip that is happening in 5 weeks we will complete that itinerary before we work on an itinerary request for a trip that is happening next year.
          <br>
          <br>
          Our team puts a lot of effort into making itineraries that fit your needs so please be patient while we make your trip perfect. Feel free to reach out to us at concierge@dollarflightclub.com if you have any questions about your itinerary status.</p>
      </div>
    </div>
  </div>
</div>
