import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {Integrations} from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://533770fc06598ab8b8710054cfcfeb63@o4507408409952256.ingest.us.sentry.io/4507408563175424',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['dev.app.dollarflightclub.com', 'app.dollarflightclub.com', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
