import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Api, TrialCodes} from '../../services/api.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Membership, MembershipName, Memberships, MembershipSpec} from '@dollar-flight-club/shared_utilities/lib/models/Membership';
import {DEFAULT_WHITELABEL, findWhiteLabel, IWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {UserModel} from '../../models/Authentication';
import {CheckoutComponent} from '../checkout/checkout.component';
import {environment} from '../../../environments/environment';
import {tap} from 'rxjs/operators';

declare var vgo: any;
declare var r: any;

@Component({
  selector: "app-upgrade",
  templateUrl: "./upgrade.component.html",
  styleUrls: ["./upgrade.component.css"]
})
export class UpgradeComponent implements OnInit {
  @ViewChild("email") emailInput: ElementRef;
  @ViewChild('checkout') checkout: ElementRef;

  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  membership: MembershipSpec;
  lifetime: boolean;
  nextMembership: MembershipSpec;
  emailInputRef: NgbModalRef;
  checkMembership: any;
  membershipNames: typeof MembershipName;
  benefits: string[];
  premiumSet: Memberships[];
  captureForm: FormGroup;
  whitelabel_config: IWhiteLabel;
  currentCustomer: boolean;
  premiumPlusUpgrade: boolean;
  user: UserModel
  cantUpgrade: boolean = false
  error: string;
  discount: string;
  emailValidationActive = false;
  coupon: any;
  partnerId: string;
  rfsn: boolean = false
  code: TrialCodes;
  utm_source: string;
  utm_medium: string;
  trafficSource: string;

  highlightClass = 'hightlight-blue';
  paragraphClass = 'pr-lg-5';
  rowContent = {
    row1: {
      mainTitle: 'GET ALL THE',
      highlightTitle: 'DEALS',
      paragraphs: [
        'Premium members get ALL of the deals we find leaving their selected airports...that means all the mistake fares and highly discounted international and domestic deals from up to 4 selected departure airports. If you\'re serious about travel Premium is a must have.'
      ],
      imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-all-the-deals.png'
    },
    row3: {
      mainTitle: 'MULTIPLE',
      highlightTitle: 'AIRPORTS',
      paragraphs: [
        'Life is all about options. With Premium you can choose up to 4 departure airports that you want to get deals from. This is perfect for digital nomads or someone with a few good airports in their proximity.'
      ],
      imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-multiple-airports.png'
    },
    row4: {
      mainTitle: 'MISTAKE',
      highlightTitle: 'FARES',
      paragraphs: [
        'How does flying First Class to Europe for $129 roundtrip sound? Airlines make pricing mistakes, we find these errors, email you about them, and help you save $2,000+ on these happy little accidents. Premium members are the only members that get access to these deals.'
      ],
      imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-mistake-fares.png'
    },
    row5: {
      mainTitle: 'AD-FREE',
      highlightTitle: 'DEALS',
      paragraphs: [
        'We get it....advertisements can be annoying. With Premium you\'ll be add free. No more 3rd party advertisements trying to sell you things.'
      ],
      imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/card-ad-free-deals.png'
    },
    row6: {
      mainTitle: 'REAL',
      highlightTitle: 'ADVENTURES',
      paragraphs: [
        'You can be our next success story, like Paula C., who got tickets to New Zealand for $250. She saved $1,800+ on roundtrip tickets and so can you. Our 1,000,000+. members have saved tens of millions on airfare.'
      ],
      imgSrc: 'https://dfcmedia.s3.amazonaws.com/email-assets/email-1.png'
    }
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              public api: Api,
              private modalService: NgbModal,
              private formBuilder: FormBuilder) {
    this.captureForm = formBuilder.group({
      email: ["", [Validators.required, Validators.email,
        Validators.pattern("^[a-z0-9A-Z]+[a-zA-Z0-9.\\-\\_]*@[a-zA-Z0-9.\\-\\_]+.[a-z0-9A-Z]{2,}$")]
      ]
    });
    this.premiumSet = [Memberships.premium, Memberships.premiumTrial];
    this.membershipNames = MembershipName;
    this.nextMembership = Membership.getNextPlanTier(Memberships.free);
  }

  ngOnInit() {
    this.api.addInitCallback(async () => {
        const params = this.route.snapshot.queryParams;
        const whitelabelId = this.api.getWhiteLabelId(this.route.snapshot.params.partner_id, params.lifetime)
        this.whitelabel_config = findWhiteLabel(whitelabelId)
        this.partnerId = this.whitelabel_config.id
        const environmentVar = environment.production ? 'production_plans' : 'development_plans'
        if(this.whitelabel_config.stripe[environmentVar].premium_plus.invoice_item || params.lifetime){
          this.lifetime = true
        }
        this.utm_source = params.utm_source;
        this.utm_medium = params.utm_medium;
        this.trafficSource = params.source;
        if (params.email) {
          this.api.userEmail = params.email;
          this.user = await this.api.checkMembership(params.email) as UserModel;
          this.membership = Membership.getMembershipSpec(this.user.membershipId);
          await this.setUserMembership();
          this.setACEmail();
        }
        if(this.whitelabel_config){
        this.premiumPlusUpgrade = this.whitelabel_config.signup.premiumPlus_offer || this.membership.basePlan === MembershipName.premium || this.lifetime;
        }
      if (params.rfsn) {
          this.rfsn = true
        }
      if (params.coupon) {
        this.coupon = params.coupon;
        this.api.checkCoupon(this.coupon).pipe(
          tap(response => {
            if (response && response.error) {
              console.error('Error checking coupon:', response.message);
              this.discount = '';
            } else {
              this.discount = response.percent_off ? `${response.percent_off}%` : `$${response.amount_off / 100}`;
            }
          })
        ).subscribe();
      }
        if(params.code){
          this.code = this.trialCode
        }
        if(this.lifetime && this.rfsn && !this.user.cartId){
          const cartId =  this.api.genUniqueId()
          await this.api.updateCartId(this.user.id, cartId)
          const rfsn = {
            cart: cartId,
            id: localStorage.getItem("rfsn_v4_id"),
            url: window.location.href,
            aid: localStorage.getItem("rfsn_v4_aid"),
            cs: localStorage.getItem("rfsn_v4_cs")
          }
          r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs)
        }
    })
    var deadline = new Date(); //today
    deadline.setHours(0, 0, 0, 0);
    deadline.setDate(deadline.getDate() + 1);
    setInterval(() => this.setTimeRemaining(deadline), 1000);
  }


  async closeEmailModal() {
    this.user = await this.api.checkMembership(this.captureForm.controls.email.value) as UserModel;
    await this.setUserMembership();
    if (this.user) {
      this.api.userEmail = this.captureForm.controls.email.value;
      this.membership = Membership.getMembershipSpec(this.user.membershipId);
      this.setACEmail();
      this.checkMembership = {
        message: `<i class="fa fa-check-circle" aria-hidden="true"></i> Great to see you again!`,
        class: 'alert alert-success m-t-10 m-b-10'
      };
      setTimeout(async () => {
        this.emailInputRef.close();
        if (this.membership.basePlan !== MembershipName.premiumPlus && !this.lifetime) {
          await this.checkoutModal();
        }
      }, 2000);
    } else {
      this.checkMembership = {
        message: `This email is not associated with a DFC account.<br/>Please try again or create a new account.`,
        class: 'alert alert-danger m-t-10 m-b-10',
        CTA: 'Create new account'
      }
    }
  }

  setACEmail(): void {
    vgo('setEmail', this.api.userEmail);
    vgo('process');
    console.log("Active campaigns tracked", this.api.userEmail);
  }

  setTimeRemaining(endtime: Date) {
    var d = new Date();
    var t = Date.parse(endtime.toString()) - Date.parse(d.toString());
    if (t < 0) {
      return false;
    }
    this.seconds = Math.floor((t / 1000) % 60);
    this.minutes = Math.floor((t / 1000 / 60) % 60);
    this.hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    this.days = Math.floor(t / (1000 * 60 * 60 * 24));
  }

  async checkoutModal() {
    if (this.user && this.currentCustomer) {
      const checkout = this.modalService.open(CheckoutComponent)
      const component = checkout.componentInstance as CheckoutComponent
      component.lifetime = this.lifetime
      component.coupon = this.coupon
      component.user = this.user
      component.partnerId = this.partnerId
      component.rfsn = this.rfsn
      component.onboardingRedirect = true
      component.trialCode = this.code
      component.location = 'Upgrade Page'
      component.utm_source = this.utm_source;
      component.utm_medium = this.utm_medium;
      component.trafficSource = this.trafficSource;
    } else {
      this.emailInputRef = this.modalService.open(this.emailInput, {
        centered: true
      });
    }
  }

  public async setUserMembership(): Promise<void> {
    if (this.user.partnerId === 'lifetimepremiumplus' || !this.lifetime && this.user.membershipId > Memberships.premium) {
      this.cantUpgrade = true
    }
    if (this.user.membershipId) {
      this.currentCustomer = true;
    } else {
      this.currentCustomer = false;
    }
    localStorage.setItem('favicon', this.whitelabel_config.logos.favicon);
    localStorage.setItem('title', this.whitelabel_config.name);
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      if (this.captureForm.controls.email.valid) {
        this.api.userEmail = this.captureForm.controls.email.value;
        this.setACEmail();
      } else {
        this.emailValidationActive = true;
      }
    }
  }


  get trialCode(){
    const code = this.route.snapshot.queryParams['code'];
    if(Object.values(TrialCodes).includes(code)){
      return code;
    }
    return null;
  }

}
