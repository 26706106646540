/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-bar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../countdown-banner/countdown-banner.component.ngfactory";
import * as i5 from "../countdown-banner/countdown-banner.component";
import * as i6 from "./menu-bar.component";
import * as i7 from "../../services/api.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
var styles_MenuBarComponent = [i0.styles];
var RenderType_MenuBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuBarComponent, data: {} });
export { RenderType_MenuBarComponent as RenderType_MenuBarComponent };
function View_MenuBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MenuBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col-md-12 col-sm-12 text-right mb-2 pr-0"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["class", "nav-item pointer"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["aria-hidden", "true"], ["class", "svg-inline--fa fa-question-circle fa-w-16 i-md"], ["data-icon", "question-circle"], ["data-prefix", "fal"], ["focusable", "false"], ["role", "img"], ["viewBox", "0 0 512 512"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:path", [["d", "M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z"], ["fill", "currentColor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", "nav-item pointer ml-4"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-lg fa-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "nav-item pointer ml-4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "img", [["class", "logout-icon"], ["src", "https://dfcmedia.s3.amazonaws.com/email-assets/logout.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "col-md-12 col-sm-12 text-right mb-2 pr-0"; var currVal_1 = (_co.displaySalesBanner ? "mt-4" : "mt-3"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _ck(_v, 4, 0, "/support"); _ck(_v, 3, 0, currVal_4); var currVal_7 = _ck(_v, 9, 0, "/profile/account"); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 3).target; var currVal_3 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 8).target; var currVal_6 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_MenuBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-countdown-banner", [], null, null, null, i4.View_CountdownBannerComponent_0, i4.RenderType_CountdownBannerComponent)), i1.ɵdid(1, 114688, null, 0, i5.CountdownBannerComponent, [], { banner_txt: [0, "banner_txt"], displaySalesBanner: [1, "displaySalesBanner"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "fixed-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuBarComponent_1)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, [["accountActions", 2]], null, 0, null, View_MenuBarComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.whitelabel_config == null) ? null : _co.whitelabel_config.members_dashboard.banner_text); var currVal_1 = (((_co.whitelabel_config == null) ? null : _co.whitelabel_config.members_dashboard.banner) && !_co.isPremiumUser); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_MenuBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "menu-bar", [], null, null, null, View_MenuBarComponent_0, RenderType_MenuBarComponent)), i1.ɵdid(1, 114688, null, 0, i6.MenuBarComponent, [i3.Router, i7.Api, i8.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuBarComponentNgFactory = i1.ɵccf("menu-bar", i6.MenuBarComponent, View_MenuBarComponent_Host_0, {}, {}, []);
export { MenuBarComponentNgFactory as MenuBarComponentNgFactory };
