import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IWhiteLabel} from '@dollar-flight-club/shared_utilities/lib/utils/whitelabel';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Api} from '../../services/api.service';
import {UserModel} from '../../models/Authentication';
import {CheckoutComponent} from '../checkout/checkout.component';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {
  @ViewChild('checkout') checkout: ElementRef;
  @ViewChild('basic') basic: ElementRef;

  basicRef: NgbModalRef;
  whitelabelConfig: IWhiteLabel;
  isSaved = false;
  rfsn: boolean = false
  user: UserModel;
  coupon: string;
  lifetime: boolean;
  partnerId: string;
  utm_source: string;
  utm_medium: string;
  trafficSource: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private api: Api,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    this.api.user$.subscribe(async user => {
      this.user = user
    });
    if (params.rfsn) {
      this.rfsn = true
    }
    this.whitelabelConfig = this.route.snapshot.data.whitelabel;
    this.partnerId = this.whitelabelConfig.id;
    const environmentVar = environment.production ? 'production_plans' : 'development_plans'
    if (this.whitelabelConfig.stripe[environmentVar].premium_plus.invoice_item) {
      this.lifetime = true
    }
    if (params.coupon) {
      this.coupon = params.coupon
    }
    this.utm_source = params.utm_source;
    this.utm_medium = params.utm_medium;
    this.trafficSource = params.source;
      localStorage.setItem("rfsn_v4_id", localStorage.getItem("rfsn_v4_id")),
      localStorage.setItem("rfsn_v4_aid", localStorage.getItem("rfsn_v4_aid")),
      localStorage.setItem("rfsn_v4_cs", localStorage.getItem("rfsn_v4_cs"))
  }

  canDeactivate() {
    return !this.isSaved;
  }

  checkoutModal() {
    this.isSaved = true
    const checkout = this.modalService.open(CheckoutComponent)
    const component = checkout.componentInstance as CheckoutComponent
    component.lifetime = this.lifetime
    component.coupon = this.coupon
    component.user = this.user
    component.partnerId = this.partnerId
    component.rfsn = this.rfsn
    component.onboardingRedirect = true
    component.location = 'Signup - Subscribe'
    component.utm_source = this.utm_source;
    component.utm_medium = this.utm_medium;
    component.trafficSource = this.trafficSource;
    component.whitelabel_config = this.whitelabelConfig;
  }


  basicModal() {
    this.basicRef = this.modalService.open(this.basic, {size: 'lg', centered: true});
  }

  basicToCheckoutModal() {
    this.basicRef.close();
    this.checkoutModal();
  }

  basicModalClose() {
    this.basicRef.close();
  }

  goBasic() {
    this.isSaved = true;
    this.basicRef.close();
    return this.router.navigate(['/onboarding'], {
      queryParams: {
        onboard: true,
        plan: 'free'
      }
    });
  }
}
