<div class="row" *ngIf="loading">
  <div class="col-12 my-5 text-center">
    <img src="../../../assets/svgs/puff-loader.svg" style="width: 70px;"/>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-12 text-left" *ngIf="api.dealType == 1">
    <p class="bold">
      Latest domestic deals to make the most out of your weekends.</p>
    <hr />
  </div>
  <div class="col-12 text-center mt-3 mb-5" *ngIf="!this.api.departures || deals.length==0">
    <p class="text-center">
      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plane-slash"
        class="svg-inline--fa fa-plane-slash fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512" style="width: 35px;">
        <path fill="currentColor"
          d="M269.44,480H211.56l53.18-186.06L257.2,288H144L96,352H69.34l28-96-28-96H94.66L54,128H48a16,16,0,0,0-15.54,19.88L64,256,32.48,364.13A16,16,0,0,0,48,384h56a16,16,0,0,0,12.8-6.41L160,320h64L175,491.59A16,16,0,0,0,190.34,512H278.7a16,16,0,0,0,13.89-8.06l74.12-129.71-25.41-20ZM189.72,72l42.94,33.81L211.56,32h57.88L376.23,218.89l6.5,5.11H512c26.23,0,62.61,21.75,64,31.91C574.61,266.25,538.23,288,512,288H464l40.64,32H512c35.34,0,96-28.66,96-64s-60.66-64-96-64H397.7L292.61,8.06C290.06,3.61,283.84,0,278.72,0H190.34A16,16,0,0,0,175,20.39ZM637,485.32,23,1.8A7.88,7.88,0,0,0,11.77,3l-10,12.5A7.94,7.94,0,0,0,3,26.71L617,510.23A8,8,0,0,0,628.2,509l10-12.5A7.86,7.86,0,0,0,637,485.32Z">
        </path>
      </svg>
    </p>
    <p class="text-center" >
      Don't miss out on a deal to your dream destination!
    </p>
    <div >
      Please update your <a routerLink="/profile" routerLinkActive="current">departure airports.</a>
    </div>
  </div>
  <div [ngClass]="{'col-xl-4 col-12 ':!fullSize,'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12':fullSize}"
    class="text-center mb-5" *ngFor="let deal of deals" (click)="showDeal(deal)">
    <div class="cust-card card-shadow" [ngClass]="{'expired':deal.expired>259200}">
      <div class="imgdeal">
        <div class="cover">
          <img class="fresh" [ngClass]="{'fresh-deal':deal.expired<259200, 'd-none':deal.expired>259200}"
            src="https://dfcmedia.s3.amazonaws.com/email-assets/new.png">
        </div>
        <img style="object-fit: cover" [src]="getDealImage(deal)" />
      </div>
      <div class="deal-details">
        <div class="row">
          <div class="col-7">
            <p class="mb-2 ml-3 font-weight-bold h6" >
              {{deal.destination}}, {{deal.country}}
            </p>
            <span class="text-secondary ml-3" *ngIf="100>deal.discount>0">
              {{deal.discount.toFixed(0)}}%<br class="mb-br"><span class="xs-lp"> Off Roundtrip</span>
            </span>
          </div>
          <div class="col-5 pl-0">
            <div class="premium-plus-type pr-2" *ngIf="getDealRequiredMembershipType(deal) === membershipTypes.premiumPlus">
              <span class="tagline pr-1">PREMIUM+</span>
            </div>
            <div class="premium-type pr-3"
              *ngIf="getDealRequiredMembershipType(deal) === membershipTypes.premium">
              <span class="tagline">PREMIUM</span>
            </div>
            <br>
            <span class="price pr-3 pt-1">
              ${{deal.dep_price ? deal.dep_price.toFixed(0) : deal.price.toFixed(0)}}
              <br>
              <span class="pl-1 font14 d-contents">{{getFlightClassText(deal.flightClass)}}</span>
            </span>
          </div>
          <div class="col-12 mt-4 text-center">
            <button [ngClass]="'primary-btn-' + this.whitelabel_config?.css"
                    class="btn rounded-btn deal-btn"><span *ngIf="deal.isClickable">View Deal</span><span *ngIf="!deal.isClickable">Upgrade</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="clear-20"></div>
    <pagination *ngIf="viewAll && !loading" [content]="api.strings?.portal.deals" [size]="24" [results]="dealsCount"
      [page]="page" [pages]="pages" (navigation)="goToPage($event);">
    </pagination>
  </div>
</div>


<!-- SELECT REGION TEMPLATE-->
<!--TODO does this even work?-->
<!--Previous developer really oopsied here :( TODO: Fix this with JSON object -->
<ng-template #selectDeparture>
  <div>
    <ul class="profile" *ngIf="!api.premium">
      <li>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <label class="main-label">North America</label>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="North America - West"
                [checked]="api.user?.region=='North America - West'" (change)="region($event)">
              <label class="form-check-label">
                West
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="North America- Midwest"
                [checked]="api.user?.region=='North America- Midwest'" (change)="region($event)">
              <label class="form-check-label">
                Midwest
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="North America- Southwest"
                [checked]="api.user?.region=='North America- Southwest'" (change)="region($event)">
              <label class="form-check-label">
                Southwest
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="North America- Southeast"
                [checked]="api.user?.region=='North America- Southeast'" (change)="region($event)">
              <label class="form-check-label">
                Southeast
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="North America- Northeast"
                [checked]="api.user?.region=='North America- Northeast'" (change)="region($event)">
              <label class="form-check-label">
                Northeast
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="North America - Canada"
                [checked]="api.user?.region=='North America - Canada'" (change)="region($event)">
              <label class="form-check-label">
                Canada
              </label>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <label class="main-label">Europe</label>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Europe - Southern"
                [checked]="api.user?.region=='Europe - Southern'" (change)="region($event)">
              <label class="form-check-label">
                Southern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Europe - Northern"
                [checked]="api.user?.region=='Europe - Northern'" (change)="region($event)">
              <label class="form-check-label">
                Northern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Europe - Eastern"
                [checked]="api.user?.region=='Europe - Eastern'" (change)="region($event)">
              <label class="form-check-label">
                Eastern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Europe - Western"
                [checked]="api.user?.region=='Europe - Western'" (change)="region($event)">
              <label class="form-check-label">
                Western
              </label>
            </div>

          </div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <label class="main-label">Asia</label>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Asia - Northern"
                [checked]="api.user?.region=='Asia - Northern'" (change)="region($event)">
              <label class="form-check-label">
                Northern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Asia - Eastern"
                [checked]="api.user?.region=='Asia - Eastern'" (change)="region($event)">
              <label class="form-check-label">
                Eastern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Asia - Southeast"
                [checked]="api.user?.region=='Asia - Southeast'" (change)="region($event)">
              <label class="form-check-label">
                Southeast
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Asia - Southern"
                [checked]="api.user?.region=='Asia - Southern'" (change)="region($event)">
              <label class="form-check-label">
                Southern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Asia - Central"
                [checked]="api.user?.region=='Asia - Central'" (change)="region($event)">
              <label class="form-check-label">
                Central
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Asia - Western"
                [checked]="api.user?.region=='Asia - Western'" (change)="region($event)">
              <label class="form-check-label">
                Western
              </label>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <label class="main-label">Australia/New Zealand</label>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Australia"
                [checked]="api.user?.region=='Australia'" (change)="region($event)">
              <label class="form-check-label">
                Australia
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="New Zealand"
                [checked]="api.user?.region=='New Zealand'" (change)="region($event)">
              <label class="form-check-label">
                New Zealand
              </label>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <label class="main-label">Africa</label>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Africa - Northern"
                [checked]="api.user?.region=='Africa - Northern'" (change)="region($event)">
              <label class="form-check-label">
                Northern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Africa - Western"
                [checked]="api.user?.region=='Africa - Western'" (change)="region($event)">
              <label class="form-check-label">
                Western
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Africa - Central"
                [checked]="api.user?.region=='Africa - Central'" (change)="region($event)">
              <label class="form-check-label">
                Central
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Africa - Eastern"
                [checked]="api.user?.region=='Africa - Eastern'" (change)="region($event)">
              <label class="form-check-label">
                Eastern
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Africa - Southern"
                [checked]="api.user?.region=='Africa - Southern'" (change)="region($event)">
              <label class="form-check-label">
                Southern
              </label>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <label class="main-label">Latin America</label>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Latin America - Central America"
                [checked]="api.user?.region=='Latin America - Central America'" (change)="region($event)">
              <label class="form-check-label">
                Central America
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Latin America - Caribbean"
                [checked]="api.user?.region=='Latin America - Caribbean'" (change)="region($event)">
              <label class="form-check-label">
                Caribbean
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Latin America - Mexico"
                [checked]="api.user?.region=='Latin America - Mexico'" (change)="region($event)">
              <label class="form-check-label">
                Mexico
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Latin America - Northern South America"
                [checked]="api.user?.region=='Latin America - Northern South America'" (change)="region($event)">
              <label class="form-check-label">
                Northern South America
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="region" value="Latin America - Southern South America"
                [checked]="api.user?.region=='Latin America - Southern South America'" (change)="region($event)">
              <label class="form-check-label">
                Southern South America
              </label>
            </div>
          </div>
        </div>
      </li>
      <li *ngIf="!api.premium" class="text-center nohover">
        <h1>{{api.strings?.portal.airport_choose}}</h1>
        <h4>{{api.strings?.portal.airport_choose_enticing}}</h4>
      </li>
<!--      TODO If so, I need to change this to an onclick listener-->
      <li *ngIf="!api.premium" class="text-center nohover">
        <a routerLink="/dashboard/checkout" class="btn btn-accent">{{api.strings?.portal.go_premium}}</a>
      </li>
    </ul>
  </div>
</ng-template>
