<vertical-nav></vertical-nav>
<div class="content-area">
  <menu-bar></menu-bar>
  <div class="content-wrapper">
    <div class="row">
      <div class="w-100 px-md-0 px-3">
        <p class="font-weight-bold title">Support</p>
        <div class="col-lg-12 col-md-12 col-12 animated fadeIn settings-card card-shadow px-40 pb-4">
          <div class="col-md-12 mt-10 mb-10">
            <h3 class="mt-20 bold">Need Help?</h3>
            <p class="mt-2 pt-1">Our <a href="https://help.dollarflightclub.com/en/" target="_blank">Support Center</a> covers just about everything you need to know about Dollar Flight Club.
              That said, we understand that there is nothing worse than not getting the answers you need, so if you can’t find what you’re looking for, please send us a message and we’ll be happy to help you out.</p>
            <p class="mt-2 pt-1">PS. We're a small start up, so we're trying to get to you as fast as possible... chat soon!</p>
            <p class="mt-2 pt-1"><b>EMAIL: </b><a href="mailto:support@dollarflightclub.com">support@dollarflightclub.com</a></p>
            <p class="mt-2 pt-1 pb-3"><b>CHAT MESSAGE: </b>Click <a id="my_custom_link" href="mailto:support@dollarflightclub.com">HERE</a> to send an instant chat message</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<static-footer></static-footer>
