export default {
  'strings': {
    'contact': {
      'accepted_payments': 'ACCEPTED PAYMENTS',
      'amex_card': '/assets/images/amex.png',
      'call_us': 'For support, email us at support@dollarflightclub.com',
      'copyright': 'Dollar Flight Club 2019. All rights reserved.',
      'cta': 'Send message',
      'discover_card': '/assets/images/discover.png',
      'email_placeholder': 'Your email',
      'facebook_link': 'https://www.facebook.com/DollarFlightClub/',
      'header': 'Get in touch',
      'instagram_link': 'https://www.instagram.com/dollarflightclub/',
      'mastercard_card': '/assets/images/mastercard.png',
      'message_placeholder': 'Your message',
      'name_placeholder': 'Your name',
      'twitter_link': 'https://twitter.com/DollarFlights',
      'visa_card': '/assets/images/visa.png',
      'youtube_link': 'https://www.youtube.com/channel/UCuwFbZ6BhTdiHgdx5sKB6ag/featured?view_as=subscriber'
    }, 'portal': {
      'account_enticing': 'The Ultimate Dollar Flight Club Experience',
      'account_information_updated': 'Your account information has been updated',
      'account_update_error': 'There is an already an account with the selected email address.',
      'activate': 'Activate',
      'active': 'Active',
      'add': 'Add',
      'airlines_search': 'Name or code of the airline',
      'airports_search': 'City or Airport',
      'airport_choose': 'Want your exact departure airport?',
      'airport_choose_enticing': 'The Ultimate Dollar Flight Club Experience',
      'availability_by_origin': 'Availablity varies by origin.',
      'average_savings': 'Average savings per deal',
      'billing_1': 'Billed Monthly',
      'billing_12': 'Billed Annually',
      'billing_3': 'Billed Quarterly',
      'cancel': 'Cancel',
      'cancel_change': 'Cancel change',
      'change_password': 'Change password',
      'choose_plan': 'Choose Your Plan',
      'class': 'Class',
      'class_business': 'Business',
      'class_economy': 'Economy',
      'class_first': 'First Class',
      'class_premium_economy': 'Premium Economy',
      'confirm': 'Yes! I do',
      'confirm_membership_upgrade_text': 'You are going to upgrade your subscription, therefore your current subscription will be canceled and you will subscribe to the new membership plan. Do you want to proceed?',
      'confirm_membership_upgrade_title': 'Confirm membership upgrade',
      'confirm_new_password': 'Confirm password',
      'current_membership': 'This is your current membership',
      'dashboard': 'Dashboard',
      'date_sent': 'Deal sent on',
      'deactivate': 'Deactivate',
      'deals': 'Deals',
      'deals_finder': 'Deals finder',
      'deals_received': 'deals received to date',
      'departure': 'Departure',
      'departure_region': 'Departure Region',
      'desktop_notifications': 'Desktop notifications',
      'destination_search': 'Enter the name of the destination',
      'dismiss': 'dismiss',
      'email': 'Email Address',
      'email_notifications': 'Email notifications',
      'email_required': 'Enter a valid email address',
      'enter_destination': 'Enter destination',
      'every': 'every',
      'expired': 'past deal',
      'faqs': 'Frequently Asked Questions',
      'faq_support': 'For additional support please email us at support@dollarflightclub.com',
      'field_required': 'Required',
      'find_flights': 'Find flights',
      'flight_deals': 'Flight Deals',
      'forgot_password': 'Forgot password?',
      'free': 'Free',
      'free_members_notice': 'Free members missed out on $190 roundtrip flights to Iceland. Upgrade to premium now and never miss a deal again......',
      'fresh_deal': 'Fresh Deal',
      'from': 'From',
      'gender': 'Gender',
      'gender_female': 'Female',
      'gender_male': 'Male',
      'gender_other': 'Other',
      'generic_error': 'There has been an error, please try again. If the problem persists refresh the page.',
      'go_home': 'Go back to home page',
      'go_premium': 'Go Premium',
      'go_premium_new': 'Go Premium',
      'go_to_login': 'Go to login page',
      'important_things_text': 'Awesome fares like this can be gone within a few hours or even within a few minutes, play around with the dates and if you do not see similar prices to the ones listed above, then they\'re gone already. Don\'t be sad, we have more awesome deals like this coming your way very soon.',
      'important_things_title': 'Very important things',
      'inactive': 'Inactive',
      'invalid_credentials': 'Invalid credentials',
      'invalid_new_password': 'The password must have at least 6 characters',
      'join_today': 'JOIN TODAY',
      'latest_deals': 'Latest deals',
      'learn_more': 'Try Premium Free',
      'loading': 'Please wait',
      'login_email': 'Enter your email address',
      'logout': 'Logout',
      'membership': 'Membership',
      'membership_benefit1_text': 'Premium members get all the deals while our free members only get 1/4 of them.',
      'membership_benefit1_title': 'Get 100% of the deals',
      'membership_benefit2_text': 'You can select your departure airport(s). Free Members can only select from their region.',
      'membership_benefit2_title': 'Choose Your Departure Airport',
      'membership_benefit3_text': 'You are always going to be the first to know. Mistake fares go fast so we\'ll give you the deals before we send them to the free member...if you\'re not first, your last!',
      'membership_benefit3_title': 'Get Deals First',
      'membership_benefit4_text': 'Don\'t want the goods after 30 days?!...no problem. Monthly plans come with a 30-day money back guarantee. Making you happy is our #1 priority!',
      'membership_benefit4_title': '30 Day Money Back Guarantee - Monthly Plans',
      'membership_bill': 'Billed as one payment of',
      'membership_canceled': 'You have canceled your subscription.',
      'membership_cycle': 'Pay ${amount}/month.',
      'membership_enticing1': 'The average saving per ticket is over $500. If you book 1 flight with your membership the service pays for itself 10X',
      'membership_enticing2': 'Go Forth and Explore',
      'membership_signature1': '- Amanda, Saved $550+',
      'membership_signature2': '- Paula C., Saved $1800+',
      'membership_status': 'Membership Status',
      'membership_subtitle': 'Start Your Premium 7-Day Free Trial',
      'membership_testimonial1': '"I booked to Iceland for $250 round trip! Flights are usually $800! The Snaefellsnes Peninsula was my favorite part of the trip. It was completely beautiful in every part! I got to see waterfalls, mountains, seals, and Aurora Borealis!” ',
      'membership_testimonial2': '“We booked flights to New Zealand for $250 round trip per person! Saved $1,800 on airfare! We hiked the Tongariro Alpine Crossing, which is the location for Mount Doom in Lord of The Rings!” ',
      'membership_title': 'Free Members miss out on 75% of the Deals',
      'membership_upgraded': 'Welcome to Premium! Make sure you select your departure airports so you get the right deals in your inbox. Click on the icon in the top right of the screen and select "Profile", then head to "My departure airports" to make your selection. ',
      'member_since': 'Member Since',
      'more_deals': 'Go Premium. Go Travel.',
      'my_account': 'My Account',
      'my_account_information': 'My Account Information',
      'my_airlines': 'My favorite airlines',
      'my_deals': 'My deals',
      'my_departures': 'My departure airports',
      'my_destinations': 'My favorite destinations',
      'my_favorite_airlines': 'My favorite airlines',
      'my_notifications': 'My notifications',
      'my_profile': 'My Profile',
      'my_tickets': 'My Support Tickets',
      'name': 'Name',
      'new_account_password': 'New account password',
      'new_support_ticket': 'Open a new Support Ticket',
      'new_ticket_message': 'Message',
      'new_ticket_message_placeholder': 'Please describe your issue so we can give you an answer',
      'new_ticket_subject': 'Subject',
      'new_ticket_subject_example': 'Ex: I am having problems finding the deals',
      'new_ticket_warning': 'Before opening a new support ticket please make sure you read all the frequently asked questions.',
      'next_payment': 'Next Payment',
      'not_member_yet': 'NOT A MEMBER YET?',
      'no_airlines_found': 'No airlines found',
      'no_airports_found': 'No airports found',
      'no_deals_found': 'We couldn\'t find any deals',
      'no_departures': 'You have not added any departure airports',
      'no_destinations_found': 'No matching destinations found',
      'no_destinations_selected': 'You have not selected any favorite destinations',
      'no_destination_selected': 'No destination selected',
      'no_favorite_airlines': 'You have not added any favorite airlines',
      'no_location': 'Don\'t see your departure location?',
      'no_see_location': 'Not seeing your departure location?',
      'no_tickets': 'You have not opened any support tickets',
      'off': 'Off',
      'open_new_ticket': 'Open new ticket',
      'other_departures': 'Other departures',
      'password': 'Password',
      'passwords_do_not_match': 'Passwords do not match',
      'password_placeholder': 'Enter your account password',
      'password_recover_instructions': 'Enter the email address you used to create your profile in the form below and we will email you instructions to reset your account password.',
      'password_required': 'Enter your account password',
      'password_reset_success': 'Your account password has been changed successfully. From now on you will be able to access the members area using the new account password that you just set.',
      'payment_renew_date': 'Your Premium Membership will renew on',
      'phone': 'Phone Number',
      'play_dates_between': 'Play with dates between',
      'premium': 'Premium',
      'premium_deals': 'Premium members only get deals with their departure locations.',
      'profile': 'Profile',
      'promotion_expired': 'The promotion has expired',
      'push_notifications': 'Push notifications',
      'recover_instructions_sent': 'Please check your email, if you have an account registered with us, you will receive an email with instructions to reset your account password. If you happen to experience any difficulties, please do not hesitate to contact us.',
      'recover_password': 'Recover Account Password',
      'reply_ticket': 'Reply',
      'reset_password': 'Reset account password',
      'reset_password_instructions': 'Fill the form below in order to reset your account password.',
      'return': 'Return',
      'roundtrip': 'Roundtrip',
      'round_trip': 'round-trip',
      'save': 'Save',
      'savings_to_date': 'total savings to date',
      'searching': 'Searching...',
      'select_airline': 'Select airline...',
      'select_destination': 'Select destination...',
      'select_origin': 'Select origin...',
      'select_plan': 'Sign Up',
      'settings': 'Settings',
      'sign_in': 'Sign in',
      'sms_notifications': 'SMS notifications',
      'standard_fare': 'Standard fare',
      'submit': 'Submit',
      'submit_ticket': 'Submit ticket',
      'success': 'Success!',
      'support': 'Support',
      'support_ticket': 'Support ticket #',
      'tickets': 'Tickets',
      'ticket_created': 'Your support ticket has been created, we will do our best offer to respond as soon as possible.',
      'ticket_date': 'On __date__ you wrote',
      'ticket_number': 'Ticket #',
      'ticket_replied': 'Thank you for your message, we will do our best to get back to you as soon as possible.',
      'ticket_reply_date': 'On __date__ we replied',
      'ticket_reply_placeholder': 'Enter your message here',
      'ticket_status': 'Status',
      'ticket_status_open': 'open',
      'ticket_status_replied': 'replied',
      'ticket_subject': 'Subject',
      'travel_dates': 'Travel dates',
      'unsubscribe': 'Unsubscribe',
      'update_account_info': 'Update account information',
      'upgrade': 'Upgrade',
      'upgrade_alert': 'Free members miss out on all the best deals, instant deal SMS/text alerts, and our smartest deal personalization tools.',
      'upgrade_now': 'Start Free 7-Day Trial',
      'up_to': 'Up to',
      'view_all': 'view all',
      'view_flights': 'View flights',
      'your_savings': 'Your huge savings'
    }, 'static_pages': {
      'advertise_audience_active_ratio': '60/40',
      'advertise_audience_active_ratio_label': 'split between women and men',
      'advertise_audience_emails_total': '9M+',
      'advertise_audience_emails_total_label': 'monthly emails delivered',
      'advertise_audience_header': 'Brands love our audience',
      'advertise_audience_household_total': '80%',
      'advertise_audience_household_total_label': 'over 100k household income',
      'advertise_audience_members_total': '300K+',
      'advertise_audience_members_total_label': 'active audience members',
      'advertise_audience_or_total': '20%',
      'advertise_audience_or_total_label': 'average daily open rate',
      'advertise_cta_button': 'Lets do this',
      'advertise_cta_text': 'Get in touch and lets figure out the best way to work together',
      'advertise_cta_title': 'Is your audience ready to reach the world\'s best audience of travelers?',
      'advertise_header': 'How can we help your team achieve it\'s goals?',
      'advertise_text1': 'Our mission is to find the cheapest international flight deals for our members, inspiring them to explore more of the world and push their boundaries.',
      'advertise_text2': 'Everyday we deliver one beautiful curated email to our members inbox, with an average savings of $500 on international flights.',
      'advertise_text3': 'That\'s why they keep coming back. So, who are they? Let\'s dig in...',
      'brand10_image': '/assets/images/brand10.png',
      'brand10_title': 'bon appétit',
      'brand10_url': '#',
      'brand11_image': '/assets/images/brand11.png',
      'brand11_title': 'Esquire',
      'brand11_url': '#',
      'brand12_image': '/assets/images/brand12.png',
      'brand12_title': 'Skillshare',
      'brand12_url': '#',
      'brand13_image': '/assets/images/brand13.png',
      'brand13_title': 'Monday',
      'brand13_url': '#',
      'brand14_image': '/assets/images/brand14.png',
      'brand14_title': 'Wine Awesomeness',
      'brand14_url': '#',
      'brand15_image': '/assets/images/brand15.png',
      'brand15_title': 'Travel+Leisure',
      'brand15_url': '#',
      'brand16_image': '/assets/images/brand16.png',
      'brand16_title': 'The Hustle',
      'brand16_url': '#',
      'brand1_image': '/assets/images/brand1.png',
      'brand1_title': 'MVMT',
      'brand1_url': '#',
      'brand2_image': '/assets/images/brand2.png',
      'brand2_title': 'Outside',
      'brand2_url': '#',
      'brand3_image': '/assets/images/brand3.png',
      'brand3_title': 'G Adventures',
      'brand3_url': '#',
      'brand4_image': '/assets/images/brand4.png',
      'brand4_title': 'Condé Nast Traveler',
      'brand4_url': '#',
      'brand5_image': '/assets/images/brand5.png',
      'brand5_title': 'Morning Brew',
      'brand5_url': '#',
      'brand6_image': '/assets/images/brand6.png',
      'brand6_title': 'Leesa',
      'brand6_url': '#',
      'brand7_image': '/assets/images/brand7.png',
      'brand7_title': 'Fundrise',
      'brand7_url': '#',
      'brand8_image': '/assets/images/brand8.png',
      'brand8_title': 'Havenly',
      'brand8_url': '#',
      'brand9_image': '/assets/images/brand9.png',
      'brand9_title': 'Kind Traveler',
      'brand9_url': '#',
      'brands': 'We help brands big and small to deliver more value to our audience',
      'download_kit': 'Download Media Kit',
      'endorsements': 'Our members and partners love us',
      'endorsements_1_image': 'https://preview.ibb.co/cNGGn7/unnamed.jpg',
      'endorsements_1_name': 'Tahsan S',
      'endorsements_1_text': '"How could I not book this Business class flight to Costa Rica for $542 roundtrip?? Saved me over $1,500 on airfare! Absolutely an amazing deal, I did it, and I\'m super excited. Your premium newsletter is f__king awesome"',
      'endorsements_1_title': 'November 2017, Saved $1500 on flights',
      'endorsements_2_image': 'https://image.ibb.co/nzbo27/2681892_640x640.jpg',
      'endorsements_2_name': ' Casandra Rotunno',
      'endorsements_2_text': '"Dollar Flight Club has been an absolute pleasure to work with, incredibly responsive and overall awesome! Working with their brand has been seamless in seeking those who are looking to explore new or revisit places of the world in an affordable manor. Thanks for always keeping us in mind for a brand appearance as the audience seemed to have great interest in our brand as we start to gain exposure."',
      'endorsements_2_title': 'Gadventures - Consumer Marketing Specialist',
      'footer_link_advertise': 'FAQ\'s',
      'footer_link_careers': 'Careers',
      'footer_link_privacy': 'Privacy Policy',
      'footer_link_terms': 'Terms & Conditions',
      'media_kit_url': 'https://addollarflightclub.typeform.com/to/ZLxPZb'
    }, 'wizard': {
      'account_created_text': 'Welcome! You\'ve just joined over 500,000 travel and adventure lovers. You\'ll start getting cheap flight alerts via email shortly. In the meantime check out some of the deals we\'ve already found in your member profile.',
      'account_step': 'Choose Your Membership',
      'airlines_step': 'Favorite Airlines',
      'average_saving': 'The average saving per ticket is $500. If you book 1 flight with your membership the service pays for itself',
      'departures_step': 'Select Departure Region',
      'departure_airport': 'Departure airport',
      'departure_region': 'Departure Region',
      'destinations_step': 'Dream Destinations',
      'finish': 'Finish',
      'free_member': 'Free Member',
      'free_membership_warning_back_button': 'Actually, Take me back. I\'ll try Premium!',
      'free_membership_warning_confirmation': 'I\'ll stay with the Free Membership for now',
      'free_membership_warning_text': 'hey hey! Are you sure you\'re ready to cancel? You won\'t get deals from your home airport as a Free Member.',
      'free_membership_warning_title': 'Are you sure?',
      'free_members_miss': 'Free members Miss out on 75% of the Deals :(',
      'header': 'CREATE YOUR PROFILE',
      'login': 'Login',
      'membership_benefits_1': 'Get 100% of the deals',
      'membership_benefits_2': 'Business + first class deals',
      'membership_benefits_3': 'Airline mileage deal alerts',
      'membership_benefits_4': 'Get deals first',
      'membership_benefits_5': 'Card billed only after trial ends.',
      'membership_benefits_6': 'Cancel anytime',
      'membership_benefits_7': '30-day money-back guarantee',
      'membership_billing': 'Billed every',
      'membership_selection_cta': 'Finish',
      'membership_selection_previous': 'Previous',
      'name_input_label': 'Your name',
      'name_input_placeholder': 'Enter your name',
      'name_placeholder': 'Enter your name',
      'next': 'Next',
      'no_airline_image': '/assets/images/airline.jpg',
      'no_airport_image': '/assets/images/airport.jpg',
      'password': 'Create Account Password',
      'password_input_label': 'Create an account password',
      'password_input_placeholder': 'Create an account password',
      'payment_error_text': 'There has been an error processing your payment, please make sure you are entering your credit card information correctly and try again. If the problem persists you may try refreshing the page and trying again, if that doesn\'t help either, please contact me.',
      'payment_error_title': 'oops!',
      'phone_input_label': 'Your phone number',
      'phone_input_placeholder': 'Enter your phone number',
      'premium': 'Premium',
      'premium_benefits': 'Premium Benefits',
      'profile_info': 'Profile Info',
      'profile_intro': 'You\'re almost there my friend! Get your passport ready to go!',
      'saving_profile': 'Saving your profile information',
      'select_destinations': 'Select Your Dream Destination',
      'select_free_membership': 'Continue with a Free Membership',
      'select_membership': 'Pick your Premium membership plan:',
      'show_selection': 'Show selection',
      'step_1_city_no_image': '/assets/images/world.jpg',
      'step_1_next': 'Next',
      'step_1_no_results': 'Sorry, I can\'t find any cities with that name, try something like Paris or London.',
      'step_1_placeholder': 'Enter the name of a city and press Enter',
      'step_1_searching': 'Loading suggestions',
      'step_1_text': 'Click over each city to select it. You can choose as many cities as you\'d like.',
      'step_1_title': 'Which cities have you always dreamed about visiting?',
      'step_2_next': 'Next',
      'step_2_no_results': 'I couldn\'t find any matches, try something like Paris or LAX',
      'step_2_placeholder': 'Enter the name of a city or airport and press Enter',
      'step_2_previous': 'Previous',
      'step_2_searching': 'Loading suggestions',
      'step_2_text': 'Click over the name of your departure city or airport to select it. Select more airports to get more deals.',
      'step_2_title': 'Where\'s your departure airport?',
      'step_3_next': 'Next',
      'step_3_no_results': 'I cannot find any airline with that name, try with something like American Airlines or Emirates',
      'step_3_placeholder': 'Enter the name of the airline and press Enter',
      'step_3_previous': 'Previous',
      'step_3_searching': 'Loading suggestions',
      'step_3_text': 'Click over the name of your preferred airline to select it.',
      'step_3_title': 'What\'s your favorite airline?',
      'stripe_subscription_button': 'Sign Me Up',
      'subheader': 'You\'re almost there my friend! Get your passport ready to go!',
      'user_portal_cta': 'Go to user portal'
    }
  },
}
