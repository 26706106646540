/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./interface.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./interface.component";
import * as i4 from "../../services/api.service";
var styles_InterfaceComponent = [i0.styles];
var RenderType_InterfaceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InterfaceComponent, data: {} });
export { RenderType_InterfaceComponent as RenderType_InterfaceComponent };
function View_InterfaceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["href", "/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Retry"]))], null, null); }
function View_InterfaceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ups! I can't connect to the server. Please refresh the page to try again "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InterfaceComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.api.error; _ck(_v, 4, 0, currVal_0); }, null); }
function View_InterfaceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.api.modalMessage = false) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.api.modalMessage = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "message ", _co.api.modalMessageClass, " animated jackInTheBox"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.api.modalMessageTitle; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.api.modalMessageText; _ck(_v, 7, 0, currVal_2); }); }
function View_InterfaceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "alert-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "alert-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.api.removeAlert(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["x"])), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.class), " animated slideInRight"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.icon), ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.message); _ck(_v, 6, 0, currVal_2); }); }
function View_InterfaceComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "message warning animated jackInTheBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "action"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.api.confirmUpgrade = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary btn-lg btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.api.confirmUpgrade = false;
        var pd_0 = (_co.api.confirmUpgradeCallback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.api.strings.portal.cancel; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.api.strings.portal.confirm_membership_upgrade_title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.api.strings.portal.confirm_membership_upgrade_text; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.api.strings.portal.confirm; _ck(_v, 9, 0, currVal_3); }); }
function View_InterfaceComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "message warning animated jackInTheBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "action"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.api.confirmFree = false;
        var pd_0 = (_co.api.freeCallback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary btn-lg btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.api.confirmFree = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.api.strings.wizard.free_membership_warning_confirmation; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.api.strings.wizard.free_membership_warning_title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.api.strings.wizard.free_membership_warning_text; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.api.strings.wizard.free_membership_warning_back_button; _ck(_v, 9, 0, currVal_3); }); }
export function View_InterfaceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InterfaceComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InterfaceComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "alerts"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InterfaceComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InterfaceComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InterfaceComponent_6)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.api.loading && _co.api.error); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.api.modalMessage; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.api.alerts; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.api.confirmUpgrade; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.api.confirmFree; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_InterfaceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "interface", [], null, null, null, View_InterfaceComponent_0, RenderType_InterfaceComponent)), i1.ɵdid(1, 114688, null, 0, i3.InterfaceComponent, [i4.Api], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InterfaceComponentNgFactory = i1.ɵccf("interface", i3.InterfaceComponent, View_InterfaceComponent_Host_0, {}, {}, []);
export { InterfaceComponentNgFactory as InterfaceComponentNgFactory };
